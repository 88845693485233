import React, { useCallback, useState } from 'react';
import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import debounce from 'lodash.debounce';

import UserClient from '../../clients/user.client';
import { useApp } from '../../hooks/useMain';
import { UserViewModel } from '../../typings';

import {
  Avatar,
  Container,
  Details,
  Name,
  User,
  Username,
  Input,
  InputWrapper,
  InputAction,
  GlobalStyle,
  NotFound,
} from './UserAutocomplete.styles';
import { useAvatarImage } from '../../hooks/useAvatarImage';
import { Button } from '../Button/Button';
import { EMAIL_REGEX } from '../../utils/regexes.utils';
import Icon, { IconType } from '../Icon/Icon';

const MINLENGTH_SEARCH = 2;

export interface UserAutocompleteProps {
  id?: string;
  placeholder?: string;
  confirmButtonText?: string;
  autoFocus?: boolean;
  showNoOptions?: boolean;
  onSelect: (item: UserViewModel) => void;
  onBlur?: () => void;
}

const UserAutocomplete = ({
  id = 'user-auto-complete',
  placeholder = 'Search by full name or username',
  autoFocus = false,
  showNoOptions = false,
  confirmButtonText,
  onSelect,
  onBlur,
}: UserAutocompleteProps): React.ReactElement => {
  const [users, setUsers] = useState<UserViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<UserViewModel>();
  const { setErrorMessage } = useApp();
  const { getAvatarMemo } = useAvatarImage();

  function isEmailValid(email: string): boolean {
    return Boolean(EMAIL_REGEX.exec(String(email).toLowerCase()));
  }

  const fetchData = useCallback(async (search: string) => {
    try {
      if (search) {
        setLoading(true);
        setSelectedOption(undefined);
        const data = await new UserClient().findByNameOrUsernameOrEmail(
          search.trim()
        );

        if (data.length) {
          setUsers(data);
        } else if (isEmailValid(search)) {
          setSelectedOption({ email: search });
        } else if (showNoOptions) {
          setUsers([{}]);
        }
      } else {
        setUsers([]);
      }
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while fetching the data'
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDebounced = useCallback(
    debounce((newValue) => fetchData(newValue), 1000),
    []
  );

  function handleConfirmation(item?: UserViewModel) {
    if (item) {
      onSelect(item);
      setSelectedOption(undefined);
      setSearchValue('');
    }
  }

  function handleSelection(item: UserViewModel) {
    if (confirmButtonText) {
      setSelectedOption(item);
    } else {
      handleConfirmation(item);
    }
  }

  function handleInputChange(
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) {
    setSearchValue(value);

    if (reason === 'input' && value.length >= MINLENGTH_SEARCH) {
      fetchDebounced(value);
    }

    if (reason === 'clear') {
      setUsers([]);
    }
  }

  return (
    <Container>
      <GlobalStyle id={id} />

      <Autocomplete
        autoComplete
        open
        freeSolo
        autoHighlight
        noOptionsText="No users found"
        disableClearable={loading}
        id={id}
        options={users}
        onClose={() => setUsers([])}
        filterOptions={(x) => x}
        inputValue={searchValue}
        onChange={(e, value) => handleSelection(value as UserViewModel)}
        onInputChange={handleInputChange}
        getOptionLabel={(option: UserViewModel) =>
          `${String(option.firstName)} ${String(option.lastName)}`
        }
        renderOption={(option: UserViewModel) => (
          <>
            {Boolean(option.firstName) && Boolean(option.lastName) && (
              <User>
                <Avatar src={getAvatarMemo(option.picture, option.username)} />

                <Details>
                  <Name>
                    {String(option.firstName)} {String(option.lastName)}
                  </Name>
                </Details>
              </User>
            )}
            {!option.username && (
              <NotFound>
                <Icon name={IconType.sadFace} />

                <p>No users found</p>
              </NotFound>
            )}
          </>
        )}
        renderInput={(params) => (
          <InputWrapper>
            <Input
              {...params}
              placeholder={placeholder}
              variant="outlined"
              autoFocus={autoFocus}
              onBlur={onBlur}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />

            {Boolean(confirmButtonText) && (
              <InputAction>
                <Button
                  type="button"
                  width="80px"
                  height="40px"
                  marginTop="0"
                  disabled={!selectedOption}
                  onClick={() => handleConfirmation(selectedOption)}
                >
                  {confirmButtonText}
                </Button>
              </InputAction>
            )}
          </InputWrapper>
        )}
      />
    </Container>
  );
};

export default UserAutocomplete;
