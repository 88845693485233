import React from 'react';

const AddProject = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2 19.2C17.8508 19.2 20 17.0508 20 14.4C20 11.7492 17.8508 9.6 15.2 9.6C12.5492 9.6 10.4 11.7492 10.4 14.4C10.4 17.0508 12.5492 19.2 15.2 19.2Z"
      fill="url(#paint0_linear_1119_270)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 0C3.22344 0 0 3.22344 0 7.2V36C0 39.9766 3.22344 43.2 7.2 43.2H9.6V45.6C9.6 49.5766 12.8234 52.8 16.8 52.8H35.2V48H16.8C15.4746 48 14.4 46.9254 14.4 45.6V43.2H36C39.9766 43.2 43.2 39.9766 43.2 36V14.4H45.6C46.9254 14.4 48 15.4746 48 16.8V35.2H52.8V16.8C52.8 12.8234 49.5766 9.6 45.6 9.6H43.2V7.2C43.2 3.22344 39.9766 0 36 0H7.2ZM38.4 24L31.0629 16.6629C29.8129 15.4129 27.7871 15.4129 26.5371 16.6629L16 27.2L12.6629 23.8629C11.4129 22.6129 9.38711 22.6129 8.13711 23.8629L4.8 27.2V7.2C4.8 5.87461 5.87461 4.8 7.2 4.8H36C37.3254 4.8 38.4 5.87461 38.4 7.2V24Z"
      fill="url(#paint1_linear_1119_270)"
    />
    <path
      d="M48 40.8H52.8V48H60V52.8H52.8V60H48V52.8H40.8V48H48V40.8Z"
      fill="url(#paint2_linear_1119_270)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1119_270"
        x1="48"
        y1="53"
        x2="11.5"
        y2="4.94967e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A795FE" />
        <stop offset="1" stopColor="#4D4DFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1119_270"
        x1="48"
        y1="53"
        x2="11.5"
        y2="4.94967e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A795FE" />
        <stop offset="1" stopColor="#4D4DFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1119_270"
        x1="48"
        y1="53"
        x2="11.5"
        y2="4.94967e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A795FE" />
        <stop offset="1" stopColor="#4D4DFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default AddProject;
