import React, { useMemo } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { BoardItem } from '../../../../../../typings';
import { Container, Movie } from './Video.styles';

interface VideoProps {
  item: BoardItem;
  disabled: boolean;
  onClick: (item: BoardItem) => void;
}

const Video = SortableHandle<VideoProps>(
  ({ item, disabled, onClick }: VideoProps) => {
    const src = useMemo(() => {
      if (item.value instanceof File) {
        return URL.createObjectURL(item.value);
      }

      return item.value;
    }, [item.value]);

    return (
      <Container disabled={disabled}>
        <Movie
          // autoPlay={false}
          // controls
          // controlsList="play nodownload nofullscreen noplaybackrate"
          // disablePictureInPicture
          onMouseOver={(event) => (event.target as any)?.play()}
          onMouseOut={(event) => (event.target as any)?.pause()}
          loop
          muted
          src={src}
          onClick={() => onClick(item)}
        />
      </Container>
    );
  }
);

export default Video;
