import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

export const Header = styled.header``;

export const Content = styled.div`
  display: flex;
  height: calc(100% - 64px);
`;
