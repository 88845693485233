class StringUtils {
  value: string;

  constructor(value: string) {
    this.value = value;
  }

  capitalize(): string {
    return (
      this.value.charAt(0).toUpperCase() + this.value.slice(1).toLowerCase()
    );
  }
}

export default StringUtils;
