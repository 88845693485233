import React, { useEffect, useRef } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Trash } from '@phosphor-icons/react';

import { useNavigate } from 'react-router-dom';
import { useApp } from '../../hooks/useMain';
import {
  Actions,
  Container,
  Stacks,
  Header,
  ProjectStatus,
} from './ProjectV2.styles';
import {
  Button,
  ButtonLink,
  ButtonOutline,
} from '../../components/Button/Button';
import useController from './hooks/useController/useController';
import Section from './components/Section/Section';
import CreateOrEditProjectModal from './components/CreateOrEditProjectModal/CreateOrEditProjectModal';
import InfosCollapse from './components/InfosCollapse/InfosCollapse';
import { Board } from '../../typings';
import AddColumn from './components/AddColumn/AddColumn';
import Tooltip from '../../components/Tooltip/Tooltip';
import ExpandableColumnDetails from './components/ExpandableColumnDetails/ExpandableColumnDetails';
import Tabs, { ProjectTabs } from './components/Tabs/Tabs';
import ShareProjectModal from './components/ShareModal/ShareModal';
import GridView from './components/GridView/GridView';
import Timeline from './components/Timeline/Timeline';
import ProjectsSideBar from '../../components/ProjectsSideBar/ProjectsSideBar';
import AddCommentInfo from './components/AddCommentInfo/AddCommentInfo';
import DeletionApprovalModal from '../../components/DeletionApprovalModal/DeletionApprovalModal';
import FollowButton from './components/FollowButton/FollowButton';
import ContentLoader from './components/ContentLoader/ContentLoader';
import CancelConfirmationModal from './components/CancelConfirmationModal/CancelConfirmationModal';
import ROUTES from '../../routes';

const MAX_COLUMNS = 10;

interface ProjectV2PageProps {
  viewOnly?: boolean;
}

const ProjectV2Page = ({
  viewOnly = false,
}: ProjectV2PageProps): React.ReactElement => {
  const {
    state: { loading },
  } = useApp();
  const {
    state,
    performColumnsSubmit,
    performInfoSubmit,
    performDeletion,
    onChangeColumn,
    onColumnAction,
    onAddColumn,
    setColumnToFocus,
    setTab,
    toggleCreateModal,
    toggleShareModal,
    handleEdit,
    handleFollow,
    addShareLink,
    removeShareLink,
    isAbleToPublish,
    isOwner,
    isDraft,
    isAuthenticated,
    allImagesFromColumns,
  } = useController(viewOnly);
  const navigate = useNavigate();

  const {
    id,
    columns,
    showCreateModal,
    showShareModal,
    tab,
    name,
    createdAt,
    audits,
    changed,
  } = state;

  if (loading && !name) {
    return <ContentLoader />;
  }

  return (
    <Container leftMargin={viewOnly && isAuthenticated}>
      {viewOnly && <ProjectsSideBar headerHeight="88px" />}

      <Header>
        <InfosCollapse
          data={state as Board}
          show={Boolean(state.user)}
          showingDetails={Boolean(state.columnInFocus?.columnId)}
          viewOnly={viewOnly}
          onEdit={() => handleEdit()}
          onShare={() => toggleShareModal(true)}
        />

        {viewOnly ? (
          <Tabs
            current={tab}
            onChange={setTab}
            showingSideBar={isAuthenticated}
          />
        ) : (
          <ProjectStatus>
            {!isDraft ? 'Edit project mode' : 'Project draft'}
          </ProjectStatus>
        )}

        {!isOwner && viewOnly && !loading && (
          <Actions>
            <AddCommentInfo show={!state.columnInFocus?.columnId} />

            <FollowButton following={state.following} onToggle={handleFollow} />
          </Actions>
        )}

        {isOwner && !viewOnly && (
          <Actions>
            <DeletionApprovalModal
              title="Are you sure you want to<br /> delete this project?"
              subtitle="This can’t be undone."
              onConfirm={performDeletion}
            >
              <ButtonLink type="button" height="46px" width="46px">
                <Trash size={24} color="#7F8087" />
              </ButtonLink>
            </DeletionApprovalModal>

            <CancelConfirmationModal
              validate={changed}
              onExit={() => navigate(ROUTES.PROJECT_VIEW(String(id)))}
              onSave={() => performColumnsSubmit(false)}
            >
              <ButtonOutline
                type="button"
                width="125px"
                height="46px"
                borderSize="1px"
                color="#7F8087"
                marginTop="0"
              >
                Cancel
              </ButtonOutline>
            </CancelConfirmationModal>

            <Tooltip
              title="Add column titles to publish project"
              id="publish-button-details"
              placement="bottom-end"
              show={isAbleToPublish ? false : undefined}
            >
              <Button
                type="button"
                width="153px"
                height="46px"
                marginTop="0"
                disabled={!isAbleToPublish}
                onClick={() => performColumnsSubmit(true)}
              >
                Publish {isDraft ? 'project' : 'updates'}
              </Button>
            </Tooltip>
          </Actions>
        )}

        {isOwner && viewOnly && !state.columnInFocus?.columnId && (
          <Actions>
            <ButtonOutline
              type="button"
              width="123px"
              height="48px"
              borderSize="1px"
              borderColor="#E3E6EC"
              borderRadius="12px"
              color="#000000"
              marginTop="0"
              onClick={() => handleEdit()}
            >
              Edit Project
            </ButtonOutline>

            <ButtonOutline
              type="button"
              width="123px"
              height="48px"
              borderSize="1px"
              borderColor="#E3E6EC"
              borderRadius="12px"
              color="#4D4DFF"
              marginTop="0"
              onClick={() => toggleShareModal(true)}
            >
              Share
            </ButtonOutline>
          </Actions>
        )}
      </Header>

      {tab === ProjectTabs.STACKS && (
        <Stacks length={columns?.length}>
          {columns.map((column) => (
            <Section
              key={`${column.order}`}
              data={column}
              disabled={viewOnly}
              stack={{
                first: column.order === 0,
                last: column.order === columns.length - 1,
                single: columns.length === 1,
              }}
              onChange={onChangeColumn}
              onMove={onColumnAction}
              onImageClick={(item) => setColumnToFocus(column.id, item.id)}
            />
          ))}
          {columns.length < MAX_COLUMNS && !viewOnly && (
            <AddColumn onClick={onAddColumn} />
          )}
        </Stacks>
      )}

      {tab === ProjectTabs.GRID && (
        <GridView images={allImagesFromColumns} onClick={setColumnToFocus} />
      )}

      {tab === ProjectTabs.TIMELINE && (
        <Timeline
          data={{
            name,
            audits,
            createdAt,
          }}
        />
      )}

      {viewOnly ? (
        <ExpandableColumnDetails
          columns={columns}
          item={state.columnInFocus}
          onChange={setColumnToFocus}
          onClose={() => setColumnToFocus(undefined, undefined)}
        />
      ) : (
        <CreateOrEditProjectModal
          open={showCreateModal}
          data={state as Board}
          onClose={() => toggleCreateModal(false)}
          onSave={performInfoSubmit}
        />
      )}

      <ShareProjectModal
        data={state as Board}
        open={showShareModal}
        onClose={() => toggleShareModal(false)}
        onGenerateLink={addShareLink}
        onDeleteLink={removeShareLink}
      />
    </Container>
  );
};

export default ProjectV2Page;
