/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from 'react-loading-overlay';

import ThemeProvider from './components/ThemeProvider/ThemeProvider';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import Toast from './components/Toast/Toast';
import ROUTES from './routes';
import { AppProvider } from './hooks/useMain';

import SmallScreenNotSupported from './components/SmallScreenNotSupported/SmallScreenNotSupported';
import { MicrosoftClarity, SentryIO } from './utils/externalServices';
import {
  PrivateRoute,
  PublicRoute,
  ValidateRoute,
} from './components/Routing/Routing';
import NotFoundPage from './pages/NotFound/NotFound';
import UserService from './services/user.service';
import MixPanelService from './services/mixpanel.service';
import JuneService from './services/june.service';
import { RouteChangeHandler } from './components/RouteChangeHandler/RouteChangeHandler';
import ProjectV2Page from './pages/ProjectV2/ProjectV2';
import UpgradeToProModal from './components/UpgradeToProModal/UpgradeToProModal';
import AuthService from './services/auth.service';
import PleaseSignUpModal from './components/PleaseSignUpModal/PleaseSignUpModal';
import { GoogleCallback } from './components/GoogleCallback/GoogleCallback';
import EmbededGifCreator from './pages/EmbededGifCreator/EmbededGifCreator';
import NewRelicScript from './components/NewRelic/NewRelic';

const SignIn = lazy(() => import('./pages/Signin/Signin'));
const SignUp = lazy(() => import('./pages/Signup/Signup'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const PasswordRecover = lazy(
  () => import('./pages/PasswordRecover/PasswordRecover')
);
// const Project = lazy(() => import('./pages/Project/Project'));
// const ProfilePage = lazy(() => import('./pages/Profile/Profile'));
// const ErrorPage = lazy(() => import('./pages/Error/Error'));
const DashboardPage = lazy(() => import('./pages/Dashboard/Dashboard'));
// const DashboardFeedPage = lazy(
//   () => import('./pages/DashboardFeed/DashboardFeed')
// );
// const GroupCreatePage = lazy(() => import('./pages/Group/Create/GroupCreate'));
const GroupViewPage = lazy(() => import('./pages/Group/View/GroupView'));
const PostCreatePage = lazy(() => import('./pages/Post/Create/PostCreate'));
const PostViewPage = lazy(() => import('./pages/Post/View/PostView'));
const PostTypePage = lazy(() => import('./pages/PostType/PostType'));
// import { getValidToken, onMessageListener } from './configs/firebase';

const IS_DEV = process.env.NODE_ENV === 'development';

function App(): JSX.Element {
  useEffect(() => {
    if (!IS_DEV) {
      const analytics = new JuneService().init();
      const userInfo = new UserService().get();
      MicrosoftClarity();
      SentryIO();
      MixPanelService.init();
      analytics.page();

      if (userInfo) {
        (window as any)?.clarity('identify', userInfo.email, userInfo.username);
        analytics.identify(userInfo);
      }
    }
  }, []);

  return (
    <AppProvider>
      {/* <NewRelicScript /> */}
      <LoadingOverlay />
      <ThemeProvider>
        <SmallScreenNotSupported />
        <UpgradeToProModal />

        <BrowserRouter>
          <Suspense fallback={<Loading active spinner />}>
            <PleaseSignUpModal />

            <RouteChangeHandler>
              <Routes>
                {/* ------ COMOOM ---------- */}
                <Route
                  path="/"
                  element={
                    <PublicRoute>
                      <SignIn />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.GIF_CREATOR}
                  element={
                    <PublicRoute>
                      <EmbededGifCreator />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.SIGN_IN}
                  element={
                    <PublicRoute>
                      <SignIn />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.GOOGLE_CALLBACK}
                  element={
                    <PublicRoute>
                      <GoogleCallback />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.SIGNIN}
                  element={
                    <PublicRoute>
                      <SignIn showCreateAccount />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.PASSWORD_RECOVER}
                  element={<PasswordRecover />}
                />
                <Route
                  path={ROUTES.SIGN_UP_GROUP}
                  element={
                    <PublicRoute>
                      <SignUp />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.SIGN_UP}
                  element={
                    <PublicRoute>
                      <SignUp />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.SIGN_UP_PRO}
                  element={
                    <PublicRoute>
                      <SignUp welcomePro />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.DASHBOARD}
                  element={
                    <PrivateRoute>
                      <DashboardPage />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path={ROUTES.COMMUNITY}
                  element={
                    <PrivateRoute>
                      <DashboardFeedPage tab={DashboardFeedTabs.COMMUNITY} />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path={`${ROUTES.SETTINGS}/*`}
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                {/* ------ PROJECT NEW ---------- */}
                <Route
                  path={ROUTES.PROJECT_NEW}
                  element={
                    <PrivateRoute>
                      <ProjectV2Page />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.PROJECT_EDIT()}
                  element={
                    <PrivateRoute>
                      <ProjectV2Page />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.NEW_PROJECT_NOT_FOUND()}
                  element={
                    <NotFoundPage title="Whoops — this page doesn’t exist!" />
                  }
                />
                <Route
                  path={ROUTES.PROJECT_VIEW()}
                  element={
                    <ValidateRoute
                      validation={(params) => {
                        const isInternalId = /[1-9]/.test(params?.id as string);
                        const isPrivateId = /^[0-9a-z]{40,}$/i.test(
                          params?.id as string
                        );
                        const isAuthenticated =
                          new AuthService().isAuthenticated();

                        return (isInternalId && isAuthenticated) || isPrivateId;
                      }}
                    >
                      <ProjectV2Page key={Date.now()} viewOnly />
                    </ValidateRoute>
                  }
                />
                {/* <Route path={ROUTES.PROJECT}>
                  <Project />
                </Route> */}
                {/* ------ PROFILE ---------- */}
                {/* <Route exact path={ROUTES.PROFILE}>
                  <ProfilePage />
                </Route>
                <Route path={ROUTES.PROFILE_NOT_FOUND}>
                  <ErrorPage
                    title="Profile not found!"
                    mainText="The profile that you are looking for doesn't exist"
                  />
                </Route>
                <Route path={`${ROUTES.PROFILE}/:username`}>
                  <ProfilePage />
                </Route> */}
                {/* ------ GROUP ---------- */}
                <Route
                  path={ROUTES.GROUP_EDIT()}
                  element={
                    <PrivateRoute>
                      <GroupViewPage editMode />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.GROUP_VIEW_NOT_FOUND()}
                  element={
                    <NotFoundPage title="Whoops — this page doesn’t exist!" />
                  }
                />
                <Route
                  path={ROUTES.GROUP_VIEW()}
                  element={
                    <PrivateRoute>
                      <GroupViewPage />
                    </PrivateRoute>
                  }
                />

                {/* ------ POST ---------- */}
                <Route path={ROUTES.POST_NEW} element={<PostCreatePage />} />
                <Route path={ROUTES.POST_TYPE} element={<PostTypePage />} />
                <Route
                  path={ROUTES.POST_EDIT()}
                  element={<PostCreatePage editMode />}
                />
                <Route
                  path={ROUTES.POST_VIEW_NOT_FOUND()}
                  element={
                    <NotFoundPage title="This post has expired or been deleted" />
                  }
                />
                <Route path={ROUTES.POST_VIEW()} element={<PostViewPage />} />

                {/* ------ DEFAULTS ---------- */}
                <Route
                  path="*"
                  element={
                    <NotFoundPage title="Whoops — this page doesn’t exist!" />
                  }
                />
              </Routes>
            </RouteChangeHandler>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>

      <Toast />
    </AppProvider>
  );
}

export default App;
