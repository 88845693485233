/* eslint-disable class-methods-use-this */
import { BoardSimplified, TeamSimplifiedViewModel } from '../typings';
import BaseClient from './base.client';

class FavoriteClient extends BaseClient {
  public async getAll(): Promise<
    Array<TeamSimplifiedViewModel | BoardSimplified>
  > {
    return this.client
      .get(`/favorites`)
      .then<Array<TeamSimplifiedViewModel | BoardSimplified>>(
        ({ data }) => data
      );
  }

  public async addTeam(teamId: number): Promise<void> {
    return this.client.post(`/favorites/team`, { teamId });
  }

  public async removeTeam(teamId: number): Promise<void> {
    return this.client.delete(`/favorites/team/${teamId}`);
  }

  public async addBoard(boardId: number): Promise<void> {
    return this.client.post(`/favorites/board`, { boardId });
  }

  public async removeBoard(boardId: number): Promise<void> {
    return this.client.delete(`/favorites/board/${boardId}`);
  }
}

export default FavoriteClient;
