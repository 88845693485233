import React, { ReactElement, cloneElement, useState } from 'react';
import { Modal } from 'react-bootstrap';

import {
  ModalContainer,
  Title,
  ConfirmButton,
  Subtitle,
} from './DeletionApprovalModal.styles';
import { ButtonOutline } from '../Button/Button';

interface DeletionApprovalModalProps {
  title: string;
  subtitle?: string;
  onConfirm: () => void;
  children: React.ReactNode;
}

const DeletionApprovalModal: React.FC<DeletionApprovalModalProps> = ({
  title,
  subtitle,
  onConfirm,
  children,
}: DeletionApprovalModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const childrenWithProps = React.Children.map(children, (child) =>
    cloneElement(child as ReactElement, { onClick: () => setIsOpen(true) })
  );

  return (
    <>
      {childrenWithProps}

      <ModalContainer
        show={isOpen}
        size="md"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Title dangerouslySetInnerHTML={{ __html: title }} />

          {Boolean(subtitle) && <Subtitle>{subtitle}</Subtitle>}
        </Modal.Header>

        <Modal.Footer>
          <ButtonOutline
            type="button"
            width="100px"
            height="48px"
            marginTop="0"
            borderSize="1px"
            borderRadius="12px"
            color="#7F8087"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </ButtonOutline>

          <ConfirmButton
            type="button"
            width="100px"
            height="48px"
            marginTop="0"
            borderRadius="12px"
            onClick={() => {
              setIsOpen(false);
              onConfirm();
            }}
          >
            Delete
          </ConfirmButton>
        </Modal.Footer>
      </ModalContainer>
    </>
  );
};

export default DeletionApprovalModal;
