import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { BiDotsVerticalRounded as IconDots } from 'react-icons/bi';

import { Container, GlobalStyle, MenuItemWrapper } from './Options.styles';
import StringUtils from '../../../../../../utils/string.utils';

interface ColumnOptionsProps {
  items: {
    name: string;
    icon: React.ReactElement;
    disabled: boolean;
  }[];
  onChange: (value: string, e?: any) => void;
}

const ColumnOptions = ({ items, onChange }: ColumnOptionsProps) => {
  function handleChange(value: string) {
    onChange(value);
  }

  return (
    <Container>
      <GlobalStyle />

      <Select
        displayEmpty
        value=""
        onChange={(e) => handleChange(String(e.target.value))}
        IconComponent={IconDots}
        MenuProps={{
          className: 'column-options',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {items.map((item: any, index: number) => (
          <MenuItem key={item.name} value={item.name} disabled={item.disabled}>
            <MenuItemWrapper
              color={index === items.length - 1 ? '#FF5656' : '#000000'}
            >
              {Boolean(item.name) && new StringUtils(item.name).capitalize()}{' '}
              {Boolean(item.icon) && item.icon}
            </MenuItemWrapper>
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};

export default ColumnOptions;
