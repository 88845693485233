/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useState, Fragment } from 'react';
import Pluralize from 'react-pluralize';

import {
  Container,
  Content,
  Row,
  Details,
  ImageGrid,
  Image,
  CreatedAt,
  MoreImagesAdded,
  Title,
  Infos,
  ImageGridWrapper,
} from './Timeline.styles';
import { BoardAudit, BoardItem } from '../../../../typings';
import DateUtils from '../../../../utils/date.utils';
import CarouselModal from '../../../Project/steps/Feedback/components/CarouselModal/CarouselModal';
import FileUtils from '../../../../utils/file.utils';
import ImageUtils from '../../../../utils/image.utils';

interface TimelineProps {
  data: {
    audits?: BoardAudit[];
    name: string;
    createdAt?: Date;
  };
}

const MAX_IMAGES = 4;

const Timeline = ({ data }: TimelineProps) => {
  const [itemToZoom, setItemToZoom] = useState<any>();

  function getImages(items: BoardItem[]) {
    const images = items.filter((item) => new FileUtils(item.value).isImage());

    return images;
  }

  function getAuditWithOnlyImages(audits?: BoardAudit[]): BoardAudit[] {
    const result = audits
      ?.map((audit) => {
        const images = getImages(audit.change?.itemsAdded);

        return {
          ...audit,
          change: {
            ...audit.change,
            itemsAdded: images,
          },
        };
      })
      .filter((audit) => audit.change?.itemsAdded?.length);

    return result || [];
  }

  return (
    <Container>
      <Content>
        {getAuditWithOnlyImages(data.audits)?.map((audit) => (
          <Row key={audit.id}>
            <Details>
              <b>{new DateUtils(audit.createdAt).fromNowDay()}</b>
            </Details>

            <ImageGridWrapper>
              <Infos>
                <Pluralize
                  singular="item"
                  count={audit.change?.itemsAdded?.length}
                />
                &nbsp;uploaded
              </Infos>

              <ImageGrid total={getImages(audit.change?.itemsAdded)?.length}>
                {audit.change?.itemsAdded
                  ?.slice(0, MAX_IMAGES)
                  ?.map((image: BoardItem, index: number) => (
                    <Fragment key={String(image.value)}>
                      <Image
                        src={new ImageUtils(image.value as string).getMedium()}
                        onClick={() =>
                          setItemToZoom({
                            image,
                            images: audit.change?.itemsAdded,
                          })
                        }
                      />
                      {audit.change?.itemsAdded?.length > MAX_IMAGES &&
                        index === MAX_IMAGES - 1 && (
                          <MoreImagesAdded
                            onClick={() =>
                              setItemToZoom({
                                image,
                                images: audit.change?.itemsAdded,
                              })
                            }
                          >
                            +{audit.change?.itemsAdded?.length - MAX_IMAGES}
                          </MoreImagesAdded>
                        )}
                    </Fragment>
                  ))}
              </ImageGrid>
            </ImageGridWrapper>
          </Row>
        ))}

        <Row>
          <Details>
            <b>{new DateUtils(data.createdAt).fromNowDay()}</b>
            <p>Project created</p>
          </Details>
          <CreatedAt>
            <b>{data.name}</b>&nbsp;was created
          </CreatedAt>
        </Row>

        <CarouselModal
          show={Boolean(itemToZoom)}
          images={itemToZoom?.images?.map((image: any) => ({
            url: image.value,
          }))}
          selectedItem={itemToZoom?.images.findIndex(
            (image: any) => image.value === itemToZoom?.image?.value
          )}
          onClose={() => setItemToZoom(null)}
        />
      </Content>
    </Container>
  );
};

export default Timeline;
