import styled, { createGlobalStyle } from 'styled-components/macro';
import { Modal } from 'react-bootstrap';

const WIDTH = 400;

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: ${WIDTH}px;
  }

  .modal-content {
    padding: 32px;
  }

  .modal-header {
    display: flex;
    gap: 24px;
    align-items: center;
    align-self: center;
    padding: 0;
    border: 0;
  }

  .modal-body {
    margin-top: 32px;
    padding: 0;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-top: 32px;
    border: 0;

    * {
      margin: 0;
    }
  }
`;

export const HeaderInfos = styled.section`
  width: 232px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const Title = styled(Modal.Title)`
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const Subtitle = styled.p`
  color: ${({ theme }: any) => theme.gray3};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  input,
  textarea {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;

    &::placeholder {
      color: ${({ theme }: any) => theme.gray1};
    }
  }
`;

export const Setting = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    display: inline;
    margin-right: 8px;
    color: ${({ theme }: any) => theme.gray3};
  }

  .MuiCheckbox-root {
    padding: 0;
    margin-right: -6px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .modal.show {
    backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.60);
  }

  .modal-backdrop.show {
    background-color: unset;
  }
`;
