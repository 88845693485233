import styled from 'styled-components/macro';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const LazyImageLoader = styled(LazyLoadImage)`
  margin: 0 auto;
  width: 100%;
`;

export const Marker = styled.div`
  position: absolute;

  &__marker--default {
    width: 25px;
    height: 25px;
    background-color: brown;
    border-radius: 50%;
    color: white;
    text-align: center;
  }
`;

export const Video = styled.video``;
