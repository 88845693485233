import React from 'react';

interface ProjectImagePlaceholderProps {
  className?: string;
}

const ProjectImagePlaceholder = ({
  className,
}: ProjectImagePlaceholderProps) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5_989)">
      <rect width="80" height="80" rx="16" fill="url(#paint0_linear_5_989)" />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9364 1.28457C27.1664 -0.529446 20.2077 3.48818 18.3936 10.2582L8.7589 46.2156C6.94488 52.9856 10.9625 59.9443 17.7325 61.7583L25.9047 63.9481L23.7149 72.1202C21.9009 78.8902 25.9185 85.8489 32.6886 87.663L68.6459 97.2977C75.416 99.1117 82.3747 95.0941 84.1887 88.3241L93.8235 52.3667C95.6375 45.5967 91.6199 38.638 84.8498 36.8239L76.6777 34.6342L78.8674 26.4621C80.6814 19.6921 76.6638 12.7333 69.8938 10.9193L33.9364 1.28457ZM68.5056 32.4445L70.6953 24.2724C71.3 22.0157 69.9608 19.6961 67.7041 19.0915L31.7467 9.45671C29.49 8.85203 27.1705 10.1912 26.5658 12.4479L16.931 48.4053C16.3264 50.662 17.6656 52.9815 19.9222 53.5862L28.0944 55.7759L36.6343 23.9046L68.5056 32.4445ZM36.2665 57.9656L42.6167 34.2665L66.3159 40.6166L59.9657 64.3158L36.2665 57.9656ZM34.0768 66.1378L65.9481 74.6777L74.488 42.8064L82.6601 44.9961C84.9168 45.6007 86.256 47.9203 85.6513 50.177L76.0166 86.1344C75.4119 88.3911 73.0923 89.7303 70.8357 89.1256L34.8783 79.4908C32.6216 78.8862 31.2824 76.5666 31.8871 74.3099L34.0768 66.1378Z"
        fill="white"
      />
      <rect x="20" y="20" width="40" height="40" rx="20" fill="white" />
      <g clipPath="url(#clip1_5_989)">
        <path
          d="M33.125 40H46.875"
          stroke="#4D4DFF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40 33.125V46.875"
          stroke="#4D4DFF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5_989"
        x1="22"
        y1="-7.33333"
        x2="60.6667"
        y2="80"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4D4DFF" />
        <stop offset="1" stopColor="#00CAF2" />
      </linearGradient>
      <clipPath id="clip0_5_989">
        <rect width="80" height="80" rx="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_5_989">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(30 30)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ProjectImagePlaceholder;
