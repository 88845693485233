import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 344px;
  }

  .modal-content {
    border-radius: 24px;
    padding: 48px 40px 40px;
    gap: 32px;
    border: 1px solid ${({ theme }) => theme.gray};
    box-shadow: 0px 11px 8px -8px rgba(127, 128, 135, 0.2);
  }

  .modal-header {
    padding: 0;
    border: 0;

    svg {
      position: absolute;
      top: 0px;
      right: -50px;
      color: ${({ theme }) => theme.white};
      cursor: pointer;
      font-size: 32px;
    }
  }

  .modal-body {
    padding: 0 8px;
    color: ${({ theme }) => theme.gray3};
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 12px;

    a {
      color: ${({ theme }) => theme.gray3} !important;
      text-decoration-line: underline !important;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-top: 32px;
    border: 0;

    * {
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
`;

export const Actions = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 48px;
  height: 48px;
`;
