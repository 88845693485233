import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { X, Check } from '@phosphor-icons/react';

import {
  ModalContainer,
  Container,
  LeftSide,
  RightSide,
  Button,
  Header,
  Badge,
  Content,
  Pricing,
  Items,
  Item,
  ComingSoon,
  PaymentTypeWrapper,
  Save,
  TotalPrice,
  PaymentType,
} from './UpgradeToProModal.styles';
import Spacer from '../Spacer/Spacer';
import { Row } from '../Row/Row';
import UserService from '../../services/user.service';
import { UserViewModel } from '../../typings';
import { useApp } from '../../hooks/useMain';

const IS_DEV = process.env.NODE_ENV === 'development';
enum PaymentTypeEnum {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

const PAYMENT_LINKS = {
  [PaymentTypeEnum.MONTHLY]: IS_DEV
    ? 'https://buy.stripe.com/test_cN27sQ43h3H56uQ5km'
    : 'https://buy.stripe.com/eVadRW6wegGf3fO145',
  [PaymentTypeEnum.YEARLY]: IS_DEV
    ? 'https://buy.stripe.com/test_aEUdReczN7Xl8CY3cd'
    : 'https://buy.stripe.com/00g15a9IqahR2bK5km',
};

const UpgradeToProModal = () => {
  const { state, setUpgradeToProModal } = useApp();
  const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.YEARLY
  );

  const user = useMemo(
    (): UserViewModel => new UserService().get(),
    [state.proModal]
  );

  function handleClose() {
    setUpgradeToProModal(undefined);
  }

  return (
    <ModalContainer
      show={Boolean(state.proModal?.title)}
      size="md"
      backdrop="static"
      backdropClassName="upgrade-to-pro-modal-backdrop"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <Container>
          <LeftSide>
            <h1>{state.proModal?.title}</h1>
            <small>{state.proModal?.subtitle}</small>
          </LeftSide>

          <RightSide>
            <Header>
              <Row gap="20px" aligment="center">
                <Badge>Pro</Badge>
                {paymentType === 'yearly' ? (
                  <Save>Save 30%!</Save>
                ) : (
                  <TotalPrice>$72/year</TotalPrice>
                )}
              </Row>

              <X
                color="#C8C9CF"
                size={26}
                weight="bold"
                cursor="pointer"
                onClick={handleClose}
              />
            </Header>

            <Spacer height={33} />

            <Content>
              <Pricing>
                <span>
                  <h1>{paymentType === 'yearly' ? '$50' : '$6'}</h1>
                  <small>
                    per {paymentType === 'yearly' ? 'year' : 'month'}
                  </small>
                </span>

                <PaymentTypeWrapper>
                  <PaymentType
                    active={paymentType === PaymentTypeEnum.YEARLY}
                    onClick={() => setPaymentType(PaymentTypeEnum.YEARLY)}
                  >
                    Yearly
                  </PaymentType>

                  <PaymentType
                    active={paymentType === PaymentTypeEnum.MONTHLY}
                    onClick={() => setPaymentType(PaymentTypeEnum.MONTHLY)}
                  >
                    Monthly
                  </PaymentType>
                </PaymentTypeWrapper>
              </Pricing>

              <Spacer height={32} />

              <a
                href={`${PAYMENT_LINKS[paymentType]}?prefilled_email=${String(
                  user?.email
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="button">Join Pro</Button>
              </a>

              <Spacer height={39} />

              <hr />

              <Spacer height={32} />

              <Items>
                <Item>
                  <Check color="#4D4DFF" size={18} weight="bold" />
                  Everything in free
                </Item>

                <Item>
                  <Check color="#4D4DFF" size={18} weight="bold" />
                  Unlimited uploads
                </Item>

                <Item>
                  <Check color="#4D4DFF" size={18} weight="bold" />
                  50mb max file size upload
                </Item>

                <Item>
                  <Check color="#4D4DFF" size={18} weight="bold" />
                  Early access to new features
                </Item>

                <Item>
                  <Check color="#4D4DFF" size={18} weight="bold" />
                  Private spaces <ComingSoon>SOON</ComingSoon>
                </Item>

                <Item>
                  <Check color="#4D4DFF" size={18} weight="bold" />
                  QuickClick case study builder <ComingSoon>SOON</ComingSoon>
                </Item>
              </Items>
            </Content>
          </RightSide>
        </Container>
      </Modal.Body>
    </ModalContainer>
  );
};

export default UpgradeToProModal;
