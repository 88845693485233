import React from 'react';

const RatioSquare = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="31"
      y="1"
      width="30"
      height="30"
      rx="3"
      transform="rotate(90 31 1)"
      fill="white"
      fillOpacity="0.15"
    />
    <rect
      x="31"
      y="1"
      width="30"
      height="30"
      rx="3"
      transform="rotate(90 31 1)"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export default RatioSquare;
