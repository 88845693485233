import styled, { keyframes, createGlobalStyle } from 'styled-components/macro';
import { ButtonTransparent } from '../../../../../../../../components/Button/Button';
import { Button as CopyButton } from '../CopyButton/CopyButton';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(77,77,255, 0.4); }
  60% { box-shadow: 0 0 0 50px rgba(77,77,255, 0); border-radius: 60px; }
  80% { box-shadow: 0 0 0 100px rgba(77,77,255, 0); border-radius: 100px; }
  100% { box-shadow: 0 0 0 0 rgba(77,77,255, 0); }
`;

export const Message = styled.div`
  width: 191px;
  height: 20px;
  position: absolute;
  bottom: 14px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  color: ${({ theme }) => theme.white};
  transition: all 0.5s ease;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
`;

export const EnlargeButton = styled(ButtonTransparent)`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: ${({ theme }) => theme.white};
  transition: all 0.5s ease;
  cursor: pointer;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

interface ContainerProps {
  drawing: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: ${({ drawing }) => (drawing ? '45px' : '0')};

  &:hover ${EnlargeButton} {
    opacity: 1;
  }

  &:hover ${CopyButton} {
    opacity: 1;
  }

  /* &:hover ${Message} {
    opacity: 1;
  } */
`;

interface ImageWrapperProps {
  show: boolean;
  mode?: {
    text: string;
    color: string;
  };
  isDrawing: boolean;
  isBig: boolean;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;

  .image-marker {
    background: ${({ theme }) => theme.white};
    border-radius: 12px 12px 0 0;

    /* &:before {
      content: ${({ mode }) => `'${mode?.text ? String(mode?.text) : ''}'`};
      width: 122px;
      height: 29px;
      background: ${({ mode }) => mode?.color};
      color: ${({ theme }) => theme.white};
      font-weight: 400;
      font-size: 12px;
      line-height: 110%;
      position: absolute;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px 0px 8px 0px;
      z-index: 1;
    } */

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: transparent;
      border-radius: 8px;
      border: 2px solid
        ${({ mode }) => (mode?.color ? `${mode.color}` : 'initial')};
      display: ${({ mode }) => (mode?.color ? 'initial' : 'none')};
      pointer-events: none;
    }
  }

  .image-marker__marker {
    z-index: ${({ isDrawing }) => (isDrawing ? 'unset' : 1)};

    &.hightlight {
      border-radius: 40px;
      box-shadow: 0 0 0 rgba(77, 77, 255, 0.4);
      animation: ${pulseAnimation} 2s infinite;
    }

    &.disabled {
      opacity: 0.3;
    }
  }

  .lazy-load-image-background {
    width: 556px;
  }

  img,
  video {
    width: 556px;
    border-radius: 8px;
    margin: 0;
    object-fit: contain;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray};
    /* cursor: ${({ show, mode }) =>
      mode || !show ? 'initial' : 'pointer'}; */
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFXSURBVHgBrZQhc8JAEIVfkGBQYDt4ZnDIUvBocHQGXzSmrUHDL6COH4BmUhyuzATfs2Bag77uS+4yoQQmR3gzL9nkki93m9sFRFrruc6mH7EvHokfkCYZaOnb9fqf5xEoZ3+3A8ZjXFWpBNTrQLMJdDrx7a34yfO8X14U4KDjEdhsgNkMGA6BwyG83eCE7DNOwKQI44osVFb6lgtooZyt0YtAy7mAVBBEFpXFo9xAarmMw8e7AM0MqcZdgPz7RmEOw/1TLOJmcX9aEagYVKunAy6q1eJwWzA7/JOwxICT2u04XNscrnno9eCsSuWkDKcWOBUr1mm/Dycl6v9DVqtCoFn2M2MCs0CZoskkTpMSv4es5ENSOgM5zRmzrBYLYLU6B3W7kc1PJIzdRqV+2fTH72TTC4LI+/1ZP/QvNtoU8ED8pS937Vbae14GMIu+YS6Za2WbaZr+AOKaFzSOA2h8AAAAAElFTkSuQmCC)
        4 4,
      auto !important;
  }

  video {
    object-fit: contain;
    margin-bottom: -7px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: all 0.2s;
    border-radius: 8px;
    cursor: pointer;
    display: ${({ show }) => (show ? 'initial' : 'none')};
  }

  &:hover:after {
    opacity: 1;
  }

  @media (min-width: 1700px) {
    img,
    video,
    .lazy-load-image-background {
      width: 756px;
    }
  }
`;

interface PinProps {
  show: boolean;
}

export const Pin = styled.div<PinProps>`
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.blue};
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  color: ${({ theme }) => theme.white};
  opacity: ${({ show }) => (show ? '1' : '0')};
`;

interface CommentWrapperProps {
  top?: number;
  left?: number;
}

export const CommentWrapper = styled.div<CommentWrapperProps>`
  position: absolute;
  top: ${({ top }) => `${String(top)}%`};
  left: ${({ left }) => `calc(${String(left)}% + 20px)`};
  z-index: 2;
`;

export const CanvasDrawWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

interface DoodleProps {
  border: boolean;
}

export const Doodle = styled.img<DoodleProps>`
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  border: ${({ border }) => (border ? '4px' : '0px')} solid transparent;
  border-radius: 12px 12px 0 0;
`;

export const LockMainContent = createGlobalStyle`
  #content {
    overflow: hidden;
  }
`;
