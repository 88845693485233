import React from 'react';
import styled, { css } from 'styled-components/macro';

const ENTER_KEY = 13;

const Input = styled.input`
  height: 48px;
  width: 100%;
  padding: 11px 20px;
  background: #f1f3f5;
  border: 0;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  border: 3px solid transparent;

  &:focus {
    border-color: ${({ theme }: any) => theme.blue};
  }

  &:active {
    border-color: ${({ theme }: any) => theme.blue};
  }

  &:disabled {
    pointer-events: none;
  }

  ${({ error }: any) =>
    error &&
    css`
      border-color: ${({ theme }: any) => theme.red} !important;
    `}
`;

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;

  ${({ disabled }: any) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const InputAction = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Error = styled.small`
  width: 100%;
  display: block;
  text-align: left;
  margin-top: 5px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: ${({ theme }: any) => theme.red};
`;

export interface TextFieldProps {
  type: string;
  id?: string;
  name: string;
  placeholder?: string;
  onChange?: Function;
  onBlur?: Function;
  onSubmit?: (value: string) => void;
  value?: string;
  error?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  ref?: any;
  children?: React.ReactNode;
  maxLength?: number;
  autoComplete?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  error,
  children,
  onSubmit,
  autoComplete = 'off',
  ...otherProps
}: any) => {
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === ENTER_KEY) {
      onSubmit?.((e.target as any)?.value);
    }
  }

  return (
    <Container disabled={otherProps.disabled}>
      <Input
        error={error}
        onKeyDown={handleKeyDown}
        autoComplete={autoComplete}
        {...otherProps}
      />
      {Boolean(children) && <InputAction>{children}</InputAction>}
      {Boolean(error) && <Error>{error}</Error>}
    </Container>
  );
};

export default TextField;
