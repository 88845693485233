/* eslint-disable class-methods-use-this */
import LocalStorageService from './localStorage.service';

const ACCESS_TOKEN_STORAGE_KEY = 'viewport-user-access-token';
const ID_TOKEN_STORAGE_KEY = 'viewport-user-id-token';
const REFRESH_TOKEN_STORAGE_KEY = 'viewport-user-refresh-token';
const USER_KEY = 'viewport-user';

class AuthService {
  saveToken(token: any): void {
    LocalStorageService.set(ACCESS_TOKEN_STORAGE_KEY, { token });
  }

  getToken(): string {
    const { data } = LocalStorageService.get(ACCESS_TOKEN_STORAGE_KEY);
    return data && data.token;
  }

  saveIdToken(idToken: any): void {
    LocalStorageService.set(ID_TOKEN_STORAGE_KEY, { idToken });
  }

  getIdToken(): string {
    const { data } = LocalStorageService.get(ID_TOKEN_STORAGE_KEY);
    return data && data.idToken;
  }

  saveRefreshToken(refreshToken: any): void {
    LocalStorageService.set(REFRESH_TOKEN_STORAGE_KEY, { refreshToken });
  }

  getRefreshToken(): string {
    const { data } = LocalStorageService.get(REFRESH_TOKEN_STORAGE_KEY);
    return data && data.refreshToken;
  }

  isAuthenticated(): boolean {
    const { data } = LocalStorageService.get(ACCESS_TOKEN_STORAGE_KEY);
    return Boolean(data?.token);
  }

  logout(): void {
    LocalStorageService.remove(ID_TOKEN_STORAGE_KEY);
    LocalStorageService.remove(REFRESH_TOKEN_STORAGE_KEY);
    LocalStorageService.remove(ACCESS_TOKEN_STORAGE_KEY);
    LocalStorageService.remove(USER_KEY);
  }
}

export default AuthService;
