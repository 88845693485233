import { AnalyticsBrowser } from '@june-so/analytics-next';
import { UserViewModel } from '../typings';

const IS_DEV = process.env.NODE_ENV === 'development';

class JuneService {
  private client?: AnalyticsBrowser;

  init(): JuneService {
    if (IS_DEV) return this;

    const response = AnalyticsBrowser.load({
      writeKey: 'xJv7EakdJaCUPp0p',
    });

    this.client = response;

    return this;
  }

  page(): void {
    if (!IS_DEV) this.client?.page();
  }

  identify(user: UserViewModel): void {
    if (!IS_DEV)
      this.client?.identify(user.username, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        avatar: user.picture,
      });
  }

  track(name: string, params?: any): void {
    if (!IS_DEV) this.client?.track(name, params);
  }
}

export default JuneService;
