import styled, { createGlobalStyle } from 'styled-components/macro';
import CloseIcon from '@material-ui/icons/Close';

export const Container = styled.div`
  width: 233px;
  height: 142px;
  padding: 20px;
  background: #37383d;
  border-radius: 16px;
  box-shadow: 0px 8px 8px -8px rgba(127, 128, 135, 0.2);

  button {
    border: 1px solid #7f8087;
  }
`;

export const Title = styled.p`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.16px;
`;

export const Subtitle = styled.p`
  color: #7f8087;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 8px;
`;

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  right: 20px;
  top: 30px;
  cursor: pointer;
  font-size: 22px !important;
  color: #c8c9cf;
`;

export const GlobalStyle = createGlobalStyle`
  .Toastify__toast--default {
    background: transparent !important;
    box-shadow: none !important;
    width: 255px !important;
    margin-left: 90px !important;
  }
`;
