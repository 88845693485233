import styled from 'styled-components/macro';
import 'react-image-lightbox/style.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Button } from '../../../../../../components/Button/Button';

export const Container = styled.div`
  position: relative;
  width: 600px;
  scroll-margin: 30px;

  &.disabled {
    opacity: 40%;
    pointer-events: none;
  }

  @media (min-width: 1700px) {
    width: 800px;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 600px;
  display: flex;
  column-gap: 12px;

  @media (min-width: 1700px) {
    width: 800px;
  }
`;

export const Details = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 56px 1fr auto;
  align-items: flex-start;
`;

export const CreatorAvatar = styled.img`
  max-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
`;

export const CreatorName = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.black};
  font-size: 16px;
  line-height: 120%;
  display: inline-block;
  width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  a {
    color: ${({ theme }) => theme.black};
    line-height: 120%;
  }

  &:hover {
    opacity: 80%;
  }
`;

export const Description = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
`;

export const Image = styled.img`
  width: 720px;
  height: 405px;
  border-radius: 12px;
  object-fit: cover;
  margin-left: 58px;
`;

export const Metadata = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.gray3};
  display: flex;
  column-gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;

  svg {
    font-size: 16px;
    color: ${({ theme }) => theme.gray3};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px; // height of the header
`;

interface CommentButtonProps {
  active: boolean;
}

export const CommentButton = styled(Button)<CommentButtonProps>`
  margin: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: visible !important;
  background: ${({ active, theme }: any) =>
    active ? theme.gray3 : theme.blue};

  &:hover {
    background: ${({ active, theme }: any) =>
      active ? theme.black : '#4833cd'};
  }
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 24px;
  background: ${({ theme }) => theme.white};
  border-radius: 0 0 12px 12px;
  border-top: 1px solid ${({ theme }) => theme.gray};
`;

export const Header = styled.header`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
  }
`;

export const UpdatedAt = styled.p``;
