import React from 'react';

const Chat = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.75 6C19.75 5.60218 19.592 5.22064 19.3107 4.93934C19.0294 4.65804 18.6478 4.5 18.25 4.5H15.25V1.5C15.25 1.10218 15.092 0.720644 14.8107 0.43934C14.5294 0.158035 14.1478 0 13.75 0H1.75C1.35218 0 0.970647 0.158035 0.689343 0.43934C0.408038 0.720644 0.250003 1.10218 0.250003 1.5V13.5C0.249603 13.642 0.290043 13.7812 0.366503 13.9008C0.442963 14.0205 0.55222 14.1157 0.681253 14.175C0.807628 14.2368 0.949138 14.2608 1.08882 14.2442C1.22849 14.2275 1.36041 14.171 1.46875 14.0813L4.75 11.4281V14.25C4.75 14.6478 4.90804 15.0294 5.18934 15.3107C5.47065 15.592 5.85218 15.75 6.25 15.75H15.025L18.5313 18.5813C18.6396 18.671 18.7715 18.7275 18.9112 18.7442C19.0509 18.7608 19.1924 18.7368 19.3188 18.675C19.4478 18.6157 19.557 18.5205 19.6335 18.4008C19.71 18.2812 19.7504 18.142 19.75 18V6ZM15.7563 14.4188C15.625 14.3087 15.4588 14.2488 15.2875 14.25H6.25V11.25H13.75C14.1478 11.25 14.5294 11.092 14.8107 10.8107C15.092 10.5294 15.25 10.1478 15.25 9.75V6H18.25V16.425L15.7563 14.4188Z"
      fill="white"
    />
  </svg>
);

export default Chat;
