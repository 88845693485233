const IMAGES_ACCEPTED = ['jpg', 'jpeg', 'png', 'gif'];
const VIDEOS_ACCEPTED = ['mp4'];

class FileUtils {
  value: any;

  constructor(date: File | string) {
    this.value = date;
  }

  isImage(): boolean {
    const isString = typeof this.value === 'string';

    if (isString) {
      return IMAGES_ACCEPTED.some((extension) =>
        this.value?.includes(`.${extension}`)
      );
    }

    return IMAGES_ACCEPTED.some((extension) =>
      this.value?.type?.includes(extension)
    );
  }

  isVideo(): boolean {
    const isString = typeof this.value === 'string';

    if (isString) {
      return VIDEOS_ACCEPTED.some((extension) =>
        this.value?.includes(`.${extension}`)
      );
    }

    return VIDEOS_ACCEPTED.some((extension) =>
      this.value?.type?.includes(extension)
    );
  }

  isUrl(): boolean {
    if (typeof this.value !== 'string') return false;

    return this.value?.includes('http');
  }

  isFile(): boolean {
    return this.value instanceof File;
  }
}

export default FileUtils;
