import Spacer from '../Spacer/Spacer';
import {
  Container,
  Content,
  Logo,
  Title,
  Description,
  Link,
} from './SmallScreenNotSupported.styles';

const SmallScreenNotSupported = () => (
  <Container id="small-screen-not-supported">
    <Content>
      <Logo src="/images/logo-without-background-v2.png" alt="Viewport logo" />

      <span>
        <Title>Viewport is currently only available on desktop. </Title>

        <Spacer height={26} />

        <Description>
          We know this is annoying, and we’re sorry about that.
          <br /> But we’re a tiny team and we gotta prioritize things.
          <br />
          We’ll get to mobile when we find the time. Thanks for
          <br />
          being patient, (if you are) we can’t tell.
        </Description>
      </span>

      <Link href="https://viewport.co/">Visit viewport.co</Link>
    </Content>
  </Container>
);

export default SmallScreenNotSupported;
