import styled, { createGlobalStyle } from 'styled-components/macro';
import { Modal } from 'react-bootstrap';
import { Carousel as CarouselResponsive } from 'react-responsive-carousel';
// import CloseIcon from '@material-ui/icons/Close';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { CaretRight, CaretLeft, X } from '@phosphor-icons/react';

export const ModalContainer = styled(Modal)`
  padding: 0 !important;

  .modal-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    margin: 0;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: transparent;
    border-radius: 0;
    border: 0;
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Carousel = styled(CarouselResponsive)`
  width: 100%;

  .carousel {
    position: inherit;
  }

  .slider {
    cursor: pointer;
  }

  .slide {
    height: 100vh !important;
    width: 100% !important;
  }
`;

export const Prev = styled(CaretLeft)`
  top: calc(50% - 30px);
  bottom: 0;
  position: absolute;
  z-index: 2;
  left: 20px;
  cursor: pointer;
  color: #fff;
`;

export const Next = styled(CaretRight)`
  top: calc(50% - 30px);
  bottom: 0;
  position: absolute;
  z-index: 2;
  right: 20px;
  cursor: pointer;
  color: #fff;
`;

export const CloseButton = styled(X)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #fff;
  z-index: 2;
`;

export const Image = styled.img`
  height: 100% !important;
  width: 100% !important;
  max-height: 99vh !important;
  max-width: 100vw !important;
  vertical-align: unset !important;
  object-fit: contain;
  margin: 0 auto;
  padding: 80px;
`;

export const GlobalStyle = createGlobalStyle`
  .modal-backdrop.show {
    opacity: 85% !important;
  }

  .bb-feedback-button-classic {
    display: none !important;
  }
`;
