import React from 'react';

const SmileySad = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z"
      stroke="#C8C9CF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M21.5625 28.125C23.1158 28.125 24.375 26.8658 24.375 25.3125C24.375 23.7592 23.1158 22.5 21.5625 22.5C20.0092 22.5 18.75 23.7592 18.75 25.3125C18.75 26.8658 20.0092 28.125 21.5625 28.125Z"
      fill="#C8C9CF"
    />
    <path
      d="M38.4375 28.125C39.9908 28.125 41.25 26.8658 41.25 25.3125C41.25 23.7592 39.9908 22.5 38.4375 22.5C36.8842 22.5 35.625 23.7592 35.625 25.3125C35.625 26.8658 36.8842 28.125 38.4375 28.125Z"
      fill="#C8C9CF"
    />
    <path
      d="M21.5625 28.125C23.1158 28.125 24.375 26.8658 24.375 25.3125C24.375 23.7592 23.1158 22.5 21.5625 22.5C20.0092 22.5 18.75 23.7592 18.75 25.3125C18.75 26.8658 20.0092 28.125 21.5625 28.125Z"
      fill="#C8C9CF"
    />
    <path
      d="M38.4375 28.125C39.9908 28.125 41.25 26.8658 41.25 25.3125C41.25 23.7592 39.9908 22.5 38.4375 22.5C36.8842 22.5 35.625 23.7592 35.625 25.3125C35.625 26.8658 36.8842 28.125 38.4375 28.125Z"
      fill="#C8C9CF"
    />
    <path
      d="M39.7454 41.2496C38.7576 39.5392 37.337 38.1189 35.6264 37.1314C33.9158 36.1439 31.9754 35.624 30.0002 35.624C28.025 35.624 26.0846 36.1439 24.374 37.1313C22.6633 38.1188 21.2427 39.5391 20.2549 41.2495"
      stroke="#C8C9CF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SmileySad;
