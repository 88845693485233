import React, { useRef, useState } from 'react';
import ReactCanvasDraw from 'react-canvas-draw';
import { X } from '@phosphor-icons/react';

import { Actions, Badge, ButtonsWrapper, Container } from './CanvasDraw.styles';
import {
  Button,
  ButtonOutline,
} from '../../../../../../../../components/Button/Button';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';

interface CanvasDrawProps {
  height: number;
  width: number;
  onCancel: () => void;
  onSave: (image: string) => void;
}

interface ReactCanvasDrawProps {
  getSaveData: () => any;
  loadSaveData: (saveData: string, immediate: boolean) => void;
  getDataURL: (
    fileType?: string,
    useBgImage?: boolean,
    backgroundColour?: string
  ) => string;
  clear: () => void;
  eraseAll: () => void;
  undo: () => void;
}

const CanvasDraw: React.FC<CanvasDrawProps> = ({
  width,
  height,
  onSave,
  onCancel,
}: CanvasDrawProps) => {
  const [isDirty, setDirty] = useState(false);
  const canvas = useRef<ReactCanvasDrawProps>();

  function handleOnChange() {
    const data = canvas?.current?.getSaveData();

    if (data) {
      const dataParsed = JSON.parse(data);

      setDirty(Boolean(dataParsed?.lines?.length));
    }
  }

  function handleSave() {
    const base64Png = canvas?.current?.getDataURL();

    if (base64Png) {
      onSave(base64Png);
    }
  }

  function handleUndo() {
    canvas?.current?.undo();
  }

  function handleClear() {
    setDirty(false);
    canvas?.current?.clear();
  }

  return (
    <Container height={height} width={width}>
      <ReactCanvasDraw
        hideGrid
        hideInterface
        ref={canvas}
        lazyRadius={0}
        canvasWidth={width}
        canvasHeight={height}
        brushColor="#FF5656"
        backgroundColor="transparent"
        brushRadius={3}
        onChange={handleOnChange}
      />

      <Actions id="drawing-canvas-actions">
        <Badge>
          You’re in drawing mode{' '}
          <Tooltip id="cancel-drawing" title="Cancel" placement="right">
            <X onClick={onCancel} cursor="pointer" size={16} />
          </Tooltip>
        </Badge>

        <ButtonsWrapper>
          <ButtonOutline
            type="button"
            width="70px"
            height="32px"
            color="#7F8087"
            borderColor="#E3E6EC"
            marginTop="0"
            alignSelf="initial"
            borderSize="1px"
            fontWeight="400"
            onClick={handleUndo}
            disabled={!isDirty}
          >
            Undo
          </ButtonOutline>

          <ButtonOutline
            type="button"
            width="63px"
            height="32px"
            color="#7F8087"
            borderColor="#E3E6EC"
            marginTop="0"
            alignSelf="initial"
            borderSize="1px"
            fontWeight="400"
            onClick={handleClear}
            disabled={!isDirty}
          >
            Clear
          </ButtonOutline>

          <Button
            type="button"
            width="66px"
            height="32px"
            marginTop="0"
            alignSelf="initial"
            onClick={handleSave}
            disabled={!isDirty}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </Actions>
    </Container>
  );
};

export default CanvasDraw;
