import React, { useEffect, useMemo } from 'react';
import uniqBy from 'lodash.uniqby';

import { Avatar, Container, Content } from './Avatars.styles';
import { BoardComment } from '../../../../../../../../typings';
import { useAvatarImage } from '../../../../../../../../hooks/useAvatarImage';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';

interface CommentsProps {
  data: {
    containerId: string;
    comments: BoardComment[];
    activeUserEmail: string | null;
  };
  className?: string;
  onSelect: (userEmail: string) => void;
}

const Avatars: React.FC<CommentsProps> = ({
  data,
  className,
  onSelect,
}: CommentsProps) => {
  const { getAvatarMemo } = useAvatarImage();

  const usersUniq = useMemo(() => {
    const users = data.comments?.map((item) => item.user);
    const uniqUsers = uniqBy(users, 'email');

    return uniqUsers;
  }, [data.comments]);

  function scrollToFocus() {
    document
      .getElementById(data.containerId)
      ?.scrollIntoView({ behavior: 'smooth' });
  }

  function handleClick(userEmail: string) {
    onSelect(userEmail);
  }

  useEffect(() => {
    if (data.activeUserEmail) {
      scrollToFocus();
    }
  }, [data.activeUserEmail]);

  if (!data.comments.length) return null;

  return (
    <Container className={className}>
      <Content>
        {usersUniq.map((user) => (
          <Tooltip
            title={`
            ${String(user?.firstName)}
            ${String(user?.lastName)}
          `}
            id={`${String(user?.email)}-user-avatar`}
            placement="right"
            key={user?.email}
          >
            <Avatar
              src={getAvatarMemo(user?.picture, user?.email)}
              selected={user?.email === data.activeUserEmail}
              active={Boolean(data.activeUserEmail)}
              onClick={() => handleClick(String(user?.email))}
            />
          </Tooltip>
        ))}
      </Content>
    </Container>
  );
};

export default Avatars;
