import styled from 'styled-components/macro';

interface RowProps {
  gap?: string;
  aligment?: string;
  justify?: string;
  width?: string;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  column-gap: ${({ gap }) => gap || 0};
  align-items: ${({ aligment }) => aligment || 'initial'};
  justify-content: ${({ justify }) => justify || 'initial'};
  width: ${({ width }) => width || 'initial'};
`;
