import React from 'react';

interface LockProps {
  className?: string;
}

const Lock = ({ className }: LockProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05263 2.13428C6.48298 2.13428 5.21053 3.27266 5.21053 4.67694V6.93708H4.89474C3.8483 6.93708 3 7.696 3 8.63219V14.2825C3 15.2187 3.8483 15.9776 4.89474 15.9776H13.1053C14.1517 15.9776 15 15.2187 15 14.2825V8.63219C15 7.696 14.1517 6.93708 13.1053 6.93708H12.7895V4.67694C12.7895 3.27267 11.517 2.13428 9.94737 2.13428H8.05263ZM10.8947 6.93708V4.67694C10.8947 4.20885 10.4706 3.82938 9.94737 3.82938H8.05263C7.52941 3.82938 7.10526 4.20885 7.10526 4.67694V6.93708H10.8947Z"
      fill="white"
    />
    <path
      d="M5.21053 6.93708V7.18708H5.46053V6.93708H5.21053ZM12.7895 6.93708H12.5395V7.18708H12.7895V6.93708ZM10.8947 6.93708V7.18708H11.1447V6.93708H10.8947ZM7.10526 6.93708H6.85526V7.18708H7.10526V6.93708ZM5.46053 4.67694C5.46053 3.43618 6.59415 2.38428 8.05263 2.38428V1.88428C6.37181 1.88428 4.96053 3.10915 4.96053 4.67694H5.46053ZM5.46053 6.93708V4.67694H4.96053V6.93708H5.46053ZM4.89474 7.18708H5.21053V6.68708H4.89474V7.18708ZM3.25 8.63219C3.25 7.85952 3.95947 7.18708 4.89474 7.18708V6.68708C3.73713 6.68708 2.75 7.53249 2.75 8.63219H3.25ZM3.25 14.2825V8.63219H2.75V14.2825H3.25ZM4.89474 15.7276C3.95947 15.7276 3.25 15.0552 3.25 14.2825H2.75C2.75 15.3822 3.73713 16.2276 4.89474 16.2276V15.7276ZM13.1053 15.7276H4.89474V16.2276H13.1053V15.7276ZM14.75 14.2825C14.75 15.0552 14.0405 15.7276 13.1053 15.7276V16.2276C14.2629 16.2276 15.25 15.3822 15.25 14.2825H14.75ZM14.75 8.63219V14.2825H15.25V8.63219H14.75ZM13.1053 7.18708C14.0405 7.18708 14.75 7.85952 14.75 8.63219H15.25C15.25 7.53249 14.2629 6.68708 13.1053 6.68708V7.18708ZM12.7895 7.18708H13.1053V6.68708H12.7895V7.18708ZM12.5395 4.67694V6.93708H13.0395V4.67694H12.5395ZM9.94737 2.38428C11.4058 2.38428 12.5395 3.43618 12.5395 4.67694H13.0395C13.0395 3.10915 11.6282 1.88428 9.94737 1.88428V2.38428ZM8.05263 2.38428H9.94737V1.88428H8.05263V2.38428ZM11.1447 6.93708V4.67694H10.6447V6.93708H11.1447ZM11.1447 4.67694C11.1447 4.04533 10.5818 3.57938 9.94737 3.57938V4.07938C10.3594 4.07938 10.6447 4.37236 10.6447 4.67694H11.1447ZM9.94737 3.57938H8.05263V4.07938H9.94737V3.57938ZM8.05263 3.57938C7.41824 3.57938 6.85526 4.04533 6.85526 4.67694H7.35526C7.35526 4.37236 7.64058 4.07938 8.05263 4.07938V3.57938ZM6.85526 4.67694V6.93708H7.35526V4.67694H6.85526ZM7.10526 7.18708H10.8947V6.68708H7.10526V7.18708Z"
      fill="#C8C9CF"
    />
  </svg>
);

export default Lock;
