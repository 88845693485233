import styled from 'styled-components/macro';

export const Picture = styled.img`
  border-radius: 15px;
  background: ${({ theme }: any) => theme.gray1};
  z-index: 1;
  object-fit: cover;
`;

export const Actions = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;

  button {
    color: ${({ theme }) => theme.white};
    font-size: 14px;
  }
`;

export const PictureWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover:after {
    opacity: 1;
  }

  &:hover ${Actions} {
    opacity: 1;
  }
`;

export const Uploader = styled.div`
  background: ${({ theme }) => theme.gray1};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface ContainerProps {
  size?: string;
}

export const Container = styled.div<ContainerProps>`
  ${Uploader} {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }

  ${Picture} {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }

  ${Actions} {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }

  ${PictureWrapper}:after {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }
`;
