import styled from 'styled-components/macro';

interface ItemProps {
  active: boolean;
}

export const Item = styled.li<ItemProps>`
  padding: 6px 8px 6px 8px;
  background: ${({ theme, active }) => (active ? theme.white : 'transparent')};
  opacity: ${({ active }) => (active ? '1' : '0.3')};
  border: 1px solid
    ${({ theme, active }) => (active ? theme.gray : 'transparent')};
  color: ${({ theme }) => theme.gray3};
  line-height: 100%;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 36px;

  &:hover {
    opacity: 50%;
  }

  @media (max-width: 1200px) {
    p {
      display: none;
    }
  }
`;

interface ContainerProps {
  showingSideBar: boolean;
}

export const Container = styled.ul<ContainerProps>`
  position: absolute;
  top: 20px;
  /* left: 87px; // sidebar width */
  left: ${({ showingSideBar }) => (showingSideBar ? '87px' : '0')};
  right: 0;
  margin-left: auto;
  margin-right: auto;

  width: 284px;
  height: 48px;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.gray2};
  border-radius: 12px;
  padding: 6px;

  @media (max-width: 1200px) {
    width: 138px;
  }
`;
