/* eslint-disable class-methods-use-this */
import BaseClient from './base.client';
import AuthService from '../services/auth.service';
import { NotificationMessageViewModel, NotificationResult } from '../typings';

class NotificationClient extends BaseClient {
  token: string;

  constructor() {
    super();
    this.token = new AuthService().getToken();
  }

  public async getAll(page = 1): Promise<NotificationResult> {
    return this.client
      .get(`/notifications`, { params: { page } })
      .then<NotificationResult>(({ data }) => data);
  }

  public async setAsRead(id: string): Promise<NotificationMessageViewModel[]> {
    return this.client
      .post(`/notifications/read`, { id })
      .then<NotificationMessageViewModel[]>(({ data }) => data);
  }

  public async clearAll(): Promise<void> {
    return this.client.post(`/notifications/clear`);
  }
}

export default NotificationClient;
