import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '../../../../components/Button/Button';

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 350px;
  }

  .modal-content {
    padding: 32px;
    border-radius: 24px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .modal-header,
  .modal-footer {
    border: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 32px;
    gap: 12px;
  }
`;

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  font-size: 30px !important;
  color: ${({ theme }) => theme.gray1};
`;

export const Title = styled(Modal.Title)`
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
`;

export const Content = styled.div`
  width: 382px;
  padding: 24px 12px;
`;

export const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.gray3};
`;

export const ConfirmButton = styled(Button)`
  background: #ff5656;
`;
