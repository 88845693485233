import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { ProjectAsset } from '../../../../../../typings';
import {
  GlobalStyle,
  ModalContainer,
  Carousel,
  Prev,
  Next,
  Image,
  CloseButton,
} from './CarouselModal.styles';

interface CarouselModalProps {
  show: boolean;
  selectedItem: number;
  images: ProjectAsset[];
  onClose: () => void;
}

const CarouselModal = ({
  show,
  selectedItem,
  images,
  onClose,
}: CarouselModalProps): JSX.Element => {
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setFocus(true);
      }, 100);
    }
  }, [show]);

  return (
    <>
      {show && <GlobalStyle />}

      <ModalContainer
        centered
        fullscreen
        keyboard
        show={show}
        backdrop="static"
        onHide={() => onClose()}
      >
        <Modal.Body>
          <CloseButton size={24} onClick={() => onClose()} />
          {Boolean(images) && show && (
            <Carousel
              emulateTouch
              infiniteLoop
              useKeyboardArrows
              autoFocus={focus}
              showStatus={false}
              autoPlay={false}
              showThumbs={false}
              showIndicators={false}
              selectedItem={selectedItem}
              renderArrowPrev={(onClickHandler: any, hasPrev: any) =>
                hasPrev && <Prev size={40} onClick={onClickHandler} />
              }
              renderArrowNext={(onClickHandler: any, hasNext: any) =>
                hasNext && <Next size={40} onClick={onClickHandler} />
              }
            >
              {images.map((image) => (
                <Image key={image.url} src={image.url} />
              ))}
            </Carousel>
          )}
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default CarouselModal;
