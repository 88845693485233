class ClipboardService {
  static async writeText(text: string): Promise<void> {
    try {
      // const hasPermission = await ClipboardService.askWritePermission();

      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error('Could not write text to clipboard', error);
      throw error;
    }
  }

  static async writeImage(blob: Blob): Promise<void> {
    try {
      // const hasPermission = await ClipboardService.askWritePermission();

      const image = new File([blob], 'image.png', { type: 'image/png' });
      const item = new ClipboardItem({ 'image/png': image });
      await navigator.clipboard.write([item]);
    } catch (error) {
      console.error('Could not write image to clipboard', error);
      throw new Error('Could not write image to clipboard');
    }
  }

  private static async askWritePermission() {
    try {
      if (navigator?.permissions?.query) {
        const { state } = await navigator.permissions.query({
          name: 'clipboard-write' as PermissionName,
        });
        return state === 'granted';
      }
      return true;
    } catch (error) {
      return false;
    }
  }
}

export default ClipboardService;
