import React from 'react';
import { ThemeProvider as StyledTheme } from 'styled-components';

const size = {
  mobileS: '320px',
  mobileM: '360px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktopM: '1919px',
  desktopL: '2560px',
};

const maxIn = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopM: `(max-width: ${size.desktopM})`,
  desktopL: `(min-width: ${size.desktopL})`,
};

export const color = {
  white: '#fff',
  black: '#000',
  black1: '#35363A',
  black2: '#252629',
  gray: '#E3E6EC',
  gray1: '#C8C9CF',
  gray2: '#F1F3F5',
  gray3: '#7F8087',
  blue: '#4D4DFF',
  red: '#FF5656',
  red1: '#F9DFDE',
  green: '#18ECBC',
};

const ThemeProvider = ({ children }: any) => (
  <StyledTheme theme={{ ...color, ...device, ...maxIn }}>
    {children}
  </StyledTheme>
);

export default ThemeProvider;
