import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  width: 51px;
  height: 51px;
  padding: 15px 15px 16px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.gray2};
  cursor: pointer;
`;

export const Content = styled.div`
  height: 100%;
  width: 51px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.gray2};
  cursor: pointer;
`;
