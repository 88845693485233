import styled, { css } from 'styled-components/macro';

export const Input = styled.input`
  height: 48px;
  width: 100%;
  padding: 16px 30px 16px 16px;
  background: transparent;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  border: 1px solid ${({ theme }) => theme.gray};

  &:focus {
    border-color: ${({ theme }: any) => theme.blue};
  }

  &:active {
    border-color: ${({ theme }: any) => theme.blue};
  }

  &:disabled {
    pointer-events: none;
  }

  ${({ error }: any) =>
    error &&
    css`
      border-color: ${({ theme }: any) => theme.red} !important;
    `}
`;

export const Textarea = styled.textarea`
  height: 100px;
  width: 100%;
  padding: 16px 30px 16px 16px;
  background: transparent;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  border: 1px solid ${({ theme }) => theme.gray};

  &:focus {
    border-color: ${({ theme }: any) => theme.blue};
  }

  &:active {
    border-color: ${({ theme }: any) => theme.blue};
  }

  &:disabled {
    pointer-events: none;
  }

  ${({ error }: any) =>
    error &&
    css`
      border-color: ${({ theme }: any) => theme.red} !important;
    `}
`;

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;

  ${({ disabled }: any) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const InputAction = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Error = styled.small`
  width: 100%;
  display: block;
  text-align: left;
  margin-top: 5px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: ${({ theme }: any) => theme.red};
`;

export const Label = styled.label`
  color: ${({ theme }: any) => theme.gray3};
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`;

export const Counter = styled.p`
  color: ${({ theme }) => theme.gray1};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;
