import styled from 'styled-components/macro';

interface ColumnProps {
  gap?: string;
  width?: string;
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  position: inherit;
  flex-direction: column;
  row-gap: ${({ gap }) => gap || 0};
  width: ${({ width }) => width || 'initial'};
`;
