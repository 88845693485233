/* eslint-disable class-methods-use-this */
import moment from 'moment';
import { UploadFilesJson } from '../typings';
import AuthService from '../services/auth.service';
import BaseClient from './base.client';
import { isStagEnv } from '../utils/env.utils';

class UploadClient extends BaseClient {
  public getLink(name: any, order: any, projectId: any) {
    const accessToken = new AuthService().getToken();
    const todayDate = moment().format('MM/DD/YYY');

    return this.client
      .post('project/asset/get-asset-presigned-url', {
        access_token: accessToken,
        project_id: projectId,
        asset_filename: name,
        asset_order: order,
        asset_date_posted: todayDate,
        asset_visibility: 'public',
      })
      .then(({ data: result }: any) => result.presigned_url);
  }

  public async uploadFiles(files: any[]): Promise<UploadFilesJson[]> {
    const formData = new FormData();

    files.map((file) => formData.append('images', file));

    return this.client
      .post('/uploads', formData)
      .then<UploadFilesJson[]>(({ data }) => data);
  }

  public async uploadTransformFiles(files: any[]): Promise<UploadFilesJson[]> {
    // if is stage env the endpoint should be the normal one because this is causing out of memory
    // due to the image convertiong that backend can't handle
    if (isStagEnv()) return this.uploadFiles(files);

    const formData = new FormData();

    files.map((file) => formData.append('images', file));

    return this.client
      .post('/uploads/transform', formData)
      .then<UploadFilesJson[]>(({ data }) => data);
  }

  public async getTotalUploads(): Promise<number> {
    return this.client.get('/uploads').then<number>(({ data }: any) => data);
  }
}

export default UploadClient;
