import styled from 'styled-components/macro';

export const Picture = styled.img`
  min-height: 130px;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${({ disabled }) => (disabled ? '272px' : '248px')};
  min-height: 130px;

  ${Picture} {
    cursor: ${({ disabled }) => (disabled ? 'pointer' : 'grab')};
    width: ${({ disabled }) => (disabled ? '272px' : '248px')};
  }

  &:after {
    content: '';
    width: ${({ disabled }) => (disabled ? '272px' : '248px')};
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    border-radius: 8px;
  }

  &:hover:after {
    background: ${({ disabled }) =>
      disabled
        ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))'
        : 'none'};
  }
`;
