import styled from 'styled-components/macro';
import { Checkbox as MaterialCheckBox } from '@material-ui/core';
import { Spinner as ReactSpinner } from 'react-bootstrap';

interface RootComponentProps {
  sizing?: string;
  checked?: boolean;
}

export const RootComponent = styled(MaterialCheckBox)<RootComponentProps>`
  &.MuiCheckbox-root {
    color: ${({ theme }) => theme.gray1} !important;
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.blue} !important;
  }

  .MuiSvgIcon-root {
    width: ${({ sizing }) => sizing || '1em'};
    height: 32px;

    path {
      stroke: ${({ checked }) => (checked ? 'unset' : 'white')};
      stroke-width: 1;
      color: ${({ theme, checked }) => (checked ? 'inherit' : theme.gray)};
    }
  }
`;

export const Spinner = styled(ReactSpinner)`
  width: 23px;
  height: 20px;
  color: ${({ theme }) => theme.blue};
  margin-right: 12px;
`;

interface CheckProps {
  size?: string;
  checked: boolean;
}

export const CheckboxContainer = styled.div<CheckProps>`
  width: ${({ size }) => size || '24px'};
  height: ${({ size }) => size || '24px'};
  background: ${({ checked, theme }) => (checked ? theme.blue : 'transparent')};
  border: 1px solid
    ${({ checked, theme }) => (checked ? 'transparent' : theme.gray3)};
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    width: ${({ size }) => (size ? `calc(${size} / 2)` : '12px')};
    height: ${({ size }) => (size ? `calc(${size} / 2)` : '12px')};
  }
`;

interface LoadingWrapperProps {
  size?: string;
}

export const LoadingWrapper = styled.div<LoadingWrapperProps>`
  width: ${({ size }) => size || '24px'};
  height: ${({ size }) => size || '24px'};

  ${Spinner} {
    width: ${({ size }) => size || '24px'};
    height: ${({ size }) => size || '24px'};
    margin: 0 !important;
    margin-left: 0 !important;
  }
`;
