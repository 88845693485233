import { Button, ButtonOutline } from '../../../../components/Button/Button';
import { Container, UnfollowButton } from './FollowButton.styles';

interface FollowButtonProps {
  onToggle: () => void;
  following: boolean;
}

const FollowButton = ({ following, onToggle }: FollowButtonProps) => (
  <Container>
    {following ? (
      <UnfollowButton type="button" hoverText="Unfollow" onClick={onToggle}>
        Following
      </UnfollowButton>
    ) : (
      <Button
        type="button"
        width="142px"
        height="48px"
        marginTop="0"
        borderColor="#4D4DFF"
        borderSize="1px"
        borderRadius="12px"
        onClick={onToggle}
      >
        Follow project
      </Button>
    )}
  </Container>
);

export default FollowButton;
