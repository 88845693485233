import styled, { keyframes } from 'styled-components/macro';
import { Spinner } from '@phosphor-icons/react';

const SpinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  animation: ${SpinAnimation} 2s linear infinite;
`;

interface ButtonSpinnerProps {
  size: string;
  color: string;
}

const ButtonSpinner = ({ size, color }: ButtonSpinnerProps) => (
  <SpinnerWrapper>
    <Spinner color={color} size={size} weight="bold" />
  </SpinnerWrapper>
);

export default ButtonSpinner;
