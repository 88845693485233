import React from 'react';
import {
  Container,
  Counter,
  Error,
  Header,
  Input,
  InputAction,
  Label,
  Textarea,
} from './InputField.styles';

const ENTER_KEY = 13;

export interface InputFieldProps {
  type: string;
  id?: string;
  name: string;
  placeholder?: string;
  onChange?: Function;
  onBlur?: Function;
  onSubmit?: (value: string) => void;
  value?: string;
  error?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  ref?: any;
  children?: React.ReactNode;
  maxLength?: number;
  rows?: string;
  autoComplete?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  error,
  rows,
  children,
  onSubmit,
  value,
  maxLength = 100,
  autoComplete = 'off',
  ...otherProps
}: any) => {
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === ENTER_KEY) {
      onSubmit?.((e.target as any)?.value);
    }
  }

  return (
    <Container disabled={otherProps.disabled}>
      <Header>
        <Label>{name}</Label>
        <Counter>
          {String(value.length)}/{maxLength}
        </Counter>
      </Header>

      {rows ? (
        <Textarea
          error={error}
          value={value}
          onKeyDown={handleKeyDown}
          {...otherProps}
        />
      ) : (
        <Input
          error={error}
          value={value}
          onKeyDown={handleKeyDown}
          autoComplete={autoComplete}
          {...otherProps}
        />
      )}

      {Boolean(children) && <InputAction>{children}</InputAction>}
      {Boolean(error) && <Error>{error}</Error>}
    </Container>
  );
};

export default InputField;
