import React from 'react';

interface ProjectStatusKilledProps {
  className?: string;
}

const ProjectStatusKilled = ({ className }: ProjectStatusKilledProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_19_1605)">
      <path
        d="M5.75 9.5C6.44036 9.5 7 8.94036 7 8.25C7 7.55964 6.44036 7 5.75 7C5.05964 7 4.5 7.55964 4.5 8.25C4.5 8.94036 5.05964 9.5 5.75 9.5Z"
        stroke="#7F8087"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 9.5C10.9404 9.5 11.5 8.94036 11.5 8.25C11.5 7.55964 10.9404 7 10.25 7C9.55964 7 9 7.55964 9 8.25C9 8.94036 9.55964 9.5 10.25 9.5Z"
        stroke="#7F8087"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 14V12"
        stroke="#7F8087"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 14V12"
        stroke="#7F8087"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13.5C11 13.6326 10.9473 13.7598 10.8536 13.8536C10.7598 13.9473 10.6326 14 10.5 14H5.5C5.36739 14 5.24021 13.9473 5.14645 13.8536C5.05268 13.7598 5 13.6326 5 13.5V12.2306C3.20687 11.2363 2 9.37812 2 7.25C2 4.07437 4.6875 1.5 8 1.5C11.3125 1.5 14 4.07437 14 7.25C14 9.37812 12.7931 11.2363 11 12.2306V13.5Z"
        stroke="#7F8087"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19_1605">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ProjectStatusKilled;
