/* eslint-disable class-methods-use-this */
import { UserViewModel, User, AuthToken } from '../typings';
import BaseClient from './base.client';
import AuthService from '../services/auth.service';

class UserClient extends BaseClient {
  token: string;

  constructor() {
    super();
    this.token = new AuthService().getToken();
  }

  public async signUp(payload: User): Promise<void> {
    return this.client.post(`/users/sign-up`, payload).then(({ data }) => data);
  }

  public async signUpWithGroup(payload: User, groupId: number): Promise<void> {
    return this.client
      .post(`/users/sign-up/external/${groupId}`, payload)
      .then(({ data }) => data);
  }

  public async signUpSimple(payload: User): Promise<UserViewModel> {
    return this.client
      .post(`/users/sign-up-simple`, payload)
      .then<UserViewModel>(({ data }) => data);
  }

  public async signIn(email: string, password: string): Promise<AuthToken> {
    return this.client
      .post(`/users/sign-in`, { email, password })
      .then<AuthToken>(({ data }) => data);
  }

  public async signInWithGoogle(code: string): Promise<AuthToken> {
    return this.client
      .post(`/users/sign-in/google/callback`, { code })
      .then<AuthToken>(({ data }) => data);
  }

  public async signupConfirmation(
    email: string,
    code: string
  ): Promise<AuthToken> {
    return this.client
      .post(`/users/sign-up-confirmation`, { email, code })
      .then<AuthToken>(({ data }) => data);
  }

  public async forgotPassword(email: string): Promise<void> {
    return this.client
      .post(`/users/forgot-password`, { email })
      .then<void>(({ data }) => data);
  }

  public async resetPassword(
    email: string,
    code: string,
    password: string
  ): Promise<void> {
    return this.client
      .post(`/users/reset-password`, { email, code, password })
      .then<void>(({ data }) => data);
  }

  public async resendConfirmationCode(email: string): Promise<AuthToken> {
    return this.client
      .post(`/users/sign-up-confirmation/resend`, { email })
      .then<AuthToken>(({ data }) => data);
  }

  public async getInfo(): Promise<UserViewModel> {
    return this.client
      .get(`/users/infos`)
      .then<UserViewModel>(({ data }) => data);
  }

  public async getFollowings(): Promise<UserViewModel[]> {
    return this.client
      .get(`/users/followings`)
      .then<UserViewModel[]>(({ data }) => data);
  }

  public async getFollowers(): Promise<UserViewModel[]> {
    return this.client
      .get(`/users/followers`)
      .then<UserViewModel[]>(({ data }) => data);
  }

  public async getByUsername(username: string): Promise<UserViewModel> {
    return this.client
      .get(`/users/username/${username}`)
      .then<UserViewModel>(({ data }) => data);
  }

  public async getAll(skip?: number, take?: number): Promise<UserViewModel[]> {
    return this.client
      .get(`/users`, { params: { skip, take } })
      .then<UserViewModel[]>(({ data }) => data);
  }

  public async findByNameOrUsernameOrEmail(
    search: string
  ): Promise<UserViewModel[]> {
    return this.client
      .get(`/users`, { params: { search } })
      .then<UserViewModel[]>(({ data }) => data);
  }

  public async update(payload: UserViewModel): Promise<UserViewModel> {
    return this.client
      .put(`/users`, payload)
      .then<UserViewModel>(({ data }) => data);
  }

  public async patchMetadata(
    payload: UserViewModel['metadata']
  ): Promise<void> {
    return this.client
      .patch('/users/metadata', payload)
      .then(({ data }) => data);
  }

  public async follow(userId: number): Promise<any> {
    return this.client.post(`/users/friendships/${userId}/follow`);
  }

  public async unfollow(userId: number): Promise<any> {
    return this.client.post(`/users/friendships/${userId}/unfollow`);
  }

  public async saveContact(
    firstName: string,
    lastName: string,
    email: string
  ): Promise<any> {
    return this.client.post(`/users/waiting-list`, {
      firstName,
      lastName,
      email,
    });
  }

  public async saveInterests(interests: string[]): Promise<void> {
    return this.client
      .post(`/users/interests`, { interests })
      .then<void>(({ data }) => data);
  }
}

export default UserClient;
