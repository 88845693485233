import styled from 'styled-components/macro';
import TextareaAutosize from 'react-textarea-autosize';

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  min-height: 40px;
  padding: ${({ disabled }) =>
    disabled ? '8px 14px 8px 14px;' : '8px 55px 8px 8px'};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.gray2 : theme.white};
  border: 1px solid transparent;
  position: relative;

  svg {
    cursor: grab;
    align-self: flex-start;
  }

  &:hover {
    border-color: ${({ theme, disabled }) =>
      disabled ? 'transparent' : theme.gray1};
  }
`;

export const Input = styled(TextareaAutosize)`
  width: 100%;
  max-height: 155px;
  font-size: 14px;
  color: ${({ theme, color }) => color || theme.gray3};
  background: transparent;
  font-weight: 500;
  border: 0;
  resize: none;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
  overflow: hidden;
`;

export const LinkSection = styled.div`
  display: flex;
  width: 100%;
  background: transparent;
  border-radius: 8px;
  outline: 4px solid transparent;

  a {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-size: 14px;
    color: ${({ theme }) => theme.gray3};

    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-decoration-line: underline !important;
  }
`;
