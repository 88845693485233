import styled from 'styled-components/macro';

interface ContainerProps {
  width: number;
  height: number;
}

export const Container = styled.div<ContainerProps>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: relative;
  z-index: 2;
`;

export const Actions = styled.div`
  width: 556px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 12px;
  background: ${({ theme }) => theme.white};
  margin-top: 12px;

  @media (min-width: 1700px) {
    width: 756px;
  }
`;

export const Badge = styled.div`
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.red1};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 15.4px */
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
