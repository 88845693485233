import UserClient from '../../../../clients/user.client';
import AuthService from '../../../../services/auth.service';
import JuneService from '../../../../services/june.service';
import UserService from '../../../../services/user.service';

const IS_DEV = process.env.NODE_ENV === 'development';

const useLogin = () => {
  async function performSignin(
    email: string,
    password: string,
    setFieldError: Function,
    setLoading: (loading: boolean) => void
  ) {
    try {
      setLoading(true);

      const analytics = new JuneService().init();
      const result = await new UserClient().signIn(email, password);

      new AuthService().saveRefreshToken(result.refreshToken);
      new AuthService().saveIdToken(result.idToken);
      new AuthService().saveToken(result.accessToken);

      const userInfos = await new UserClient().getInfo();

      new UserService().login(userInfos);

      if (!IS_DEV) {
        (window as any)?.clarity(
          'identify',
          userInfos.email,
          userInfos.username
        );
        analytics.identify(userInfos);
      }

      analytics.track('Signed In');
    } catch (error: any) {
      if (error?.response?.data?.error?.includes('User is not confirmed')) {
        setFieldError('email', 'You need to confirm your email first!');
      } else {
        setFieldError('email', ' ');
        setFieldError(
          'password',
          error?.data?.message || 'Something went wrong while trying to sign in'
        );
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }

  return {
    performSignin,
  };
};

export default useLogin;
