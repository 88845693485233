import React from 'react';

const Timeline = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="9" y="16.5" width="12" height="4.5" rx="2" fill="#7F8087" />
    <rect x="9" y="3" width="12" height="4.5" rx="2" fill="#7F8087" />
    <rect x="3" y="9" width="18" height="6" rx="2" fill="#7F8087" />
  </svg>
);

export default Timeline;
