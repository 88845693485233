import styled from 'styled-components/macro';
import { ButtonOutline } from '../../../../components/Button/Button';

export const Container = styled.div``;

export const UnfollowButton = styled(ButtonOutline)`
  width: 142px;
  height: 48px;
  line-height: 44px;
  margin-top: 0;
  border: 1px solid ${({ theme }: any) => theme.blue};
  color: ${({ theme }: any) => theme.blue};

  &:hover {
    background: ${({ theme }: any) => theme.gray2};
    border: 1px solid ${({ theme }: any) => theme.gray3};
    color: ${({ theme }: any) => theme.gray3};
    line-height: 100%;
  }
`;
