import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';
import UserClient from '../../clients/user.client';
import JuneService from '../../services/june.service';
import ROUTES from '../../routes';
import { useApp } from '../../hooks/useMain';
import { GoogleSignInState } from '../../typings';
import SignUp from '../../pages/Signup/Signup';

const IS_DEV = process.env.NODE_ENV === 'development';

export const GoogleCallback = () => {
  const { setErrorMessage, setLoading } = useApp();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [showCollectUsage, setShowCollectUsage] = useState(false);

  async function performSigninWithCode(code: string, state: string | null) {
    try {
      setLoading(true);
      const analytics = new JuneService().init();

      const result = await new UserClient().signInWithGoogle(code);

      new AuthService().saveRefreshToken(result.refreshToken);
      new AuthService().saveIdToken(result.idToken);
      new AuthService().saveToken(result.accessToken);

      const userInfos = await new UserClient().getInfo();

      new UserService().login(userInfos);

      if (!IS_DEV) {
        (window as any)?.clarity(
          'identify',
          userInfos.email,
          userInfos.username
        );
        analytics.identify(userInfos);
        analytics.track('Signed In with Google', { email: userInfos.email });
      }

      if (state === GoogleSignInState.SIGN_UP_NEW_USER) {
        setShowCollectUsage(true);
        return;
      }

      navigate(ROUTES.DASHBOARD);
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while trying to sign in'
      );
      navigate(ROUTES.SIGN_IN);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (code) {
      searchParams.delete('code');
      searchParams.delete('state');
      searchParams.delete('error');
      searchParams.delete('error_description');

      navigate({ search: searchParams.toString() });

      performSigninWithCode(code, state);

      return;
    }

    if (error) {
      setErrorMessage('Something went wrong while trying to sign in');
      Sentry.captureException(new Error(String(errorDescription)), {
        tags: { type: 'Google Sign In' },
      });
      navigate(ROUTES.SIGN_IN);
    }
  }, []);

  return showCollectUsage ? <SignUp initialStep="usage" /> : null;
};

export default GoogleCallback;
