import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.gray2};
  flex-direction: column;
  padding: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 899px) {
    display: flex;
  }
`;

export const Content = styled.div`
  width: 100%;
  position: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 32px;
  z-index: 3;
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
`;

export const Link = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.gray3};
`;
