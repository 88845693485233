import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MoonLoader from 'react-spinners/MoonLoader';
import { BAD_REQUEST } from 'http-status';

import {
  Content,
  InputsWrapper,
  Form,
  LoaderWrapper,
  Actions,
} from '../Create/Create.styles';

import { InfosHeader, Label, TextField } from './UserInfos.styles';
import UserClient from '../../../../clients/user.client';
import { useApp } from '../../../../hooks/useMain';
import { UserViewModel } from '../../../../typings';
import StringUtils from '../../../../utils/string.utils';
import { Row } from '../../../../components/Row/Row';
import ProfilePhotoUploader from '../../../../components/ProfilePhotoUploader/ProfilePhotoUploader';
import { Column } from '../../../../components/Column/Column';
import UploadClient from '../../../../clients/upload.client';
import ROUTES from '../../../../routes';
import { Button } from '../../../../components/Button/Button';

const validation = Yup.object({
  firstName: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  username: Yup.string().max(20, 'Must be 20 characters or less'),
});

interface UserInfosFormProps {
  onSuccess: () => void;
}

const UserInfosForm = ({ onSuccess }: UserInfosFormProps) => {
  const { setErrorMessage } = useApp();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      picture: null,
      username: '',
    },
    validationSchema: validation,
    onSubmit: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      performSubmit(values);
    },
  });

  async function uploadPicture(image: File): Promise<string> {
    try {
      const images = await new UploadClient().uploadFiles([image]);
      const imageLocation = images?.[0].location;

      return imageLocation;
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while uploading the image'
      );
      return '';
    }
  }

  async function performSubmit(data: any) {
    try {
      setIsLoading(true);

      const payload: UserViewModel = {
        firstName: data.firstName || undefined,
        lastName: data.lastName || undefined,
        username: data.username || undefined,
      };

      if (data.picture) {
        payload.picture = await uploadPicture(data.picture);
      }

      await new UserClient().update(payload);

      onSuccess();
    } catch (error: any) {
      const isInvalidGroup = error?.data?.statusCode === BAD_REQUEST;
      if (isInvalidGroup) {
        setErrorMessage('The invite URL is invalid');
      } else {
        setErrorMessage(error?.data?.message || 'Something went wrong');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const imageSrc = useMemo((): string => {
    if ((formik.values.picture as any) instanceof File) {
      return URL.createObjectURL(formik.values.picture as unknown as File);
    }

    return formik.values.picture || '';
  }, [formik.values.picture]);

  return (
    <Content>
      {isLoading ? (
        <LoaderWrapper>
          <MoonLoader color="#7F8087" size={30} />
        </LoaderWrapper>
      ) : (
        <>
          <Form noValidate onSubmit={formik.handleSubmit} gap="32px">
            <Row gap="24px">
              <ProfilePhotoUploader
                backgroundFancy
                size="100px"
                data={imageSrc}
                onChange={(item) =>
                  formik.setFieldValue('picture', item as File)
                }
              />

              <InfosHeader>
                <p>Finish your account!</p>
                <small>Upload a profile photo</small>
              </InfosHeader>
            </Row>

            <InputsWrapper>
              <Row gap="12px">
                <Column gap="6px">
                  <Label>First name</Label>
                  <TextField
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Steve"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    error={
                      formik.touched.firstName ? formik.errors.firstName : ''
                    }
                  />
                </Column>

                <Column gap="6px">
                  <Label>Last name</Label>
                  <TextField
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Jobs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    error={
                      formik.touched.lastName ? formik.errors.lastName : ''
                    }
                  />
                </Column>
              </Row>

              <Column gap="6px">
                <Label>Username</Label>
                <TextField
                  type="text"
                  id="username"
                  name="username"
                  placeholder="app.viewport.co/steve"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  error={
                    formik.touched.username && formik.errors.username
                      ? new StringUtils(formik.errors.username).capitalize()
                      : ''
                  }
                />
              </Column>
            </InputsWrapper>

            <Actions>
              <Button
                type="submit"
                width="90px"
                height="48px"
                fontSize="16"
                marginTop="0"
                borderRadius="12px"
                disabled={!formik.isValid}
              >
                Done
              </Button>
            </Actions>
          </Form>
        </>
      )}
    </Content>
  );
};

export default UserInfosForm;
