import React, { useEffect, useMemo, useState } from 'react';
import {
  BsChevronLeft as LeftArrowIcon,
  BsChevronRight as RightArrowIcon,
} from 'react-icons/bs';

import { BoardColumn, BoardItem, ProjectAsset } from '../../../../typings';
import {
  Container,
  Header,
  Content,
  Name,
  Description,
  HeaderContent,
  CloseButton,
  Items,
  Actions,
  Note,
  Link,
} from './ExpandableColumnDetails.styles';
import DateUtils from '../../../../utils/date.utils';
import FileUtils from '../../../../utils/file.utils';
import { ButtonTransparent } from '../../../../components/Button/Button';
import Spacer from '../../../../components/Spacer/Spacer';
import ImageItem from './components/ImageItem/ImageItem';
import CarouselModal from '../../../Project/steps/Feedback/components/CarouselModal/CarouselModal';

const CONTAINER_ID = 'project-column-expandable-details-item';

interface ExpandableColumnDetailsProps {
  columns: BoardColumn[];
  item: { columnId?: number; itemId?: number };
  onChange: (columnId: number, itemId: number) => void;
  onClose: () => void;
}

const ExpandableColumnDetails: React.FC<ExpandableColumnDetailsProps> = ({
  columns,
  item,
  onChange,
  onClose,
}: ExpandableColumnDetailsProps) => {
  const [itemToZoom, setItemToZoom] = useState<string | null>(null);
  const current = useMemo(
    (): BoardColumn | undefined =>
      columns.find((column) => column.id === item?.columnId),
    [columns, item?.columnId]
  );

  const previous = useMemo((): BoardColumn | undefined => {
    if (!current) return undefined;
    return columns.find((column) => column.order === current?.order - 1);
  }, [columns, current]);

  const next = useMemo((): BoardColumn | undefined => {
    if (!current) return undefined;
    return columns.find((column) => column.order === current?.order + 1);
  }, [columns, current]);

  function scrollToFocus(itemId?: number) {
    if (!itemId) return;

    setTimeout(() => {
      document
        .getElementById(`${CONTAINER_ID}-${itemId}`)
        ?.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }

  function scrollToTop() {
    const firstItemId = current?.items?.[0]?.id;

    scrollToFocus(firstItemId);
  }

  function isImage(value: BoardItem): boolean {
    return (
      new FileUtils(value.value).isImage() ||
      new FileUtils(value.value).isVideo()
    );
  }

  function isLink(value: BoardItem): boolean {
    const regex = /^((https?|ftp):\/\/[^\s/$.?#].[^\s]*)$/i;
    return regex.test(value.value as string);
  }

  function handleNextColumn() {
    if (current) {
      const nextColumnId = columns[current?.order + 1]?.id;
      if (nextColumnId) onChange(nextColumnId, 0);
    }
  }

  function handlePreviousColumn() {
    if (current) {
      const previousColumnId = columns[current?.order - 1]?.id;
      if (previousColumnId) onChange(previousColumnId, 0);
    }
  }

  const images = useMemo(
    () =>
      current?.items
        ?.filter((value) => isImage(value))
        ?.map((image) => ({
          url: image.value,
        })),
    [current?.items]
  );

  useEffect(() => {
    if (item?.itemId === 0) {
      scrollToTop();
    } else {
      scrollToFocus(item?.itemId);
    }
  }, [item]);

  if (!item?.columnId) {
    return null;
  }

  return (
    <Container show={Boolean(item?.columnId)}>
      <Header>
        <HeaderContent>
          <div>
            <Name>{current?.name}</Name>
            {Boolean(current?.updatedAt) && (
              <Description>
                Updated {new DateUtils(current?.updatedAt).toView()}
              </Description>
            )}
          </div>

          <CloseButton onClick={onClose} />
        </HeaderContent>
      </Header>

      <Content id="column-container">
        <Items>
          {current?.items?.map((value) =>
            isImage(value) ? (
              <ImageItem
                id={`${CONTAINER_ID}-${String(value.id)}`}
                key={value.id}
                data={value}
                onShowDetails={setItemToZoom}
              />
            ) : (
              <>
                {isLink(value) ? (
                  <Link href={value.value as string} target="_blank">
                    {value.value as string}
                  </Link>
                ) : (
                  <Note
                    id={`${CONTAINER_ID}-${String(value.id)}`}
                    key={value.value as string}
                  >
                    {value.value as string}
                  </Note>
                )}
              </>
            )
          )}
          <div>
            <Spacer height={60}>&nbsp;</Spacer>
          </div>
        </Items>
      </Content>

      <Actions>
        {previous ? (
          <ButtonTransparent
            type="button"
            marginTop="0"
            width="140px"
            height="48px"
            background="transparent"
            onClick={handlePreviousColumn}
          >
            <LeftArrowIcon size="20px" color="#C8C9CF" />{' '}
            <p>{previous?.name}</p>
          </ButtonTransparent>
        ) : (
          <span />
        )}

        {next ? (
          <ButtonTransparent
            type="button"
            marginTop="0"
            width="140px"
            height="48px"
            background="transparent"
            onClick={handleNextColumn}
          >
            <p>{next?.name}</p> <RightArrowIcon size="20px" color="#C8C9CF" />
          </ButtonTransparent>
        ) : (
          <span />
        )}
      </Actions>

      <CarouselModal
        show={Boolean(itemToZoom)}
        images={images as ProjectAsset[]}
        selectedItem={
          (images as ProjectAsset[])?.findIndex(
            (image) => image.url === itemToZoom
          ) || 0
        }
        onClose={() => setItemToZoom(null)}
      />
    </Container>
  );
};

export default ExpandableColumnDetails;
