import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 536px;
  height: 100%;
  background-color: ${({ theme }) => theme.gray2};
  border-radius: 8px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: rgb(77, 77, 255);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;
  }
`;

export const Placeholder = styled.img`
  width: 536px;
  height: 350px;
  object-fit: cover;
  border-radius: 8px;
`;

interface PlayerProps {
  width?: string;
  height?: string;
}

export const Player = styled.img<PlayerProps>`
  width: ${({ width }) => width || '536px'};
  height: ${({ height }) => height || '300px'};
  object-fit: cover;
`;
