/* eslint-disable class-methods-use-this */
import axios, { AxiosInstance } from 'axios';
import { BAD_REQUEST } from 'http-status';
import AuthService from '../services/auth.service';
import { AuthToken } from '../typings';

const FORBIDDEN = 403;

class TokenClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: '/api',
      timeout: 50000,
    });
  }

  public async refreshToken(refreshToken: string): Promise<AuthToken> {
    return this.client
      .post(`/users/refresh-token`, { refreshToken })
      .then<AuthToken>(({ data }) => data)
      .catch((error) => {
        const isRefreshTokenExpired = error.response.status === FORBIDDEN;
        const isRefreshTokenError = error.response.status === BAD_REQUEST;

        if (isRefreshTokenExpired || isRefreshTokenError) {
          new AuthService().logout();
          window.location.assign(window.location.origin);
        }

        return error;
      });
  }
}

export default TokenClient;
