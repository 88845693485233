import styled from 'styled-components/macro';
import DefaultTextField, {
  TextFieldProps,
} from '../../../../components/TextField/TextField';

export const TextField = styled(DefaultTextField)<TextFieldProps>`
  border: 1px solid ${({ theme }: any) => theme.gray};
  border-radius: 8px;
  background-color: #ffffff;
  height: 50px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
  background: ${({ theme }) => theme.white};

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.gray1};
  }
`;

export const InfosHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  small {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    opacity: 0.3;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: ${({ theme }) => theme.gray3};
  margin: 0;
`;
