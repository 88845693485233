import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import MoonLoader from 'react-spinners/MoonLoader';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FaPlay as Play } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { DownloadSimple, ArrowLeft, Trash } from '@phosphor-icons/react';

import gifshot from '../../../../../../services/gif.service';

import {
  ModalContainer,
  Title,
  Subtitle,
  Content,
  EmptyState,
  Image,
  LoaderWrapper,
  ImageGalleryPreview,
  ImageContainer,
  CloseButton,
  Action,
  GlobalStyle,
  SupportText,
  UpgradeText,
  Ratios,
  RatioCard,
} from './GifRecapModal.styles';
import { useApp } from '../../../../../../hooks/useMain';
import { BoardItem, UserType } from '../../../../../../typings';
import BoardClient from '../../../../../../clients/board.client';
import ROUTES from '../../../../../../routes';
import {
  Button,
  ButtonFancy,
} from '../../../../../../components/Button/Button';
import { useAvatarImage } from '../../../../../../hooks/useAvatarImage';
import DropdownSelect from '../../../../../../components/DropdownSelect/DropdownSelect';
import GifPlayer from '../GifPlayer/GifPlayer';
import { CheckboxNew } from '../../../../../../components/Checkbox/Checkbox';
import ImageUtils from '../../../../../../utils/image.utils';
import UserService from '../../../../../../services/user.service';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import RatioSquare from '../../../../../../components/Icon/icons/RatioSquare';
import RatioLandscape from '../../../../../../components/Icon/icons/RatioLandscape';
import RatioPortrait from '../../../../../../components/Icon/icons/RatioPortrait';
import { Row } from '../../../../../../components/Row/Row';

export const FPS_OPTIONS = [
  {
    name: '0.25 FPS',
  },
  {
    name: '0.5 FPS',
  },
  {
    name: '1 FPS',
  },
  {
    name: '1.5 FPS',
  },
  {
    name: '2 FPS',
  },
];

interface ImageDraggableProps {
  src: string;
  selected: boolean;
  selectable: boolean;
}

export const ImageDraggable = SortableHandle<ImageDraggableProps>(
  ({ src, selected, selectable }: ImageDraggableProps) => (
    <Image src={src} selected={selected} selectable={selectable} />
  )
);

interface SortableImageProps {
  src: string;
  onToggle: (value: boolean) => void;
  onRemove?: (value: string) => void;
  selected: boolean;
  selectable: boolean;
}

export const SortableImage = SortableElement<SortableImageProps>(
  ({ src, selected, selectable, onToggle, onRemove }: SortableImageProps) => (
    <ImageContainer onClick={() => onToggle(!selected)}>
      <Action>
        {selectable && (
          <CheckboxNew checked={selected} onChange={() => {}} name={src} />
        )}

        {Boolean(onRemove) && (
          <Tooltip title="Remove" id="remove-assets" placement="top">
            <Button
              type="button"
              onClick={() => onRemove?.(src)}
              height="24px"
              width="24px"
              marginTop="0"
              background="white"
              backgroundOverlay="white"
              borderRadius="4px"
            >
              <Trash size={16} color="#7F8087" weight="bold" />
            </Button>
          </Tooltip>
        )}
      </Action>

      <ImageDraggable src={src} selected={selected} selectable={selectable} />
    </ImageContainer>
  )
);

export interface SortableProps {
  items: BoardItem[];
  selecteds?: string[];
  onToggle?: (value: string) => void;
  onRemove?: (value: string) => void;
  allowSelection?: boolean;
  allowDragDrop?: boolean;
  children?: React.ReactNode;
}

export const Sortable = SortableContainer<SortableProps>(
  ({
    items,
    selecteds = [],
    allowSelection: selectable = true,
    allowDragDrop = true,
    onToggle,
    onRemove,
    children,
  }: SortableProps) => {
    function isIncluded(id: string): boolean {
      return selecteds.some((item) => item === id);
    }

    const { getAvatarMemo } = useAvatarImage();

    return (
      <ImageGalleryPreview>
        {items?.map((value: BoardItem, index: any) => (
          <SortableImage
            key={index}
            index={index}
            selectable={selectable}
            disabled={!allowDragDrop}
            src={getAvatarMemo(
              new ImageUtils(value.value as string).getSmall(),
              value.id
            )}
            onToggle={() => onToggle?.(value.value as string)}
            onRemove={onRemove}
            selected={isIncluded(value.value as string)}
          />
        ))}

        {children}
      </ImageGalleryPreview>
    );
  }
);

interface GifRecapModalProps {
  open: boolean;
  onClose: () => void;
}

const GifRecapModal: React.FC<GifRecapModalProps> = ({
  open,
  onClose,
}: GifRecapModalProps) => {
  const {
    setErrorMessage,
    setSuccessMessage,
    setUpgradeToProModal,
    isProUser,
  } = useApp();
  const [fps, setFps] = useState<string>('1 FPS');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<BoardItem[]>([]);
  const [selecteds, setSelecteds] = useState<string[]>([]);
  const [generatedGit, setGeneratedGif] = useState<string>('');
  const [step, setStep] = useState<
    'dimension' | 'selection' | 'ordering' | 'preview'
  >('dimension');
  const [ratio, setRatio] = useState<'square' | 'portrait' | 'landscape'>(
    'landscape'
  );

  const maxImagesToBeSelected = useMemo(
    () => (isProUser ? 30 : 10),
    [isProUser]
  );

  const title = useMemo(() => {
    if (step === 'dimension') {
      return 'Select a ratio for your new gif recap';
    }

    if (step === 'preview') {
      return 'Your recap is ready!';
    }

    if (step === 'ordering') {
      return 'Customize the order of your recap';
    }

    return 'Select images from your projects to recap';
  }, [step]);

  const dimensionOutput = useMemo(() => {
    if (ratio === 'square') {
      return {
        width: 1200,
        height: 1200,
      };
    }

    if (ratio === 'landscape') {
      return {
        width: 1200,
        height: 675,
      };
    }

    return {
      width: 675,
      height: 1200,
    };
  }, [ratio]);

  const dimensionPreview = useMemo(() => {
    if (ratio === 'square') {
      return {
        width: 400,
        height: 400,
      };
    }

    if (ratio === 'landscape') {
      return {
        width: 536,
        height: 300,
      };
    }

    return {
      width: 250,
      height: 400,
    };
  }, [ratio]);

  function handleClose() {
    onClose();
    setData([]);
    setSelecteds([]);
    setGeneratedGif('');
    setStep('dimension');
    setRatio('landscape');
  }

  function handleGoBack() {
    if (step === 'selection') {
      setStep('dimension');
    }

    if (step === 'ordering') {
      setStep('selection');
    }

    if (step === 'preview') {
      setStep('ordering');
    }
  }

  function handleNextStep() {
    if (step === 'dimension') {
      setStep('selection');
    }

    if (step === 'selection') {
      setStep('ordering');
    }

    if (step === 'ordering') {
      setStep('preview');
    }
  }

  function handleSelection(value: string) {
    const isAlreadySelected = selecteds.some((item) => item === value);
    if (isAlreadySelected) {
      const withoutSelected = selecteds.filter((item) => item !== value);
      setSelecteds(withoutSelected);
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (!isAlreadySelected && selecteds.length < maxImagesToBeSelected) {
      setSelecteds([...selecteds, value]);
    }
  }

  async function fetchData() {
    try {
      setIsLoading(true);

      const result = await new BoardClient().getLatestUploads();

      setData(result);
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while fetching the projects'
      );
    } finally {
      setIsLoading(false);
    }
  }

  function getSelectedsInOrder<T>(onyValue: boolean): T[] {
    const result = selecteds.map((selected) =>
      data.find((item) => item.value === selected)
    );

    if (onyValue) {
      return result.map((item) => item?.value) as T[];
    }

    return result as T[];
  }

  function generateGif() {
    setIsLoading(true);

    const options = {
      images: getSelectedsInOrder<string>(true),
      gifWidth: dimensionOutput.width,
      gifHeight: dimensionOutput.height,
      interval: 0.1,
      numFrames: 25,
      sampleInterval: 10,
      frameDuration: Number(fps.split(' ')[0]) * 10,
      numWorkers: 2,
      progressCallback: (e: any) => console.log(e),
    };

    gifshot.createGIF(options, (obj: any) => {
      if (!obj.error) {
        setIsLoading(false);
        setStep('preview');
        setGeneratedGif(obj.image);
      } else {
        setIsLoading(false);
        console.log(obj.error);
      }
    });
  }

  function onSortEnd({ oldIndex, newIndex }: any) {
    if (step === 'selection') {
      const filesReordered = arrayMove(data, oldIndex, newIndex);
      setData(filesReordered);
    }

    if (step === 'ordering') {
      const selectedsReordered = arrayMove(selecteds, oldIndex, newIndex);
      setSelecteds(selectedsReordered);
    }
  }

  function downloadGif() {
    const link = document.createElement('a');
    link.href = generatedGit;
    link.download = 'viewport-recap.gif';
    link.click();

    setSuccessMessage('GIF Saved!');
  }

  function showUpgradetoProModal() {
    setUpgradeToProModal({
      title: 'Upgrade to Pro membership',
      subtitle: 'Unlock unlimited uploads and early access to new features',
    });
  }

  useEffect(() => {
    if (open === true) {
      fetchData();
    }
  }, [open]);

  return (
    <ModalContainer
      show={open}
      size="md"
      backdrop="static"
      keyboard={false}
      centered
    >
      <GlobalStyle />
      <Modal.Header>
        <Title>{title}</Title>

        {(step === 'selection' || step === 'ordering') && (
          <Subtitle>
            {step === 'selection' ? (
              <>
                {selecteds.length}/{maxImagesToBeSelected} images selected
                {!isProUser && (
                  <>
                    {' '}
                    •{' '}
                    <UpgradeText onClick={showUpgradetoProModal}>
                      Select more with Pro
                    </UpgradeText>
                  </>
                )}
              </>
            ) : (
              'Files will be automatically centered in the frame'
            )}
          </Subtitle>
        )}
        <CloseButton onClick={handleClose} />
      </Modal.Header>

      <Modal.Body>
        {step === 'dimension' && (
          <Ratios>
            <RatioCard
              selected={ratio === 'square'}
              onClick={() => setRatio('square')}
            >
              <RatioSquare />
              <p>
                Square
                <br />
                1:1
              </p>
            </RatioCard>

            <RatioCard
              selected={ratio === 'landscape'}
              onClick={() => setRatio('landscape')}
            >
              <RatioLandscape />
              <p>
                Landscape
                <br />
                16:9
              </p>
            </RatioCard>

            <RatioCard
              selected={ratio === 'portrait'}
              onClick={() => setRatio('portrait')}
            >
              <RatioPortrait />
              <p>
                Portrait
                <br />
                9:16
              </p>
            </RatioCard>
          </Ratios>
        )}

        {(step === 'selection' || step === 'ordering') && (
          <>
            <Content>
              {isLoading && (
                <LoaderWrapper>
                  <MoonLoader color="#7F8087" loading />
                </LoaderWrapper>
              )}

              {!isLoading && (
                <>
                  <Sortable
                    useDragHandle
                    lockToContainerEdges
                    items={
                      step === 'selection' ? data : getSelectedsInOrder(false)
                    }
                    selecteds={step === 'selection' ? selecteds : []}
                    onSortEnd={onSortEnd}
                    onToggle={handleSelection}
                    allowDragDrop={step === 'ordering'}
                    allowSelection={step === 'selection'}
                    axis="xy"
                    helperClass="sortable-helper"
                    updateBeforeSortStart={(node: any) => {
                      node.node.style.zIndex = 9999;
                    }}
                  />

                  {!data.length && (
                    <EmptyState>
                      <p>
                        No projects <br />
                        or collections yet
                      </p>
                      <Link to={ROUTES.PROJECT_NEW}>
                        <ButtonFancy type="button">
                          Create a project
                        </ButtonFancy>
                      </Link>
                    </EmptyState>
                  )}
                </>
              )}
            </Content>
          </>
        )}

        {step === 'preview' && (
          <GifPlayer
            src={generatedGit}
            width={`${dimensionPreview.width}px`}
            height={`${dimensionPreview.height}px`}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        {step !== 'dimension' && (
          <Button
            type="button"
            width="102px"
            height="48px"
            marginTop="0"
            fontSize="16"
            background="#F1F3F5"
            backgroundOverlay="#F1F3F5"
            color="#7F8087"
            onClick={handleGoBack}
          >
            <ArrowLeft color="#7F8087" size={22} weight="bold" />
            Back
          </Button>
        )}

        {step === 'ordering' && (
          <DropdownSelect
            label="Project status"
            items={FPS_OPTIONS}
            value={String(fps)}
            width={144}
            height={48}
            hideChevronAfterSelect={false}
            optionsAtBottom
            onChange={(value: string) => setFps(value)}
          />
        )}

        {step === 'dimension' && (
          <Row justify="center" width="100%">
            <Button
              type="button"
              width="114px"
              height="48px"
              marginTop="0"
              fontSize="16"
              onClick={handleNextStep}
              disabled={!ratio}
            >
              Continue
            </Button>
          </Row>
        )}

        {step === 'selection' && (
          <Button
            type="button"
            width="114px"
            height="48px"
            marginTop="0"
            fontSize="16"
            onClick={handleNextStep}
            disabled={isLoading || !selecteds.length}
          >
            Continue
          </Button>
        )}

        {step === 'ordering' && (
          <Button
            type="button"
            width="128px"
            height="48px"
            marginTop="0"
            fontSize="16"
            onClick={generateGif}
            disabled={isLoading || !selecteds.length}
          >
            <Play color="#FFF" size={16} />
            Preview
          </Button>
        )}

        {step === 'preview' && (
          <Button
            type="button"
            width="144px"
            height="48px"
            marginTop="0"
            fontSize="16"
            fontWeight="600"
            onClick={downloadGif}
          >
            <DownloadSimple color="#FFF" size={22} weight="fill" />
            Download
          </Button>
        )}
      </Modal.Footer>
    </ModalContainer>
  );
};

export default GifRecapModal;
