import React from 'react';

const NotFoundIcon = () => (
  <svg
    width="321"
    height="150"
    viewBox="0 0 321 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_946_1648)">
      <path
        d="M62.6625 143.6V115.4H1.66248V93.6L39.6625 3.6H67.0625L30.6625 90.8V92H62.6625V62.2H88.8625V92H104.462V115.4H88.8625V143.6H62.6625Z"
        fill="url(#paint0_linear_946_1648)"
      />
      <path
        d="M159.291 146.2C135.291 146.2 111.691 129.4 111.691 100.4V46.8C111.691 17.8 135.291 1 159.291 1C183.491 1 207.091 17.8 207.091 46.8V100.4C207.091 129.4 183.491 146.2 159.291 146.2ZM159.291 121.2C171.891 121.2 180.891 112.2 180.891 98.6V48.6C180.891 35.2 171.691 26 159.291 26C147.091 26 137.891 35.2 137.891 48.6V98.6C137.891 112.2 146.891 121.2 159.291 121.2Z"
        fill="url(#paint1_linear_946_1648)"
      />
      <path
        d="M277.537 143.6V115.4H216.537V93.6L254.537 3.6H281.937L245.537 90.8V92H277.537V62.2H303.737V92H319.337V115.4H303.737V143.6H277.537Z"
        fill="url(#paint2_linear_946_1648)"
      />
      <path
        d="M62.1625 143.6V144.1H62.6625H88.8625H89.3625V143.6V115.9H104.462H104.962V115.4V92V91.5H104.462H89.3625V62.2V61.7H88.8625H62.6625H62.1625V62.2V91.5H31.1625V90.9002L67.5239 3.79261L67.813 3.1H67.0625H39.6625H39.3308L39.2019 3.40552L1.20185 93.4055L1.16248 93.4988V93.6V115.4V115.9H1.66248H62.1625V143.6ZM277.037 143.6V144.1H277.537H303.737H304.237V143.6V115.9H319.337H319.837V115.4V92V91.5H319.337H304.237V62.2V61.7H303.737H277.537H277.037V62.2V91.5H246.037V90.9002L282.399 3.79261L282.688 3.1H281.937H254.537H254.206L254.077 3.40552L216.077 93.4055L216.037 93.4988V93.6V115.4V115.9H216.537H277.037V143.6ZM111.191 100.4C111.191 129.735 135.077 146.7 159.291 146.7C183.703 146.7 207.591 129.736 207.591 100.4V46.8C207.591 17.4641 183.703 0.5 159.291 0.5C135.077 0.5 111.191 17.4653 111.191 46.8V100.4ZM180.391 98.6C180.391 105.288 178.18 110.807 174.456 114.652C170.734 118.496 165.468 120.7 159.291 120.7C153.216 120.7 147.999 118.498 144.301 114.653C140.602 110.808 138.391 105.288 138.391 98.6V48.6C138.391 35.4578 147.385 26.5 159.291 26.5C171.4 26.5 180.391 35.461 180.391 48.6V98.6Z"
        stroke="url(#paint3_linear_946_1648)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_946_1648"
        x="0.662476"
        y="0"
        width="319.675"
        height="149.199"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.890196 0 0 0 0 0.901961 0 0 0 0 0.92549 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_946_1648"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_946_1648"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_946_1648"
        x1="160.5"
        y1="1"
        x2="160.5"
        y2="146.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3E6EC" />
        <stop offset="1" stopColor="#F1F3F5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_946_1648"
        x1="160.5"
        y1="1"
        x2="160.5"
        y2="146.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3E6EC" />
        <stop offset="1" stopColor="#F1F3F5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_946_1648"
        x1="160.5"
        y1="1"
        x2="160.5"
        y2="146.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3E6EC" />
        <stop offset="1" stopColor="#F1F3F5" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_946_1648"
        x1="145.988"
        y1="-74.4004"
        x2="196.988"
        y2="167.6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1F3F5" />
        <stop offset="1" stopColor="#E3E6EC" />
      </linearGradient>
    </defs>
  </svg>
);

export default NotFoundIcon;
