import React from 'react';
import { useDropzone } from 'react-dropzone';

import {
  Container,
  Uploader,
  Picture,
  PictureWrapper,
  Actions,
} from './PictureUploader.styles';
import { useApp } from '../../../../../../hooks/useMain';
import { ButtonLink } from '../../../../../../components/Button/Button';
import ProjectImagePlaceholder from '../../../../../../components/Icon/icons/ProjectImagePlaceholder';

interface PictureUploaderProps {
  data?: string;
  onChange: (item: File | null) => void;
  size?: string;
  backgroundFancy?: boolean;
}

const MAX_FILES = 1;

const PictureUploader: React.FC<PictureUploaderProps> = ({
  data,
  onChange,
  size,
  backgroundFancy = false,
}: PictureUploaderProps) => {
  const { setErrorMessage } = useApp();

  const onDrop = function (newFiles: any[]) {
    if (newFiles.length > MAX_FILES) {
      setErrorMessage(`Maximun files allowed are ${MAX_FILES}`);
      return;
    }

    if (onChange) {
      onChange(newFiles[0]);
    }
  };

  const {
    getRootProps,
    getInputProps,
    open: openUploader,
  } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES,
    accept: 'image/jpg, image/jpeg, image/png, image/gif, image/tiff',
    maxSize: 20971520, // 20 MB's
    noClick: Boolean(data),
  });

  return (
    <Container {...getRootProps()} size={size}>
      {!data && (
        <Uploader>
          <input {...getInputProps()} />
          <ProjectImagePlaceholder />
        </Uploader>
      )}

      {Boolean(data) && (
        <PictureWrapper>
          <input {...getInputProps()} />

          <Picture src={data} />

          <Actions>
            <ButtonLink onClick={() => openUploader()}>Replace</ButtonLink>
            <ButtonLink onClick={() => onChange(null)}>Remove</ButtonLink>
          </Actions>
        </PictureWrapper>
      )}
    </Container>
  );
};

export default PictureUploader;
