import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 345px;
  overflow-x: hidden;
  overflow-y: auto;
  row-gap: 8px;

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }
`;

interface AvatarProps {
  selected: boolean;
  active: boolean;
}

export const Avatar = styled.img<AvatarProps>`
  position: relative;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  object-fit: cover;

  cursor: pointer;
  border: 2px solid
    ${({ theme, selected }) => (selected ? theme.blue : 'transparent')};
  opacity: ${({ selected, active }) =>
    // eslint-disable-next-line no-nested-ternary
    active ? (selected && active ? 1 : '0.5') : 1};
`;
