import styled from 'styled-components/macro';
import DefaultTextField, {
  TextFieldProps,
  Error,
} from '../../../../components/TextField/TextField';

interface ContentProps {
  height?: string;
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  min-height: ${({ height }) => height || '256px'};

  ${Error} {
    position: absolute;
    margin-top: 2px;
    left: 3px;
    line-height: unset;
    margin-top: 0;
    font-weight: 400;
    font-size: 10px;
  }
`;

export const Header = styled.header`
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: 40px;

  p {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
  }

  small {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }: any) => theme.gray3};
  }
`;

export const TextBoxPlaceholder = styled.span`
  position: relative;

  p {
    position: absolute;
    z-index: 1;
    height: 48px;
    line-height: 48px;
    margin-left: 18px;
    margin-top: 10px;
    color: ${({ theme }: any) => theme.gray1};
  }

  input {
    padding-left: 118px;
  }
`;

export const TextField = styled(DefaultTextField)<TextFieldProps>`
  border: 1px solid ${({ theme }: any) => theme.gray};
  border-radius: 12px;
  background-color: #ffffff;
  height: 50px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
  background: ${({ theme }) => theme.white};

  &::placeholder {
    color: ${({ theme }) => theme.grey1};
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface FormProps {
  gap?: string;
}

export const Form = styled.form<FormProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '40px'};
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
