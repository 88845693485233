import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import DefaultTextField, {
  TextFieldProps,
  Error,
} from '../TextField/TextField';

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 533px;
  }

  .modal-content {
    padding: 40px;
    border-radius: 20px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    border: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
  }
`;

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
  font-size: 25px !important;
  color: ${({ theme }: any) => theme.gray1};
`;

export const Title = styled(Modal.Title)`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  margin-top: 40px;
`;

export const SubTitle = styled(Modal.Title)`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  color: ${({ theme }: any) => theme.gray3};
`;

export const Content = styled.form`
  margin-top: 32px;
  padding: 0px 55px;

  ${Error} {
    position: absolute;
    margin-top: 2px;
    left: 3px;
    line-height: unset;
    margin-top: 0;
    font-weight: 400;
    font-size: 10px;
  }
`;

export const Logo = styled.img`
  width: 44px;
  height: 44px;
  margin: 0 auto;
`;

export const TextField = styled(DefaultTextField)<TextFieldProps>`
  border: 2px solid ${({ theme }: any) => theme.gray};
  border-radius: 12px;
  background-color: #ffffff;
  height: 50px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;

  &::placeholder {
    color: ${({ theme }) => theme.grey1};
    font-weight: 400;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  column-gap: 12px;
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
