import React from 'react';

const RatioPortrait = () => (
  <svg
    width="18"
    height="32"
    viewBox="0 0 18 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="17"
      y="31"
      width="16"
      height="30"
      rx="3"
      transform="rotate(-180 17 31)"
      fill="white"
      fillOpacity="0.15"
    />
    <rect
      x="17"
      y="31"
      width="16"
      height="30"
      rx="3"
      transform="rotate(-180 17 31)"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export default RatioPortrait;
