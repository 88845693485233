import { Link } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { Container, Content, Logo, BottomText } from './Signup.styles';
import CreateAccount from './components/Create/Create';
import ROUTES from '../../routes';
import CodeVerificationForm from './components/CodeVerification/CodeVerification';
import UserInfosForm from './components/UserInfos/UserInfos';
import UsageForm from './components/Usage/Usage';
import InitialForm from './components/Initial/Initial';

type Step = 'initial' | 'creation' | 'code' | 'infos' | 'usage';
interface SignUpProps {
  initialStep?: Step;
  welcomePro?: boolean;
}

const SignUp = ({
  initialStep = 'initial',
  welcomePro = false,
}: SignUpProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [step, setStep] = useState<
    'initial' | 'creation' | 'code' | 'infos' | 'usage'
  >(initialStep);

  const showBorderInfos = useMemo(
    () => ['initial', 'creation'].includes(step),
    [step]
  );

  return (
    <Container>
      <Content>
        {showBorderInfos && (
          <Logo
            src="/images/logo-without-background-v2.png"
            alt="Viewport logo"
          />
        )}

        {step === 'initial' && (
          <InitialForm
            welcomePro={welcomePro}
            onSuccess={() => {
              setStep('creation');
            }}
          />
        )}

        {step === 'creation' && (
          <CreateAccount
            onSuccess={(userEmail, userPassword) => {
              setEmail(userEmail);
              setPassword(userPassword);
              setStep('code');
            }}
          />
        )}

        {step === 'code' && (
          <CodeVerificationForm
            onSuccess={() => setStep('infos')}
            data={{ email, password }}
          />
        )}

        {step === 'infos' && (
          <UserInfosForm onSuccess={() => setStep('usage')} />
        )}

        {step === 'usage' && <UsageForm />}
      </Content>

      {showBorderInfos && !welcomePro && (
        <Link to={ROUTES.SIGN_IN}>
          <BottomText>Have an account? Log in</BottomText>
        </Link>
      )}

      {step === 'code' && (
        <BottomText>Check your spam folder if you do not see it</BottomText>
      )}
    </Container>
  );
};

export default SignUp;
