import React, { useState } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { HandWaving } from '@phosphor-icons/react';

import { useNavigate } from 'react-router-dom';
import { LoaderWrapper } from '../Create/Create.styles';

import {
  Actions,
  Content,
  Header,
  Item,
  ItemInfo,
  Items,
} from './Usage.styles';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { Button } from '../../../../components/Button/Button';
import JuneService from '../../../../services/june.service';
import ROUTES from '../../../../routes';
import { useApp } from '../../../../hooks/useMain';

const UsageForm = () => {
  const { setCustomToast } = useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState('');
  const navigate = useNavigate();

  function performSubmit() {
    setIsLoading(true);
    new JuneService().init().track('Signup Usage', { usage: data });
    navigate(ROUTES.DASHBOARD);
    setCustomToast('Welcome to Viewport!', {
      className: 'viewport-success',
      icon: <HandWaving color="white" weight="bold" size={20} />,
      autoClose: 6000,
    });
  }

  return (
    <Content>
      {isLoading ? (
        <LoaderWrapper>
          <MoonLoader color="#7F8087" size={30} />
        </LoaderWrapper>
      ) : (
        <>
          <Header>
            <p>How are you planning on using Viewport?</p>
            <small>
              No wrong answers here — we’re trying to better understand
              <br /> our early users’ needs to make the best product for you.
            </small>
          </Header>

          <Items>
            <Item onClick={() => setData('solo')} active={data === 'solo'}>
              <Checkbox
                checked={data === 'solo'}
                onChange={() => setData('solo')}
                name="solo"
              />

              <ItemInfo>
                <p>Solo</p>
                <small>
                  To organize and track my own projects and progress
                </small>
              </ItemInfo>
            </Item>

            <Item onClick={() => setData('team')} active={data === 'team'}>
              <Checkbox
                checked={data === 'team'}
                onChange={() => setData('team')}
                name="team"
              />

              <ItemInfo>
                <p>Team</p>
                <small>
                  To organize team projects and get feedback from others
                </small>
              </ItemInfo>
            </Item>

            <Item
              onClick={() => setData('not-sure')}
              active={data === 'not-sure'}
            >
              <Checkbox
                checked={data === 'not-sure'}
                onChange={() => setData('not-sure')}
                name="not-sure"
              />

              <ItemInfo>
                <p>Not sure yet!</p>
              </ItemInfo>
            </Item>

            <Actions>
              <Button
                type="button"
                height="48px"
                width="121px"
                marginTop="0"
                borderRadius="12px"
                disabled={!data}
                onClick={performSubmit}
              >
                Continue
              </Button>
            </Actions>
          </Items>
        </>
      )}
    </Content>
  );
};

export default UsageForm;
