import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineLink as LinkIcon } from 'react-icons/ai';
import { BiCopy as CopyIcon } from 'react-icons/bi';
import CloseIcon from '@material-ui/icons/Close';
import { LinkSimple, Trash } from '@phosphor-icons/react';

import { useApp } from '../../../../hooks/useMain';
import ROUTES from '../../../../routes';
import BoardClient from '../../../../clients/board.client';
import {
  ModalContainer,
  Dropdown,
  Spinner,
  Title,
  Button,
  Links,
  Link,
} from './ShareModal.styles';
import { Board, BoardShare } from '../../../../typings';
import { Row } from '../../../../components/Row/Row';
import ClipboardService from '../../../../services/clipboard.service';
import {
  ButtonLink,
  ButtonTransparent,
} from '../../../../components/Button/Button';
import Spacer from '../../../../components/Spacer/Spacer';
import DateUtils from '../../../../utils/date.utils';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import ButtonSpinner from '../../../../components/ButtonSpinner/ButtonSpinner';

enum ButtonTexts {
  Create = 'Create link',
  Copy = 'Copy link',
  Copied = 'Copied!',
}

const THREE_SECONDS = 3000;

const LINK_EXPIRATION_TIMES = [
  {
    id: null,
    name: 'Link doesn’t expire',
  },
  {
    id: 1,
    name: '24 hours',
  },
  {
    id: 2,
    name: '48 hours',
  },
  {
    id: 7,
    name: '1 week',
  },
];

interface ShareProjectModalProps {
  data: Board;
  open: boolean;
  onClose: () => void;
  onGenerateLink: (data: BoardShare) => void;
  onDeleteLink: (ids: string[]) => void;
}

interface Duration {
  id: number | null;
  name: string;
}

const ShareProjectModal: React.FC<ShareProjectModalProps> = ({
  data,
  open,
  onClose,
  onGenerateLink,
  onDeleteLink,
}: ShareProjectModalProps) => {
  const { setErrorMessage } = useApp();
  const [buttonText, setButtonText] = useState(ButtonTexts.Create);
  const [duration, setDuration] = useState<Duration>();
  const [generating, setGenerating] = useState(false);
  const [deleting, setDeleting] = useState<string[]>([]);
  const [link, setLink] = useState('');

  const generateUrl = useCallback((token?: string): string => {
    if (!token) return '';

    const { origin } = window?.location;
    const url = `${origin}${ROUTES.PROJECT_VIEW(token)}`;

    return url;
  }, []);

  function reset() {
    setLink('');
    setButtonText(ButtonTexts.Create);
  }

  function handleDurationChange(name: string) {
    reset();

    const expirationData = LINK_EXPIRATION_TIMES.find(
      (item) => item.name === name
    );

    if (expirationData) {
      setDuration(expirationData);
    }
  }

  function copyLinkToClipboard() {
    const url = generateUrl(link);

    try {
      ClipboardService.writeText(url);
    } catch (error) {
      setErrorMessage('Failed to copy the link to the clipboard');
    }

    setButtonText(ButtonTexts.Copied);
    setTimeout(() => {
      setButtonText(ButtonTexts.Copy);
    }, THREE_SECONDS);
  }

  async function performLinkGeneration() {
    try {
      setGenerating(true);

      const result = await new BoardClient().getExpirationLink(
        data.id!,
        duration?.id
      );
      const url = generateUrl(result);

      setLink(result);
      setButtonText(ButtonTexts.Copy);

      ClipboardService.writeText(url);

      const now = new Date();
      const expiresAt = duration?.id
        ? new DateUtils(now).addDays(duration?.id)
        : null;
      onGenerateLink({
        id: 0,
        hash: result,
        expiresAt,
        createdAt: now,
      });
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while generating the link'
      );
    } finally {
      setGenerating(false);
    }
  }

  async function performLinkDeletion(hash?: string) {
    try {
      const hashs = hash ? [hash] : data.shares?.map((share) => share.hash);
      setDeleting(hashs);

      await new BoardClient().deleteExpirationLink(data.id!, hashs);

      onDeleteLink(hashs);
      reset();
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while generating the link'
      );
    } finally {
      setDeleting([]);
    }
  }

  return (
    <ModalContainer
      show={open}
      size="md"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <p>Share {data.name}</p>
        <CloseIcon onClick={onClose} />
      </Modal.Header>

      <Modal.Body>
        <Title>Create a private link</Title>

        <Spacer height={12} display="block" />

        <Row gap="12px">
          <Dropdown
            label="Link doesn’t expire"
            items={LINK_EXPIRATION_TIMES}
            value={duration ? duration.name : ''}
            width={238}
            onChange={(value: string) => handleDurationChange(value)}
          />

          <Button
            type="button"
            width="150px"
            height="48px"
            marginTop="0px"
            onClick={link ? copyLinkToClipboard : performLinkGeneration}
            background={link ? '#000000' : '#4D4DFF'}
          >
            {generating ? (
              <Spinner animation="border" role="status" />
            ) : (
              <>
                {link ? <CopyIcon size="24px" /> : <LinkIcon size="24px" />}{' '}
                {buttonText}
              </>
            )}
          </Button>
        </Row>

        {Boolean(data.shares.length) && (
          <>
            <hr />

            <Row justify="space-between">
              <Title>Shared via</Title>
              <ButtonLink
                type="button"
                color="#7F8087"
                fontSize="14"
                fontWeight="600"
                withUnderline={false}
                onClick={() => performLinkDeletion()}
              >
                Remove all
              </ButtonLink>
            </Row>

            <Links>
              {data.shares?.map((share) => (
                <Link key={share.id}>
                  <div>
                    <Tooltip
                      title="Copy link"
                      id={`${String(share.hash)}-copy-link`}
                      placement="top"
                    >
                      <Button
                        type="button"
                        background="#7F8087"
                        width="32px"
                        height="32px"
                        borderRadius="6px"
                        marginTop="0"
                        onClick={() =>
                          ClipboardService.writeText(generateUrl(share.hash))
                        }
                      >
                        <LinkSimple size={16} weight="bold" />
                      </Button>
                    </Tooltip>

                    <strong>
                      Private link {new DateUtils(share.createdAt).toView(true)}
                    </strong>
                    <p>
                      {share.expiresAt === null
                        ? 'No expiration'
                        : `Expires ${new DateUtils(share.expiresAt).fromNow()}`}
                    </p>
                  </div>

                  <Tooltip
                    title="Delete"
                    id={`${String(share.hash)}-delete-link`}
                    placement="top"
                  >
                    <ButtonTransparent
                      type="button"
                      marginTop="0"
                      onClick={() => performLinkDeletion(share.hash)}
                      height="32px"
                    >
                      {deleting.includes(share.hash) ? (
                        <ButtonSpinner size="16px" color="#7F8087" />
                      ) : (
                        <Trash size={16} />
                      )}
                    </ButtonTransparent>
                  </Tooltip>
                </Link>
              ))}
            </Links>
          </>
        )}
      </Modal.Body>
    </ModalContainer>
  );
};

export default ShareProjectModal;
