import React from 'react';

const Logo = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" fill="white" />
    <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" stroke="#E3E6EC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0303 14C15.3567 14 14 15.3567 14 17.0303V25.9192C14 27.5928 15.3567 28.9495 17.0303 28.9495H19.0505V30.9697C19.0505 32.6433 20.4072 34 22.0808 34H30.9697C32.6433 34 34 32.6433 34 30.9697V22.0808C34 20.4072 32.6433 19.0505 30.9697 19.0505H28.9495V17.0303C28.9495 15.3567 27.5928 14 25.9192 14H17.0303ZM26.9293 19.0505V17.0303C26.9293 16.4724 26.4771 16.0202 25.9192 16.0202H17.0303C16.4724 16.0202 16.0202 16.4724 16.0202 17.0303V25.9192C16.0202 26.4771 16.4724 26.9293 17.0303 26.9293H19.0505V19.0505H26.9293ZM21.0707 26.9293V21.0707H26.9293V26.9293H21.0707ZM21.0707 28.9495H28.9495V21.0707H30.9697C31.5276 21.0707 31.9798 21.5229 31.9798 22.0808V30.9697C31.9798 31.5276 31.5276 31.9798 30.9697 31.9798H22.0808C21.5229 31.9798 21.0707 31.5276 21.0707 30.9697V28.9495Z"
      fill="url(#paint0_linear_706_2382)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_706_2382"
        x1="16.9167"
        y1="10.25"
        x2="30.25"
        y2="39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4D4DFF" />
        <stop offset="1" stopColor="#00CAF2" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
