import styled, { css } from 'styled-components/macro';
import { ButtonOutline } from '../Button/Button';

interface HeaderProps {
  height: string;
}

export const Header = styled.header<HeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 15px;
  transition: all 0.5s ease;
  position: relative;
  height: ${({ height }) => height || '88px'};

  &:after {
    content: '';
    width: 32px;
    height: 1px;
    background: ${({ theme }) => theme.gray};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Title = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.5s ease;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: ${({ theme }) => theme.gray2};
  border-radius: 12px;
  margin: 0 12px;
  padding: 12px 12px 0px 8px;
  transition: all 0.5s ease;
`;

interface ImageProps {
  selected: boolean;
  open: boolean;
  src: string;
}

export const Image = styled.div<ImageProps>`
  position: relative;
  min-width: 48px;
  min-height: 48px;
  border-radius: 12px;

  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;

  border: 1px solid ${({ theme }) => theme.gray};
  outline: 2px solid ${({ theme }) => theme.white};
  box-shadow: 0px 0px 0px 4px
    ${({ selected }) => (selected ? '#18ecbc' : 'transparent')};

  &:hover {
    box-shadow: 0px 0px 0px 4px #00caf2;
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  min-width: 48px;
  max-height: 48px;
  border-radius: 8px;

  &:after {
    content: '';
    min-width: 52px;
    min-height: 52px;
    position: absolute;
    border-radius: 14px;
    left: -2px;
    top: -2px;
    background: transparent;
    border: 2px solid ${({ theme }) => theme.white};
  }

  &:hover:after {
    box-shadow: 0px 0px 0px 2px #00caf2;
  }
`;

export const Name = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 155px;
  color: #000 !important;

  transform: translateX(16px);
  transition: transform 0.5s ease, color 0.2s ease;
`;

export const UpdatedAt = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.gray3};
  text-align: left;
  white-space: nowrap;

  transform: translateX(16px);
  transition: transform 0.5s ease, color 0.2s ease;
`;

export const Toggle = styled.button`
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.gray2};
  font-size: 13px;
  line-height: 13px;
  border: 0;
  border-radius: 4px;
  color: ${({ theme }) => theme.gray3};
  position: absolute;
  right: 16px;
`;

export const CreateButton = styled(ButtonOutline)`
  position: absolute;
  left: 12px;
  bottom: 20px;
  width: 248px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.gray};
  color: ${({ theme }) => theme.gray3};
  border-radius: 12px;

  svg {
    color: ${({ theme }) => theme.gray3};
    display: none;
    font-size: 26px;
  }

  outline: 2px solid ${({ theme }) => theme.white} !important;

  &:hover {
    box-shadow: 0px 0px 0px 4px #00caf2;
  }
`;

export const EmptyText = styled.p`
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  margin: 0 auto;
  bottom: 74px;
  width: 140px;
  transform: translateX(calc(140px / 2));
  transition: all 0.3s ease;
  text-align: center;
  color: ${({ theme }) => theme.gray1};
`;

interface ContentProps {
  disabled: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: calc(100% - 170px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        height: calc(100% - 120px);

        ${Item} {
          padding: 12px;
        }
      `;
    }
  }}
`;

interface GroupProps {
  open: boolean;
}

export const Container = styled.aside<GroupProps>`
  width: ${({ open }) => (open ? '275px' : '88px')};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid ${({ theme }) => theme.gray};
  background: ${({ theme }) => theme.white};
  transition: all 0.5s ease;
  z-index: 3;

  ${({ open }) => {
    if (!open) {
      return css`
        ${Name} {
          opacity: 0;
          transform: translateX(0);
          color: transparent;
        }

        ${UpdatedAt} {
          opacity: 0;
          transform: translateX(0);
          color: transparent;
        }

        ${Title} {
          opacity: 0;
          color: transparent;
          color: ${({ theme }) => theme.white};
        }

        ${Item} {
          background: transparent;
        }

        ${Toggle} {
          right: calc((88px - 24px) / 2);
        }

        ${CreateButton} {
          left: 20px;
          border: 1px solid #e3e6ec;
          width: 48px;

          svg {
            display: initial;
          }

          span {
            display: none;
          }
        }

        ${EmptyText} {
          /* left: 10px; */
          transform: translateX(10px);
          width: 70px;
        }

        ${Content} {
          row-gap: 0;
        }
      `;
    }
  }}
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span:first-child {
    transform: rotate(-0.25turn);
  }
`;
