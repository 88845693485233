import React, { useState } from 'react';
import { RiPlayCircleFill as PlayCircle } from 'react-icons/ri';

import { Container, Placeholder, Player } from './GifPlayer.styles';

interface GiftPlayerProps {
  src: string;
  width?: string;
  height?: string;
}

const GifPlayer = ({ src, width, height }: GiftPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Container>
      <Player src={src} width={width} height={height} />
      {/* {!isPlaying && (
        <PlayCircle
          color="#4D4DFF"
          size={64}
          onClick={() => setIsPlaying(true)}
        />
      )}

      {isPlaying ? <Player src={src} /> : <Placeholder src={placeholder} />} */}
    </Container>
  );
};

export default GifPlayer;
