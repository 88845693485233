import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 430px;
  }

  .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 40px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }

  .modal-header,
  .modal-footer {
    border: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-footer {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.gray3};
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 48px 230px 10px;
  align-items: center;
  padding: 24px;
  gap: 16px;
  cursor: pointer;

  width: 390px;
  height: 96px;

  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.gray};
  box-shadow: 0px 1px 0px #e7e9ef;
  border-radius: 16px;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: ${({ theme }) => theme.black} !important;
  }

  small {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: ${({ theme }) => theme.gray3};
  }
`;
