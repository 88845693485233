import styled, { css } from 'styled-components';

// TODO: remove all marginTop="0" and fix all
// TODO: change the fontSize to receive the entire size, from 12 to 12px
interface ButtonProps {
  width?: string;
  height?: string;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: string;
  marginRight?: string;
  marginLeft?: string;
  color?: string;
  borderColor?: string;
  borderSize?: string;
  borderRadius?: string;
  background?: string;
  backgroundOverlay?: string;
  marginTop?: string;
  marginBottom?: string;
  alignSelf?: string;
  withUnderline?: boolean;
  hoverText?: string;
}

export const Button = styled.button<ButtonProps>`
  height: ${({ height }) => height || '42px'};
  width: ${({ width }) => width || '100px'};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  background: ${({ background, theme }) => background || theme.blue};
  color: ${({ color, theme }) => color || theme.white};
  margin-top: ${({ marginTop }) => marginTop || '12px'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  border-radius: ${({ borderRadius }) => borderRadius || '12px'};
  align-self: ${({ alignSelf: alinSelf }) => alinSelf || 'flex-end'};
  line-height: ${({ height }) => (height ? `calc(${height} - 4px)` : '100%')};
  border: ${({ borderSize }) => borderSize || '0px'} solid
    ${({ theme, borderColor }) => borderColor || theme.gray};
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : '600')};

  &:hover {
    background: ${({ backgroundOverlay }) => backgroundOverlay || '#000000'};
  }

  &:disabled {
    background: #c8c9ce;

    &:hover {
      background: #b4b5ba;
    }
  }
`;

export const ButtonOutline = styled.button<ButtonProps>`
  margin-top: ${({ marginTop }) => marginTop || '12px'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  border: ${({ borderSize }) => borderSize || '1px'} solid
    ${({ theme, borderColor }) => borderColor || theme.gray};
  color: ${({ theme, color }) => color || theme.gray3};
  height: ${({ height }) => height || '42px'};
  width: ${({ width }) => width || 'auto'};
  line-height: ${({ lineHeight, height }) =>
    lineHeight || (height ? `calc(${String(height)} - 4px)` : '100%')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : '600')};
  align-self: ${({ alignSelf: alinSelf }) => alinSelf || 'flex-end'};
  border-radius: ${({ borderRadius }) => borderRadius || '12px'};
  background: transparent;
  position: relative;

  &:disabled {
    opacity: 50%;
  }

  ${({ hoverText }) =>
    hoverText &&
    css`
      &:hover {
        background: ${({ theme }) => theme.gray2};

        &:after {
          content: '${hoverText}';
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          background: ${({ theme }) => theme.gray2};
          border-radius: ${({ borderRadius }) => borderRadius || '12px'};
          width: 100%;
          line-height: 120%;
        }
      }
    `}
`;

export const ButtonTransparent = styled.button<ButtonProps>`
  margin-top: ${({ marginTop }) => marginTop || '12px'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  border: none;
  color: ${({ theme, color }) => color || theme.gray3};
  height: ${({ height }) => height || '42px'};
  width: ${({ width }) => width || 'auto'};
  line-height: ${({ height }) => height || '100%'};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  border-radius: 8px;
  background: transparent;
  font-weight: 400;
  align-self: flex-end;

  &:disabled {
    opacity: 50%;
  }

  &:hover {
    background: ${({ theme, background }) => background || theme.gray2};
  }
`;

export const ButtonLink = styled.button<ButtonProps>`
  background: transparent;
  border: 0;
  width: ${({ width }) => width || 'auto'};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '18px')};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  color: ${({ color }) => color || '#a9a9ac'};
  text-decoration-line: ${({ withUnderline = true }) =>
    withUnderline ? 'underline' : 'inherit'};
  align-self: ${({ alignSelf: alinSelf }) => alinSelf || 'initial'};
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : '400')};
  padding: 0;

  &:hover {
    opacity: 80%;
  }
`;

export const ButtonFancy = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  border: 0;

  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || '48px'};
  color: ${({ color }) => color || 'white'};

  font-weight: 600;
  font-size: 14px;

  background: linear-gradient(167.27deg, #00caf2 -2.25%, #4d4dff 110.79%);
  box-shadow: 0px 7px 10px -4px rgba(77, 77, 255, 0.4),
    inset 0px 0px 0px 1px rgba(77, 77, 255, 0.2),
    inset 0px 6px 4px -3px rgba(255, 255, 255, 0.4);
  border-radius: 12px;
`;
