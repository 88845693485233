class UrlUtils {
  value: any;

  constructor(date: any) {
    this.value = date;
  }

  getPathname(): string {
    const result = new URL(this.value);

    return `${result.pathname}${result.search}`;
  }
}

export default UrlUtils;
