import { Link } from 'react-router-dom';

import { useMemo, useState } from 'react';
import ROUTES from '../../routes';
import { Container, Content, CreateButton, JoinButton } from './Header.styles';
import NavigationOptions from './components/Navigation/Navigation';
import Notification from './components/Notification/Notification';
import AuthService from '../../services/auth.service';
import Search from './components/Search/Search';
import RequestAccessModal from '../RequestAccessModal/RequestAccessModal';
import Logo from '../Icon/icons/Logo';

interface HeaderProps {
  children?: React.ReactNode;
  links?: boolean;
  search?: boolean;
  notification?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  children,
  links = false,
  search = false,
  notification = false,
}: HeaderProps): JSX.Element => {
  const [modalRequestAccessState, toggleModalRequestAccess] = useState(false);
  const isAuthenticated = useMemo(
    () => new AuthService().isAuthenticated(),
    []
  );

  return (
    <Container>
      <a href={ROUTES.DASHBOARD}>
        <Logo />
      </a>

      <Content marginRight={isAuthenticated}>
        {Boolean(children) && children}

        {isAuthenticated && search && <Search />}

        {isAuthenticated && notification && <Notification />}

        {isAuthenticated && links && (
          <Link to={ROUTES.POST_TYPE}>
            <CreateButton type="button">Share work</CreateButton>
          </Link>
        )}
      </Content>

      {isAuthenticated && <NavigationOptions />}

      {!isAuthenticated && (
        <JoinButton
          type="button"
          onClick={() => toggleModalRequestAccess(true)}
        >
          Join Viewport
        </JoinButton>
      )}

      {!isAuthenticated && (
        <RequestAccessModal
          open={modalRequestAccessState}
          onClose={() => toggleModalRequestAccess(false)}
        />
      )}
    </Container>
  );
};

export default Header;
