import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  row-gap: 24px;
`;

export const Text = styled.h1`
  font-size: 18px;
  font-weight: 400;
  margin: 0;
`;

export const HeaderContent = styled.div`
  width: 100%;
`;
