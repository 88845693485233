import React from 'react';
import styled from 'styled-components';
import AddImages from './icons/AddImages';
import AddOneImage from './icons/AddOneImage';
import Pencil from './icons/Pencil';
import LeftArrow from './icons/LeftArrow';
import RightArrow from './icons/RightArrow';
import SmileySad from './icons/SmileySad';
import AddProject from './icons/AddProject';
import SadFace from './icons/SadFace';
import Lock from './icons/Lock';
import Chat from './icons/Chat';
import PencilDrawing from './icons/PencilDrawing';
import Invite from './icons/Invite';
import Logout from './icons/Logout';

export const IconType = {
  addOneImage: 'add-one-image',
  addImages: 'add-images',
  pencil: 'pencil',
  leftArrow: 'left-arrow',
  rightArrow: 'right-arrow',
  smileySad: 'smiley-sad',
  addProject: 'add-project',
  sadFace: 'sad-face',
  lock: 'lock',
  chat: 'chat',
  pencilDrawing: 'pencil-drawing',
  invite: 'invite',
  logout: 'logout',
};

interface ContainerProps {
  left: number;
  right: number;
  bottom: number;
  top: number;
  position?: string;
  color?: string;
}

const Container = styled.i<ContainerProps>`
  margin-right: ${({ right }) => right}px;
  margin-left: ${({ left }) => left}px;
  margin-bottom: ${({ bottom }) => bottom}px;
  margin-top: ${({ top }) => top}px;
  display: inline-block;
  position: ${({ position }) => position || 'relative'};
  color: ${({ color }) => color || 'initial'};
`;

const Icon = ({
  name,
  left = 0,
  right = 0,
  bottom = 0,
  top = 0,
  color,
  className,
  onClick,
  position,
  ...props
}: any) => (
  <Container
    left={left}
    right={right}
    bottom={bottom}
    top={top}
    className={className}
    onClick={onClick}
    position={position}
    color={color}
  >
    {
      {
        [IconType.addOneImage]: <AddOneImage {...props} />,
        [IconType.addImages]: <AddImages {...props} />,
        [IconType.pencil]: <Pencil {...props} />,
        [IconType.leftArrow]: <LeftArrow {...props} />,
        [IconType.rightArrow]: <RightArrow {...props} />,
        [IconType.smileySad]: <SmileySad {...props} />,
        [IconType.addProject]: <AddProject {...props} />,
        [IconType.sadFace]: <SadFace {...props} />,
        [IconType.lock]: <Lock {...props} />,
        [IconType.chat]: <Chat {...props} />,
        [IconType.pencilDrawing]: <PencilDrawing {...props} />,
        [IconType.invite]: <Invite {...props} />,
        [IconType.logout]: <Logout {...props} />,
      }[String(name).toLowerCase()]
    }
  </Container>
);

export default Icon;
