import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Container } from './Items.styles';
import Item from '../Item/Item';
import { BoardItem } from '../../../../../../typings';

interface ItemsProps {
  items: BoardItem[];
  disabled: boolean;
  onRemove: (item: BoardItem) => void;
  onChange: (item: BoardItem) => void;
  onImageClick: (item: BoardItem) => void;
  reference: any;
}

const Items = SortableContainer<ItemsProps>(
  ({
    items,
    disabled,
    reference,
    onRemove,
    onChange,
    onImageClick,
  }: ItemsProps) => {
    function getKey(item: BoardItem): string {
      if (item.value instanceof File) {
        return item.value.name;
      }

      if (item.createdAt instanceof Date) {
        return item.createdAt.toISOString();
      }

      return `${String(item.createdAt)}-${String(item.id)}`;
    }

    return (
      <Container ref={reference}>
        {items.map((item: BoardItem, index: number) => (
          <Item
            key={getKey(item)}
            index={index}
            item={item}
            disabled={disabled}
            editable={!disabled}
            onRemove={onRemove}
            onChange={onChange}
            onImageClick={onImageClick}
          />
        ))}
      </Container>
    );
  }
);

export default Items;
