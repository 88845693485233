import styled, { createGlobalStyle } from 'styled-components/macro';
import { FormControl as MaterialFormControl } from '@material-ui/core';

interface ContainerProps {
  width?: number;
  height?: number;
  hideChevron?: boolean;
}

export const Container = styled(MaterialFormControl)<ContainerProps>`
  min-width: ${({ width }) => (width ? `${width}px` : '100%')} !important;
  text-align: left;
  background: #f1f3f5;
  height: ${({ height }) => (height ? `${height}px` : '40px')};
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 7px;

  .MuiInput-root {
    border-bottom: 0 !important;
    width: ${({ width }) => (width ? `${width}px` : '100%')} !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
  }

  .MuiInput-underline:before {
    border-bottom: 0 !important;
  }

  .MuiInput-underline:after {
    border-bottom: 0 !important;
  }

  .MuiSelect-select:focus {
    background-color: transparent !important;
  }

  .MuiSelect-selectMenu {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: ${({ theme }: any) => theme.black} !important;

    .help-text {
      display: none;
    }
  }

  .MuiInput-input {
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px;
  }

  .MuiSelect-icon {
    display: ${({ hideChevron }) =>
      hideChevron ? 'none' : 'initial'} !important;
  }

  svg {
    font-size: 30px;
    margin-top: -3px;
    margin-right: 10px;
  }
`;

interface GlobalStyleProps {
  optionsAtBottom?: boolean;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  .MuiListItem-root {
    column-gap: 12px !important;
    border-radius: 4px !important;
    margin: 0 8px !important;
  }

  /* .MuiPopover-root {
    z-index: 1000 !important;
  } */

  .MuiListItem-placeholder {
    display: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 8px !important;
    margin-top: ${({ optionsAtBottom }) => (optionsAtBottom ? '8px' : '0')};
  }

  .MuiListItem-button:hover {
    background-color: #F1F3F5 !important;
  }
`;
