import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

interface MessageProps {
  length: number;
}

export const Message = styled.textarea<MessageProps>`
  height: ${({ length }) => `calc(((${length}px / 75) * 15) + 37px)`};
  width: 100%;
  max-height: 88px;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 36px 8px 12px;
  resize: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.gray2};
  border: none;

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.gray3};
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }
`;

export const SubmitButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.blue};
  border: none;
  color: ${({ theme }) => theme.white};
  margin-left: 12px;

  svg {
    margin-top: -3px;
    margin-left: -1px;
  }

  &:disabled {
    background: #c8c9ce;

    &:hover {
      background: #b4b5ba;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.gray};
  background: ${({ theme }) => theme.gray2};
  display: flex;
  flex-direction: column;
`;

export const BottomInfo = styled.div`
  padding: 4px 4px 4px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.gray};

  p {
    color: ${({ theme }) => theme.gray3};
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%; /* 12px */
    letter-spacing: -0.24px;
  }
`;
