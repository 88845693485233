import styled, { css } from 'styled-components/macro';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

export const AddIcon = styled(AddToPhotosOutlinedIcon)`
  color: ${({ theme }) => theme.white};
`;

export const Picture = styled.img`
  border-radius: 15px;
  background: ${({ theme }: any) => theme.gray1};
  z-index: 1;
  object-fit: cover;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }: any) => theme.white};
  text-align: center;
`;
interface ActionsProps {
  fontSize?: string;
}

export const Actions = styled.div<ActionsProps>`
  position: absolute;
  z-index: 2;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;

  button {
    color: ${({ theme }) => theme.white};
    font-size: ${({ fontSize }) => fontSize || '14px'};
  }
`;

export const PictureWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover:after {
    opacity: 1;
  }

  &:hover ${Actions} {
    opacity: 1;
  }
`;

interface UploaderProps {
  backgroundFancy: boolean;
  borderRadius?: string;
  hoverEffect?: boolean;
}

export const Uploader = styled.div<UploaderProps>`
  background: ${({ theme, backgroundFancy }) =>
    backgroundFancy
      ? 'linear-gradient(180deg, #4D4DFF 0%, #00CAF2 100%);'
      : theme.gray1};
  border-radius: ${({ borderRadius }) => borderRadius || '15px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
  cursor: pointer;
  padding: 14px 10px;

  ${({ hoverEffect }) =>
    hoverEffect &&
    css`
      border: 1px solid ${({ theme }) => theme.gray};
      outline: 2px solid ${({ theme }) => theme.white};
      box-shadow: 0px 0px 0px 4px transparent;

      &:hover {
        box-shadow: 0px 0px 0px 4px #00caf2;
      }
    `}
`;

interface ContainerProps {
  size?: string;
}

export const Container = styled.div<ContainerProps>`
  ${Uploader} {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }

  ${Picture} {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }

  ${Actions} {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }

  ${Description} {
    width: ${({ size }) => size || '120px'};
  }

  ${PictureWrapper}:after {
    width: ${({ size }) => size || '120px'};
    height: ${({ size }) => size || '120px'};
  }
`;
