import ReactContentLoader from 'react-content-loader';
import { Container, GlobalStyle } from './ContentLoader.styles';
import { BoardColumn } from '../../../../typings';

interface ContainerProps {
  columns?: BoardColumn[];
}

const ContentLoader = ({ columns }: ContainerProps) => (
  <Container>
    <GlobalStyle />
    <ReactContentLoader viewBox="0 0 1112 748">
      <rect y="88" width="260" height="48" rx="12" fill="#F1F3F5" />
      <rect
        x="0.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        fill="#F1F3F5"
      />
      <rect
        x="0.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        stroke="#F1F3F5"
      />
      <rect x="284" y="88" width="260" height="48" rx="12" fill="#F1F3F5" />
      <rect
        x="284.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        fill="#F1F3F5"
      />
      <rect
        x="284.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        stroke="#F1F3F5"
      />
      <rect x="568" y="88" width="260" height="48" rx="12" fill="#F1F3F5" />
      <rect
        x="568.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        fill="#F1F3F5"
      />
      <rect
        x="568.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        stroke="#F1F3F5"
      />
      <rect x="852" y="88" width="260" height="48" rx="12" fill="#F1F3F5" />
      <rect
        x="852.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        fill="#F1F3F5"
      />
      <rect
        x="852.5"
        y="152.5"
        width="259"
        height="595"
        rx="11.5"
        stroke="#F1F3F5"
      />
      <rect width="48" height="48" rx="12" fill="#F1F3F5" />
      <rect x="974" width="138" height="48" rx="12" fill="#F1F3F5" />
    </ReactContentLoader>
  </Container>
);

export default ContentLoader;
