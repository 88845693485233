import styled, { css, createGlobalStyle } from 'styled-components/macro';

export const Button = styled.button`
  position: relative;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }: any) => theme.white};
  background: transparent;
  z-index: 2;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

interface ContainerProps {
  open: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  /* transition: all 0.3s ease; */
  border-radius: 8px;
  height: 40px;
  width: 40px;
  border: 1px solid
    ${({ open }) => (open ? 'rgba(255, 255, 255, 0.7)' : 'transparent')};

  .MuiFormControl-root {
    z-index: 1;
    background: transparent !important;
    height: 40px !important;
  }

  .MuiInputBase-root {
    width: 355px;
    margin-left: -22px;
  }

  .MuiAutocomplete-clearIndicatorDirty {
    svg {
      color: ${({ theme }: any) => theme.white} !important;
    }
  }

  .MuiCircularProgress-svg {
    color: ${({ theme }: any) => theme.white} !important;
  }

  #header-user-search {
    color: ${({ theme }: any) => theme.white} !important;
  }

  ${({ open }) => {
    if (open) {
      return css`
        width: 376px;

        ${Button} {
          background: transparent !important;
        }
      `;
    }
  }}
`;

export const GlobalStyle = createGlobalStyle`
  .MuiAutocomplete-popper {
    margin-left: -17px;
    width: 376px !important;
  }
`;
