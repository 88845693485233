import { useMemo, useState } from 'react';
import arrayMove from 'array-move';
import { useDropzone } from 'react-dropzone';
import MoonLoader from 'react-spinners/MoonLoader';
import { DownloadSimple, ArrowLeft, Plus } from '@phosphor-icons/react';
import { FaPlay as Play } from 'react-icons/fa6';
import Pluralize from 'react-pluralize';

import {
  Container,
  Details,
  Footer,
  EmptyState,
  ButtonAddMore,
  Ratios,
  RatioCard,
  ButtonUpload,
  DimensionsWrapper,
} from './GifCreator.styles';
import {
  Content,
  LoaderWrapper,
} from '../../Dashboard/components/CreateButton/components/GifRecapModal/GifRecapModal.styles';

import gifshot from '../../../services/gif.service';
import GifPlayer from '../../Dashboard/components/CreateButton/components/GifPlayer/GifPlayer';
import { Sortable } from '../../Dashboard/components/CreateButton/components/GifRecapModal/GifRecapModal';
import DropdownSelect from '../../../components/DropdownSelect/DropdownSelect';
import { Button } from '../../../components/Button/Button';
import { BoardItem } from '../../../typings';
import { useApp } from '../../../hooks/useMain';
import RatioSquare from '../../../components/Icon/icons/RatioSquare';
import RatioLandscape from '../../../components/Icon/icons/RatioLandscape';
import RatioPortrait from '../../../components/Icon/icons/RatioPortrait';

const IMAGES_ACCEPTED = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

export const FPS_OPTIONS = [
  {
    name: '0.25 FPS',
  },
  {
    name: '0.5 FPS',
  },
  {
    name: '1 FPS',
  },
  {
    name: '1.5 FPS',
  },
  {
    name: '2 FPS',
  },
];

export const MAX_IMAGES = 10;

const GifCreator = () => {
  const { setErrorMessage, setSuccessMessage } = useApp();
  const [fps, setFps] = useState<string>('1 FPS');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<BoardItem[]>([]);
  const [generatedGit, setGeneratedGif] = useState<string>('');
  const [step, setStep] = useState<'ordering' | 'dimension' | 'preview'>(
    'ordering'
  );
  const [ratio, setRatio] = useState<'square' | 'portrait' | 'landscape'>(
    'landscape'
  );

  const dimensionOutput = useMemo(() => {
    if (ratio === 'square') {
      return {
        width: 1200,
        height: 1200,
      };
    }

    if (ratio === 'landscape') {
      return {
        width: 1200,
        height: 675,
      };
    }

    return {
      width: 675,
      height: 1200,
    };
  }, [ratio]);

  const dimensionPreview = useMemo(() => {
    if (ratio === 'square') {
      return {
        width: 400,
        height: 400,
      };
    }

    if (ratio === 'landscape') {
      return {
        width: 536,
        height: 350,
      };
    }

    return {
      width: 250,
      height: 400,
    };
  }, [ratio]);

  function handleGoBack() {
    if (step === 'dimension') {
      setStep('ordering');
    }

    if (step === 'preview') {
      setStep('dimension');
    }
  }

  function handleNextStep() {
    if (step === 'ordering') {
      setStep('dimension');
    }

    if (step === 'dimension') {
      setStep('preview');
    }
  }

  function handleDelete(value: string) {
    const newData = data.filter((item) => item.value !== value);
    setData(newData);
  }

  function generateGif() {
    setIsLoading(true);

    const options = {
      images: data.map((item) => item.value as string),
      gifWidth: dimensionOutput.width,
      gifHeight: dimensionOutput.height,
      interval: 0.1,
      numFrames: 25,
      sampleInterval: 10,
      frameDuration: Number(fps.split(' ')[0]) * 10,
      numWorkers: 2,
      progressCallback: (e: any) => console.log(e),
    };

    gifshot.createGIF(options, (obj: any) => {
      if (!obj.error) {
        setIsLoading(false);
        setStep('preview');
        setGeneratedGif(obj.image);
      } else {
        setIsLoading(false);
        console.log(obj.error);
      }
    });
  }

  function onSortEnd({ oldIndex, newIndex }: any) {
    const filesReordered = arrayMove(data, oldIndex, newIndex);
    setData(filesReordered);
  }

  function downloadGif() {
    const link = document.createElement('a');
    link.href = generatedGit;
    link.download = 'viewport-recap.gif';
    link.click();

    setSuccessMessage('GIF Saved!');
  }

  function onDrop(newFiles: File[], fileRejections: any[]) {
    if (fileRejections?.[0]?.errors?.[0]?.code === 'file-too-large') {
      setErrorMessage('Max file size is 5mb');
      return;
    }

    if (
      fileRejections?.[0]?.errors?.[0]?.code === 'too-many-files' ||
      data.length + newFiles.length > MAX_IMAGES
    ) {
      setErrorMessage(`Maximun files allowed are ${MAX_IMAGES}`);
      return;
    }

    if (newFiles?.length) {
      const filesConverted = newFiles.map((file) => ({
        value: URL.createObjectURL(file),
        createdAt: new Date(),
      }));

      setData([...data, ...filesConverted]);
    }
  }

  const {
    getRootProps,
    getInputProps,
    open: openUploader,
  } = useDropzone({
    onDrop,
    maxFiles: MAX_IMAGES,
    maxSize: 5000000,
    accept: IMAGES_ACCEPTED.toString(),
    noDragEventsBubbling: true,
    noClick: true,
  });

  return (
    <Container>
      {step === 'ordering' && (
        <Content {...getRootProps()} width="536px" height="350px">
          <input {...getInputProps()} />

          {isLoading && (
            <LoaderWrapper>
              <MoonLoader color="#7F8087" loading />
            </LoaderWrapper>
          )}

          {!isLoading && (
            <>
              {Boolean(data.length) && (
                <Sortable
                  items={data}
                  allowSelection={false}
                  onSortEnd={onSortEnd}
                  onRemove={handleDelete}
                  axis="xy"
                  useDragHandle
                  helperClass="sortable-helper"
                  lockToContainerEdges
                  updateBeforeSortStart={(node: any) => {
                    node.node.style.zIndex = 9999;
                  }}
                >
                  <ButtonAddMore type="button" onClick={openUploader}>
                    <Plus size={32} color="#7F8087" />
                  </ButtonAddMore>
                </Sortable>
              )}

              {!data.length && (
                <EmptyState>
                  <p>
                    Upload JPG or PNG images
                    <br /> to create a recap
                  </p>
                  <ButtonUpload
                    type="button"
                    width="148px"
                    height="48px"
                    onClick={openUploader}
                  >
                    Upload assets
                  </ButtonUpload>
                  <p>Max 5mb file size</p>
                </EmptyState>
              )}
            </>
          )}
        </Content>
      )}

      {step === 'dimension' && (
        <Content width="536px" height="350px">
          {isLoading && (
            <LoaderWrapper>
              <MoonLoader color="#7F8087" loading />
            </LoaderWrapper>
          )}

          {!isLoading && (
            <DimensionsWrapper>
              <h3>Select a ratio for your gif recap</h3>

              <Ratios>
                <RatioCard
                  selected={ratio === 'square'}
                  onClick={() => setRatio('square')}
                >
                  <RatioSquare />
                  <p>
                    Square
                    <br />
                    1:1
                  </p>
                </RatioCard>

                <RatioCard
                  selected={ratio === 'landscape'}
                  onClick={() => setRatio('landscape')}
                >
                  <RatioLandscape />
                  <p>
                    Landscape
                    <br />
                    16:9
                  </p>
                </RatioCard>

                <RatioCard
                  selected={ratio === 'portrait'}
                  onClick={() => setRatio('portrait')}
                >
                  <RatioPortrait />
                  <p>
                    Portrait
                    <br />
                    9:16
                  </p>
                </RatioCard>
              </Ratios>
            </DimensionsWrapper>
          )}
        </Content>
      )}

      {step === 'preview' && (
        <GifPlayer
          src={generatedGit}
          width={`${dimensionPreview.width}px`}
          height={`${dimensionPreview.height}px`}
        />
      )}

      <Footer>
        {(step === 'dimension' || step === 'preview') && (
          <Button
            type="button"
            width="102px"
            height="48px"
            marginTop="0"
            fontSize="16"
            background="#F1F3F5"
            backgroundOverlay="#F1F3F5"
            color="#7F8087"
            onClick={handleGoBack}
          >
            <ArrowLeft color="#7F8087" size={22} weight="bold" />
            Back
          </Button>
        )}

        {step === 'ordering' && (
          <>
            <DropdownSelect
              label="Project status"
              items={FPS_OPTIONS}
              value={String(fps)}
              width={144}
              height={48}
              hideChevronAfterSelect={false}
              optionsAtBottom
              onChange={(value: string) => setFps(value)}
            />

            <Details>
              <small>
                {data.length}/{MAX_IMAGES}{' '}
                <Pluralize
                  singular="upload"
                  count={data.length}
                  showCount={false}
                />
              </small>
            </Details>
          </>
        )}

        {step === 'ordering' && (
          <Button
            type="button"
            width="106px"
            height="48px"
            marginTop="0"
            fontSize="16"
            onClick={handleNextStep}
            disabled={isLoading || !data.length}
          >
            Continue
          </Button>
        )}

        {step === 'dimension' && (
          <Button
            type="button"
            width="128px"
            height="48px"
            marginTop="0"
            fontSize="16"
            onClick={generateGif}
            disabled={!ratio}
          >
            <Play color="#FFF" size={16} />
            Preview
          </Button>
        )}

        {step === 'preview' && (
          <Button
            type="button"
            width="144px"
            height="48px"
            marginTop="0"
            fontSize="16"
            fontWeight="600"
            onClick={downloadGif}
          >
            <DownloadSimple color="#FFF" size={22} />
            Download
          </Button>
        )}
      </Footer>
    </Container>
  );
};

export default GifCreator;
