import React from 'react';

import {
  Container,
  Content,
  Description,
  CreatorAvatar,
  Details,
  CreatorName,
  Metadata,
  Actions,
  CommentButton,
  Footer,
  Header,
  UpdatedAt,
} from './ImageItem.styles';

import Image from './components/Image/Image';
import useImageItemController from './hooks/useController/useController';
import Avatars from './components/Avatars/Avatars';
import { BoardItem } from '../../../../../../typings';
import { Column } from '../../../../../../components/Column/Column';
import CommentsPanel from './components/CommentsPanel/CommentsPanel';

interface ImageItemProps {
  id: string;
  data: BoardItem;
  onCommentsPanelToggle?: (open: boolean) => void;
  onShowDetails: (src: string) => void;
}

const ImageItem = ({
  id,
  data,
  onCommentsPanelToggle,
  onShowDetails,
}: ImageItemProps) => {
  const {
    state,
    setIsCommenting,
    setDrawing,
    removeDrawingFromComment,
    setCommentsPanelUseremail,
    performCommentAdd,
    performCommentDeletion,
    performCommentReply,
    handleAvatarSelection,
    commentsByUser,
  } = useImageItemController({ data, onCommentsPanelToggle });

  const {
    value,
    comments,
    currentDrawing,
    isCommenting,
    commentsPanelUseremail,
  } = state;

  return (
    <>
      <Container id={id}>
        <Content>
          <Column>
            <Image
              src={value as string}
              drawing={currentDrawing}
              feedbackMode={isCommenting}
              comments={commentsPanelUseremail ? commentsByUser : []}
              onSubmitComment={performCommentAdd}
              onAddComment={() => setIsCommenting(true)}
              onCancelComment={() => setIsCommenting(false)}
              onShowDetails={onShowDetails}
            />
          </Column>

          <Column gap="12px">
            <Avatars
              className="avatars-container"
              data={{
                containerId: id,
                comments,
                activeUserEmail: commentsPanelUseremail,
              }}
              onSelect={handleAvatarSelection}
            />
          </Column>
        </Content>
      </Container>

      <CommentsPanel
        data={{
          containerId: id,
          comments: commentsByUser,
        }}
        open={Boolean(commentsPanelUseremail)}
        currentDrawing={currentDrawing}
        onDelete={performCommentDeletion}
        onRemoveDrawing={removeDrawingFromComment}
        onSetDrawing={setDrawing}
        onClose={() => setCommentsPanelUseremail(null)}
        onReply={performCommentReply}
      />
    </>
  );
};

export default ImageItem;
