import React from 'react';

const RightArrow = () => (
  <svg
    width="718"
    height="30"
    viewBox="0 0 718 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter1_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M715 15C715 21.6274 709.627 27 703 27C696.373 27 691 21.6274 691 15C691 8.37258 696.373 3 703 3C709.627 3 715 8.37258 715 15ZM700.606 8.73304C700.02 9.31882 700.02 10.2686 700.606 10.8544L704.752 15L700.606 19.1456C700.02 19.7314 700.02 20.6811 700.606 21.2669C701.192 21.8527 702.142 21.8527 702.727 21.2669L707.818 16.1762C708.14 15.8539 708.285 15.4214 708.253 15C708.285 14.5786 708.14 14.1461 707.818 13.8238L702.727 8.73304C702.142 8.14725 701.192 8.14725 700.606 8.73304Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter1_d"
        x="688"
        y="0"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default RightArrow;
