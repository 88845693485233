/* eslint-disable class-methods-use-this */
import BaseClient from './base.client';
import AuthService from '../services/auth.service';
import {
  Board,
  BoardAudit,
  BoardItem,
  BoardSimplified,
  BoardStat,
} from '../typings';

class BoardClient extends BaseClient {
  token: string;

  constructor() {
    super();
    this.token = new AuthService().getToken();
  }

  public async create(payload: Board): Promise<Board> {
    return this.client.post('/boards', payload).then<Board>(({ data }) => data);
  }

  public async update(id: number, payload: Board): Promise<Board> {
    return this.client
      .put(`/boards/${id}`, payload)
      .then<Board>(({ data }) => data);
  }

  public async getById(id: string, invitedUserEmail?: string): Promise<Board> {
    return this.client
      .get(`/boards/${id}`, { params: { invitedUserEmail } })
      .then<Board>(({ data }) => data);
  }

  public async getAll(): Promise<Board[]> {
    return this.client.get(`/boards`).then<Board[]>(({ data }) => data);
  }

  public async getAllSimplified(): Promise<BoardSimplified[]> {
    return this.client
      .get(`/boards/simplified`)
      .then<BoardSimplified[]>(({ data }) => data);
  }

  public async getFavorites(): Promise<BoardSimplified[]> {
    return this.client
      .get(`/boards/favorites`)
      .then<BoardSimplified[]>(({ data }) => data);
  }

  public async getTimeline(): Promise<BoardAudit[]> {
    return this.client
      .get(`/boards/timeline`)
      .then<BoardAudit[]>(({ data }) => data);
  }

  public async patch(id: number, payload: Partial<Board>): Promise<unknown> {
    return this.client.patch(`/boards/${id}`, payload);
  }

  public async getStats(start: Date, end: Date): Promise<BoardStat[]> {
    return this.client
      .get(`/boards/stats`, {
        params: {
          start,
          end,
        },
      })
      .then<BoardStat[]>(({ data }) => data);
  }

  public async getLatestUploads(): Promise<BoardItem[]> {
    return this.client
      .get(`/boards/latest-uploads`)
      .then<BoardItem[]>(({ data }) => data);
  }

  getExpirationLink(id: number, value?: number | null): Promise<string> {
    return this.client
      .get(`/boards/${id}/expiration`, { params: { value } })
      .then<string>(({ data }) => data);
  }

  deleteExpirationLink(id: number, hashs: string[]): Promise<void> {
    return this.client.delete(`/boards/${id}/expiration`, {
      params: { hashs },
    });
  }

  public async delete(id: number): Promise<void> {
    return this.client.delete(`/boards/${id}`).then<void>(({ data }) => data);
  }

  public async follow(id: number): Promise<void> {
    return this.client
      .post(`/boards/${id}/follow`)
      .then<void>(({ data }) => data);
  }

  public async unfollow(id: number): Promise<void> {
    return this.client
      .post(`/boards/${id}/unfollow`)
      .then<void>(({ data }) => data);
  }

  public async getAllFollowing(): Promise<BoardSimplified[]> {
    return this.client
      .get(`/boards/following`)
      .then<BoardSimplified[]>(({ data }) => data);
  }
}

export default BoardClient;
