import styled from 'styled-components/macro';
import { Popover as BootstrapPopover } from 'react-bootstrap';

export const Button = styled.button`
  height: 48px;
  border: 1px solid #e3e6ec;
  border-radius: 12px;
  background: transparent;
  padding: 10px 20px 10px 20px;
  margin-left: 150px;

  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.01em;
`;

export const Popover = styled(BootstrapPopover)`
  border: 0;
  margin-top: 6px !important;
  background: transparent;

  .arrow {
    display: none;
  }
`;

export const Info = styled.div`
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: 20px;
  box-shadow: 0px 13px 12px -6px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 16px;

  width: 255px;
  height: 167px;

  background: ${({ theme }) => theme.white};

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray3};
  }
`;

export const Placeholder = styled.div`
  width: 255px;
  height: 48px;
`;
