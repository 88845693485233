import React from 'react';

const SadFace = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="2"
      width="44"
      height="44"
      rx="6"
      stroke="#C8C9CF"
      strokeWidth="4"
    />
    <path
      d="M15 21C16.6569 21 18 19.6569 18 18C18 16.3431 16.6569 15 15 15C13.3431 15 12 16.3431 12 18C12 19.6569 13.3431 21 15 21Z"
      fill="#C8C9CF"
    />
    <path
      d="M33 21C34.6569 21 36 19.6569 36 18C36 16.3431 34.6569 15 33 15C31.3431 15 30 16.3431 30 18C30 19.6569 31.3431 21 33 21Z"
      fill="#C8C9CF"
    />
    <path
      d="M34.4016 35.0001C33.3443 33.1786 31.8272 31.6667 30.002 30.6158C28.1768 29.5649 26.1077 29.0117 24.0016 29.0117C21.8955 29.0117 19.8263 29.5649 18.0011 30.6158C16.176 31.6667 14.6588 33.1786 13.6016 35.0001"
      stroke="#C8C9CF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SadFace;
