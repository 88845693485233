import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.gray2};
  gap: 40px;
`;

export const Content = styled.div`
  min-width: 456px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  gap: 40px;
  padding: 48px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 6px 10px -2px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: 24px;
  z-index: 2;
`;

export const Logo = styled.img`
  width: 48px;
  height: 48px;
`;

export const BottomText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.gray3};
`;
