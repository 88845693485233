import styled, { keyframes } from 'styled-components/macro';
import { Copy, Spinner } from '@phosphor-icons/react';

import { useMemo, useState } from 'react';
import { ButtonTransparent } from '../../../../../../../../components/Button/Button';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';
import ClipboardService from '../../../../../../../../services/clipboard.service';
import FileUtils from '../../../../../../../../utils/file.utils';

export const Button = styled(ButtonTransparent)`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 4px;
  right: 4px;
  color: ${({ theme }) => theme.white};
  transition: all 0.5s ease;
  cursor: pointer;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const SpinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  animation: ${SpinAnimation} 2s linear infinite;
`;

const THREE_SECONDS = 3000;

interface CopyButtonProps {
  image: string;
}

const CopyButton = ({ image }: CopyButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isImage = useMemo(() => new FileUtils(image).isImage(), [image]);

  async function performCopy() {
    try {
      setIsLoading(true);
      const response = await fetch(`${image}?x-amazon-cache=force`, {
        cache: 'force-cache',
      });
      const blob = await response.blob();
      await ClipboardService.writeImage(blob);

      setIsCopied(true);
    } catch (error) {
      console.error('Could not copy image to clipboard', error);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setIsCopied(false);
      }, THREE_SECONDS);
    }
  }

  if (!isImage) {
    return null;
  }

  return (
    <Tooltip
      title={isCopied ? 'Copied!' : 'Copy'}
      id="copy"
      placement="left"
      borderRadius="4px"
      spacing={2}
      height="32px"
    >
      <Button type="button" onClick={performCopy}>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner color="white" size="18px" weight="bold" />
          </SpinnerWrapper>
        ) : (
          <Copy color="white" size="14px" weight="bold" />
        )}
      </Button>
    </Tooltip>
  );
};

export default CopyButton;
