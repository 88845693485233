import styled from 'styled-components/macro';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 243px;
  min-height: 118px;
  border-radius: 12px;
  background: ${({ theme }: any) => theme.white};
  display: flex;
  flex-direction: column;
  opacity: ${({ show }) => (show ? '1' : '0')};
  border: 1px solid ${({ theme }) => theme.gray};
`;

export const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  height: 80px;
  border: 0;
  padding: 8px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.gray1};
    font-style: italic;
  }
`;

export const Line = styled.hr`
  height: 1px;
  border: 1px;
  margin: 0;
  background: ${({ theme }) => theme.gray};
`;

export const Actions = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px;

  svg {
    vertical-align: sub;
  }
`;

export const Drawing = styled.div`
  width: 145px;
  height: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.gray2};
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px 4px 4px;

  svg {
    font-size: 14px;
    cursor: pointer;
  }
`;
