import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { MdOutlineHelp as HelpIcon } from 'react-icons/md';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Container, GlobalStyle, Label } from './DropdownOptions.styles';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import StringUtils from '../../../../../../utils/string.utils';

interface DropdownOptionsProps {
  label?: string;
  value: string;
  placeholder?: string;
  items: {
    name: string;
    help?: string;
    icon?: React.ReactElement;
  }[];
  width?: number;
  className?: string;
  onChange: (value: string, e?: any) => void;
}

const DropdownOptions = ({
  label,
  value,
  placeholder,
  items,
  width,
  className,
  onChange,
}: DropdownOptionsProps): React.ReactElement => (
  <Container width={width} className={className} empty={!value}>
    <GlobalStyle />
    <Label>{label}</Label>

    <Select
      displayEmpty
      value={value}
      onChange={(e) => onChange(String(e.target.value), e)}
      IconComponent={ExpandMoreIcon}
    >
      {Boolean(placeholder) && (
        <MenuItem value="" className="MuiListItem-placeholder" disabled>
          {placeholder}
        </MenuItem>
      )}

      {items.map((item: any) => (
        <MenuItem key={item.name} value={item.name}>
          {Boolean(item.name) && new StringUtils(item.name).capitalize()}{' '}
          {Boolean(item.icon) && item.icon}
          {Boolean(item.help) && (
            <Tooltip title={item.help} id={item.name}>
              <HelpIcon color="#C8C9CF" className="help-text" />
            </Tooltip>
          )}
        </MenuItem>
      ))}
    </Select>
  </Container>
);

export default DropdownOptions;
