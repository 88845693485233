import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: auto;
  padding-bottom: 72px;
  padding-top: 24px;
`;

export const Row = styled.div`
  width: 900px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  column-gap: 24px;
  margin-left: -258px; // 210px + 48px (from gap)
`;

export const Details = styled.div`
  text-align: right;
  width: 210px;

  b {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: ${({ theme }) => theme.black};
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: ${({ theme }) => theme.gray3};
  }
`;

export const CreatedAt = styled.div`
  width: 600px;
  height: 48px;
  background: ${({ theme }) => theme.gray2};
  border-radius: 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MoreImagesAdded = styled.div`
  width: 300px;
  height: 165px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  font-weight: 400;
  font-size: 32px;
  color: ${({ theme }) => theme.white};
  grid-area: 2 / 2 / 3 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 12px;
  cursor: zoom-in;
`;

interface ImageGridProps {
  total: number;
}

export const ImageGrid = styled.div<ImageGridProps>`
  width: 600px;
  height: 334px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  border-radius: 12px;
  margin-bottom: 64px;
  border: 1px solid ${({ theme }) => theme.gray};

  img {
    ${({ total }) => {
      if (total === 1) {
        return css`
          &:nth-child(1) {
            border-radius: 12px;
            grid-area: 1 / 1 / 3 / 3;
          }
        `;
      }

      if (total === 2) {
        return css`
          &:nth-child(1) {
            grid-area: 1 / 1 / 3 / 2;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-right: 1px solid ${({ theme }) => theme.gray};
          }
          &:nth-child(2) {
            grid-area: 1 / 2 / 3 / 3;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        `;
      }

      if (total === 3) {
        return css`
          &:nth-child(1) {
            max-height: 332px;
            grid-area: 1 / 1 / 3 / 2;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-right: 1px solid ${({ theme }) => theme.gray};
          }
          &:nth-child(2) {
            max-height: 166px;
            grid-area: 1 / 2 / 2 / 3;
            border-top-right-radius: 12px;
            border-bottom: 1px solid ${({ theme }) => theme.gray};
          }
          &:nth-child(3) {
            max-height: 166px;
            grid-area: 2 / 2 / 3 / 3;
            border-bottom-right-radius: 12px;
          }
        `;
      }

      if (total >= 4) {
        return css`
          max-width: 300px;
          max-height: 166px;

          &:nth-child(1) {
            border-top-left-radius: 12px;
            grid-area: 1 / 1 / 2 / 2;
            border-right: 1px solid ${({ theme }) => theme.gray};
            border-bottom: 1px solid ${({ theme }) => theme.gray};
          }
          &:nth-child(2) {
            border-top-right-radius: 12px;
            grid-area: 1 / 2 / 2 / 3;
          }
          &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 2;
            border-bottom-left-radius: 12px;
          }
          &:nth-child(4) {
            grid-area: 2 / 2 / 3 / 3;
            border-bottom-right-radius: 12px;
            border-left: 1px solid ${({ theme }) => theme.gray};
            border-top: 1px solid ${({ theme }) => theme.gray};
          }
        `;
      }
    }}
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
`;

export const Title = styled.p`
  text-align: left;
  width: 100%;
  padding: 0 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.14px;
`;

export const ImageGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const Infos = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.gray3};

  a {
    color: ${({ theme }) => theme.gray3} !important;
    text-decoration: underline !important;
  }
`;
