import styled from 'styled-components/macro';
import { ButtonFancy } from '../../../components/Button/Button';

export const Container = styled.div`
  width: 600px;
  height: 494px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
`;

export const Footer = styled.footer`
  width: 536px;
  padding: 32px 0 0 0;
  display: flex;
  justify-content: space-between;

  button {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 8px;
  }

  .MuiFormControl-root {
    border-radius: 12px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 280px;

  small {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;

    color: ${({ theme }) => theme.gray3};
  }

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-decoration-line: underline !important;

    color: ${({ theme }) => theme.blue};
  }
`;

export const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  p {
    color: ${({ theme }) => theme.gray3};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
`;

export const ButtonAddMore = styled.button`
  width: 96px;
  height: 96px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.gray1};
  border-radius: 8px;
`;

export const Ratios = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

interface RadioCardProps {
  selected: boolean;
}

export const RatioCard = styled.div<RadioCardProps>`
  width: 124px;
  height: 124px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;

  background: ${({ selected, theme }) =>
    selected ? 'rgba(77, 77, 255, 0.1)' : theme.white};

  border-radius: 12px;
  border: ${({ theme, selected }) =>
    selected
      ? `3px solid ${String(theme.blue)}`
      : `1px solid ${String(theme.gray1)}`};

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
  }
`;

export const ButtonUpload = styled(ButtonFancy)`
  &:hover {
    background: linear-gradient(
      177.48deg,
      #00caf2 -48.91%,
      #4d4dff 57.63%,
      #271c44 138.5%
    );
    box-shadow: 0px 11px 16px -6px rgba(77, 77, 255, 0.6),
      inset 0px 0px 0px 1px rgba(77, 77, 255, 0.2),
      inset 0px 6px 3.8px -4px rgba(255, 255, 255, 0.5);
  }
`;

export const DimensionsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    margin: 0;
  }
`;
