import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';
import { CheckboxNew } from '../../../Checkbox/Checkbox';

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 500px;
  }

  .modal-content {
    padding: 24px 20px 20px 20px;
    border-radius: 24px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }

  .modal-header,
  .modal-footer {
    border: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-footer {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
`;

export const Title = styled(Modal.Title)`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`;

export const Content = styled.div`
  width: 448px;
  height: 350px;
  background: ${({ theme }) => theme.gray2};
  border-radius: 12px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  span:first-child {
    margin: auto auto;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }
`;

export const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  p {
    color: ${({ theme }) => theme.gray3};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 350px;
  justify-content: initial;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.gray};

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }
`;

interface ItemProps {
  checked: boolean;
  disabled: boolean;
}

export const Item = styled.div<ItemProps>`
  height: 72px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  padding: 12px 20px 12px 12px;
  border-radius: 12px;
  border: 1px solid
    ${({ checked, theme }) => (checked ? theme.blue : 'transparent')};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    border-color: ${({ theme, checked }) =>
      checked ? theme.blue : theme.gray1};
  }

  .MuiCheckbox-root {
    width: 50px;
    height: 50px;

    &:hover {
      background: transparent;
    }
  }

  .spinner-border {
    width: 22px;
    height: 22px;
    margin-left: 12px;
  }
`;

export const Image = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  object-fit: cover;
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 14px;
  width: 270px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Details = styled.p`
  color: #a7a7a7;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.14px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateButton = styled.button`
  padding: 16px 24px;
  border-radius: 12px;
  background: linear-gradient(167deg, #00caf2 -2.25%, #4d4dff 110.79%);
  box-shadow: 0px 6px 10px -5px rgba(77, 77, 255, 0.4);
  color: ${({ theme }) => theme.white};
  border: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
`;

export const Checkbox = styled(CheckboxNew)`
  border-color: ${({ checked, theme }) =>
    checked ? 'transparent' : theme.gray1};
`;
