/* eslint-disable class-methods-use-this */
import BaseClient from './base.client';
import AuthService from '../services/auth.service';
import { BoardComment } from '../typings';

class BoardItemClient extends BaseClient {
  token: string;

  constructor() {
    super();
    this.token = new AuthService().getToken();
  }

  public async addComments(
    id: number,
    comments: BoardComment[]
  ): Promise<BoardComment> {
    return this.client
      .post(`/board-items/${id}/comments`, { comments })
      .then<BoardComment>(({ data }) => data);
  }

  public async addCommentReply(
    id: number,
    commentId: number,
    message: string
  ): Promise<BoardComment> {
    return this.client
      .post(`/board-items/${id}/comments/${commentId}/replies`, { message })
      .then<BoardComment>(({ data }) => data);
  }

  public async deleteComment(id: number, commentId: number): Promise<void> {
    return this.client
      .delete(`/board-items/${id}/comments/${commentId}`)
      .then<void>(({ data }) => data);
  }

  public async deleteCommentReply(
    id: number,
    commentId: number,
    replyId: number
  ): Promise<void> {
    return this.client
      .delete(`/board-items/${id}/comments/${commentId}/replies/${replyId}`)
      .then<void>(({ data }) => data);
  }
}

export default BoardItemClient;
