import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { ToastContainerProps, toast } from 'react-toastify';
import { ReducerAction, ReducerDispatch, UserType } from '../typings';
import UserService from '../services/user.service';

interface State {
  loading?: boolean;
  background?: boolean;
  proModal?: {
    title: string;
    subtitle: string;
  };
  showSignUpModal: boolean;
}

const initialState: State = {
  loading: false,
  background: undefined,
  proModal: undefined,
  showSignUpModal: false,
};

const ACTION_TYPES = {
  SET_LOADING: 'SET_LOADING',
  SET_PRO_MODAL: 'SET_PRO_MODAL',
  TOGGLE_SIGN_UP_MODAL: 'TOGGLE_SIGN_UP_MODAL',
};

const Context = createContext<
  { state: State; dispatch: ReducerDispatch } | undefined
>(undefined);

function reducer(state: State, action: ReducerAction): State {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.value.loading,
        background: action.value.background,
      };
    }
    case ACTION_TYPES.SET_PRO_MODAL: {
      return {
        ...state,
        proModal: action.value,
      };
    }
    case ACTION_TYPES.TOGGLE_SIGN_UP_MODAL: {
      return {
        ...state,
        showSignUpModal: action.value,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AppProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  // @ts-ignore
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useApp() {
  const context = useContext<any>(Context);

  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider');
  }

  const { state, dispatch }: { state: State; dispatch: ReducerDispatch } =
    context;

  function setLoading(loading: boolean, background = false): void {
    dispatch({
      type: ACTION_TYPES.SET_LOADING,
      value: { loading, background },
    });
  }

  function setUpgradeToProModal(value: State['proModal']) {
    dispatch({
      type: ACTION_TYPES.SET_PRO_MODAL,
      value,
    });
  }

  function toggleSignUpModal(value: boolean) {
    dispatch({
      type: ACTION_TYPES.TOGGLE_SIGN_UP_MODAL,
      value,
    });
  }

  function setSuccessMessage(
    message = '',
    autoClose = 6000,
    className = 'viewport-success'
  ): void {
    toast.success(message, { autoClose: autoClose || false, className });
  }

  function setErrorMessage(message: any): void {
    toast.error(message);
  }

  function setCustomToast(message: any, options?: ToastContainerProps): void {
    toast(message, options);
  }

  const isProUser = useMemo((): boolean => {
    const userInfo = new UserService().get();
    const result = userInfo?.type === UserType.PRO;

    return result;
  }, []);

  return {
    state,
    setLoading,
    setSuccessMessage,
    setErrorMessage,
    setCustomToast,
    setUpgradeToProModal,
    toggleSignUpModal,
    isProUser,
  };
}

export { AppProvider, useApp };
