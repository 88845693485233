import styled from 'styled-components/macro';

import { Button } from '../Button/Button';

export const Container = styled.header`
  position: absolute;
  top: 0;
  background-color: ${({ theme }: any) => theme.white};
  border-bottom: 1px solid ${({ theme }: any) => theme.gray2};
  height: 88px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 3;
`;

export const Logo = styled.img`
  height: 40px;
  width: 40px;
  background-image: url(${({ src }) => String(src)}) no-repeat center center;
  background-color: ${({ theme }: any) => theme.white};
  border-radius: 8px;
  padding: 8px;
`;

interface ContentProps {
  marginRight: boolean;
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 24px;
  margin-left: 23px;
  margin-right: ${({ marginRight }) => (marginRight ? '23px' : '0')};

  a {
    color: ${({ theme }: any) => theme.white};
    font-size: 14px;

    &:not(:last-child) {
      margin-right: 36px;
    }
  }
`;

export const CreateButton = styled(Button)`
  width: 135px;
  height: 40px;
  margin-top: 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  color: ${({ theme }: any) => theme.white};
  background: ${({ theme }: any) => theme.blue};
`;

export const JoinButton = styled(Button)`
  min-width: 138px;
  height: 40px;
  margin-top: 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  align-self: center;
  color: ${({ theme }: any) => theme.white};
  background: ${({ theme }: any) => theme.blue};
`;
