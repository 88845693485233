import React, { useMemo, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { useDebouncedCallback } from 'use-debounce';

import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { BoardItem } from '../../../../../../typings';
import { Container, Input, LinkSection } from './Note.styles';

interface NoteProps {
  item: BoardItem;
  disabled: boolean;
  onChange: (value: BoardItem) => void;
}

const Note = SortableHandle<NoteProps>(
  ({ item, disabled, onChange }: NoteProps) => {
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState(item.value as string);
    const debouncedInputValue = useDebouncedCallback((value: string) => {
      onChange({
        ...item,
        value,
      });
    }, 200);

    function handleChange(value: string) {
      debouncedInputValue(value);
      setInputValue(value);
    }

    const isLink = useMemo((): boolean => {
      const regex = /^((https?|ftp):\/\/[^\s/$.?#].[^\s]*)$/i;
      return regex.test(inputValue);
    }, [inputValue]);

    return (
      <Container disabled={disabled}>
        {!disabled && <DragIcon size="20px" color="#7F8087" />}

        {isLink && disabled ? (
          <LinkSection>
            <a href={inputValue} target="_blank" rel="noreferrer">
              {inputValue}
            </a>
          </LinkSection>
        ) : (
          <Input
            cacheMeasurements
            disabled={disabled}
            autoFocus={item.value === ''}
            value={inputValue}
            placeholder="Add a note..."
            maxLength={180}
            onChange={(e) => handleChange(e.target.value)}
            // onHeightChange={(height) => console.log(height)}
            // onFocus={handleClick}
            // onClick={() => setFocused(true)}
            // onBlur={() => setFocused(false)}
          />
        )}
      </Container>
    );
  }
);

export default Note;
