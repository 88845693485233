import { useCallback } from 'react';
import memoize from 'fast-memoize';

const MIN = 1;
const MAX = 10;

interface UseAvatarImageProps {
  getAvatar: (source?: string | null) => string;
  getAvatarMemo: (source?: string | null, comparator?: any) => string;
}

export function useAvatarImage(): UseAvatarImageProps {
  const getAvatar = useCallback((source?: string | null): string => {
    if (source) return source;

    const randomNumber = Math.floor(Math.random() * (MAX - MIN + 1) + MIN);
    const defaultAvatar = `/images/avatars/avatar-background${randomNumber}.png`;

    return defaultAvatar;
  }, []);

  const getAvatarMemo = useCallback(
    memoize((source?: string | null, comparator?: any): string => {
      if (source) return source;

      const randomNumber = Math.floor(Math.random() * (MAX - MIN + 1) + MIN);
      const defaultAvatar = `/images/avatars/avatar-background${randomNumber}.png`;

      return defaultAvatar;
    }),
    []
  );

  return {
    getAvatar,
    getAvatarMemo,
  };
}
