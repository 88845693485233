import { Check } from '@phosphor-icons/react';
import {
  RootComponent,
  Spinner,
  CheckboxContainer,
  LoadingWrapper,
} from './Checkbox.styles';

export interface CheckboxProps {
  name: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  loading?: boolean;
  className?: string;
  sizing?: string;
}

const Checkbox = ({
  name,
  checked,
  onChange,
  className,
  sizing,
  loading = false,
}: CheckboxProps): React.ReactElement =>
  loading ? (
    <Spinner animation="border" role="status" size="sm" />
  ) : (
    <RootComponent
      sizing={sizing}
      className={className}
      checked={checked}
      onChange={(_, newState) => onChange(newState)}
      name={name}
    />
  );

export const CheckboxNew = ({
  name,
  checked,
  onChange,
  className,
  sizing,
  loading = false,
}: CheckboxProps) =>
  loading ? (
    <LoadingWrapper size={sizing}>
      <Spinner animation="border" role="status" size="sm" />
    </LoadingWrapper>
  ) : (
    <CheckboxContainer
      size={sizing}
      checked={checked}
      onClick={() => onChange(!checked)}
      className={className}
    >
      <Check color="white" weight="bold" />
    </CheckboxContainer>
  );

export default Checkbox;
