import styled from 'styled-components/macro';
import { Modal, Spinner as ReactSpinner } from 'react-bootstrap';
import { Button as ButtonBase } from '../../../../components/Button/Button';

import DropdownSelect from '../../../../components/DropdownSelect/DropdownSelect';

const WIDTH = 448;

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: ${WIDTH}px;
  }

  .modal-content {
    border-radius: 16px;
  }

  .modal-header {
    display: flex;
    gap: 24px;
    align-items: center;
    align-self: center;
    padding: 0;
    width: 100%;
    padding: 20px 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    svg {
      color: ${({ theme }) => theme.gray1};
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-top: 32px;
    border: 0;

    * {
      margin: 0;
    }
  }
`;

export const Dropdown = styled(DropdownSelect)`
  background: ${({ theme }: any) => theme.gray2};
  height: 48px;
  border-radius: 12px;

  .MuiInput-input {
    color: ${({ theme }) => theme.gray3};
  }

  svg {
    color: ${({ theme }) => theme.gray3};
  }
`;

export const Spinner = styled(ReactSpinner)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.white};
`;

export const Title = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

export const Button = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

export const Link = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 8px 8px;

  background: ${({ theme }) => theme.gray2};
  border-radius: 8px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    strong {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      margin: 0;
    }

    p {
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;

      color: ${({ theme }) => theme.gray3};
    }
  }
`;
