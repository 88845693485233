import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ArrowsOutSimple } from '@phosphor-icons/react';

import ImageMarker, {
  Marker,
  MarkerComponentProps,
} from '../../../../../../../../components/ImageMarker/ImageMarker';
import AddComment from '../AddComment/AddComment';
import CanvasDraw from '../CanvasDraw/CanvasDraw';

import {
  CanvasDrawWrapper,
  CommentWrapper,
  Container,
  Doodle,
  Pin,
  ImageWrapper,
  EnlargeButton,
  LockMainContent,
  Message,
} from './Image.styles';
import {
  BoardComment,
  BoardCommentMetadataType,
} from '../../../../../../../../typings';
import FileUtils from '../../../../../../../../utils/file.utils';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';
import AuthService from '../../../../../../../../services/auth.service';
import { useApp } from '../../../../../../../../hooks/useMain';
import CopyButton from '../CopyButton/CopyButton';

interface ImageProps {
  src: string;
  drawing?: string;
  feedbackMode: boolean;
  comments: BoardComment[];
  onSubmitComment?: (data: BoardComment) => void;
  onAddComment?: () => void;
  onCancelComment?: () => void;
  onShowDetails: (src: string) => void;
}

const Image: React.FC<ImageProps> = ({
  src,
  drawing,
  feedbackMode,
  comments,
  onSubmitComment,
  onAddComment,
  onCancelComment,
  onShowDetails,
}: ImageProps) => {
  const { toggleSignUpModal } = useApp();
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [marker, setMarker] = useState<Marker | null>();
  const [isDrawing, setIsDrawing] = useState(false);
  const [draw, setDraw] = useState<string | undefined>();
  const [isBig, setIsBig] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleAddMarker(value: Marker) {
    const isAuthenticated = new AuthService().isAuthenticated();

    if (!isAuthenticated) {
      toggleSignUpModal(true);
      return;
    }

    setMarker(value);

    if (marker) {
      markers.pop();
    }

    setMarkers([...markers, value]);

    // using setTimeout to be able to use the scroll functionality
    setTimeout(() => onAddComment?.(), 0);
  }

  function handleCancel() {
    const newMarkers = markers.filter(
      (item) => item.top !== marker?.top && item.left !== marker?.left
    );
    setMarker(null);
    setMarkers(newMarkers);
    setDraw(undefined);

    // using setTimeout to be able to use the scroll functionality
    setTimeout(() => onCancelComment?.(), 0);
  }

  function handleAddComment(data: BoardComment) {
    onSubmitComment?.(data);
    setMarker(null);
    setDraw(undefined);
  }

  function handleOnDraw() {
    setDraw(undefined);
    setIsDrawing(true);
  }

  function handleOnSaveDraw(value: string) {
    setDraw(value);
    setIsDrawing(false);
  }

  function onImageLoad() {
    const imageElement: HTMLImageElement | null | undefined =
      imageRef.current?.querySelector('.image-marker__image');

    const dimensions = {
      height: imageElement?.naturalHeight,
      width: imageElement?.naturalWidth,
    };

    if (
      (dimensions?.height && dimensions?.height > 900) ||
      (dimensions?.width && dimensions?.width > 900)
    ) {
      setIsBig(true);
    }
  }

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        Boolean(marker) &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        handleCancel();
      }
    },
    [marker, containerRef.current]
  );

  const containerDimension = useMemo(() => {
    // feedbackMode access needed to get dimensions of container after image loads
    if (imageRef.current && feedbackMode) {
      const dimensions = imageRef.current?.getBoundingClientRect();

      return dimensions;
    }

    return { width: 0, height: 0 };
  }, [imageRef.current, feedbackMode]);

  const containerMode = useMemo(
    () =>
      feedbackMode && isDrawing
        ? {
            text: 'Drawing mode',
            color: '#FF5656',
          }
        : undefined,
    [feedbackMode, isDrawing]
  );

  const shouldShowLightbox = useMemo((): boolean => {
    const isImage = new FileUtils(src).isImage();
    const isFeedbackModeInactive = !containerMode;
    const hasComments = Boolean(comments.length);

    return isImage && isFeedbackModeInactive && !hasComments;
  }, [src, containerMode, comments]);

  useEffect(() => {
    if (!feedbackMode) {
      setMarker(null);
      setIsDrawing(false);
      setDraw(undefined);
    }
  }, [feedbackMode]);

  useEffect(() => {
    const markersFromComments: Marker[] = comments.map(
      (comment) =>
        comment.metadatas?.find(
          (metadata) => metadata.type === BoardCommentMetadataType.MARKER
        )?.value
    );

    if (!marker) {
      setMarkers(markersFromComments);
    }
  }, [comments]);

  useEffect(() => {
    setDraw(drawing);
  }, [drawing]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <>
      <Container ref={containerRef} drawing={isDrawing}>
        <ImageWrapper
          show={false}
          mode={containerMode}
          isDrawing={isDrawing}
          isBig={isBig}
        >
          <ImageMarker
            ref={imageRef}
            src={src}
            markers={markers}
            onAddMarker={handleAddMarker}
            onLoad={onImageLoad}
            markerComponent={({ itemNumber }: MarkerComponentProps) => (
              <Pin show={!isDrawing}>{Number(itemNumber) + 1}</Pin>
            )}
          />
        </ImageWrapper>

        {shouldShowLightbox && (
          <>
            <CopyButton image={src} />

            <Tooltip
              title="Enlarge"
              id="enlarge"
              placement="left"
              borderRadius="4px"
              spacing={2}
              height="32px"
            >
              <EnlargeButton type="button" onClick={() => onShowDetails(src)}>
                <ArrowsOutSimple color="white" size="18px" weight="bold" />
              </EnlargeButton>
            </Tooltip>
          </>
        )}

        {!marker && <Message>Tap anywhere to start a comment</Message>}

        {/* {getContainerMode() && <LockMainContent />} */}

        {Boolean(draw) && <Doodle border={Boolean(containerMode)} src={draw} />}

        {Boolean(marker) && (
          <CommentWrapper top={marker?.top} left={marker?.left}>
            <AddComment
              onSave={handleAddComment}
              onCancel={handleCancel}
              onDrawingSave={handleOnDraw}
              onDrawingDelete={() => setDraw(undefined)}
              show={!isDrawing}
              drawValue={draw}
              marker={marker}
            />
          </CommentWrapper>
        )}

        {isDrawing && feedbackMode && (
          <CanvasDrawWrapper>
            <CanvasDraw
              width={containerDimension.width}
              height={containerDimension.height}
              onSave={handleOnSaveDraw}
              onCancel={() => setIsDrawing(false)}
            />
          </CanvasDrawWrapper>
        )}
      </Container>
    </>
  );
};

export default Image;
