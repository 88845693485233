import React, { useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Content,
  Title,
  ModalContainer,
  Actions,
  Logo,
} from './PleaseSignUpModal.styles';
import { Button, ButtonOutline } from '../Button/Button';
import { useApp } from '../../hooks/useMain';
import AuthService from '../../services/auth.service';
import ROUTES from '../../routes';

const PleaseSignUpModal = () => {
  const { state, toggleSignUpModal } = useApp();
  const navigate = useNavigate();

  const isAuthenticated = useMemo(
    (): boolean => new AuthService().isAuthenticated(),
    [state.showSignUpModal]
  );

  function handleClose() {
    toggleSignUpModal(false);
  }

  function handleConfirm() {
    navigate(ROUTES.SIGN_UP);
    toggleSignUpModal(false);
  }

  if (isAuthenticated) {
    return null;
  }

  return (
    <ModalContainer
      show={state.showSignUpModal}
      size="md"
      backdrop="static"
      keyboard={false}
      centered
      autoFocus
      enforceFocus
    >
      <Content>
        <Logo
          src="/images/logo-without-background-v2.png"
          alt="Viewport logo"
        />

        <Title>
          Sign up for free to add comments and to create your own projects
        </Title>

        <Actions>
          <ButtonOutline
            type="button"
            height="48px"
            width="108px"
            marginTop="0"
            fontSize="16"
            onClick={handleClose}
          >
            Cancel
          </ButtonOutline>

          <Button
            type="button"
            height="48px"
            width="108px"
            marginTop="0"
            fontSize="16"
            onClick={handleConfirm}
          >
            Sign up
          </Button>
        </Actions>
      </Content>
    </ModalContainer>
  );
};

export default PleaseSignUpModal;
