import styled from 'styled-components';

interface SpacerProps {
  width?: number;
  height?: number;
  display?: string;
}

const Spacer = styled.span<SpacerProps>`
  display: ${({ display }) => display || 'inline-block'};
  width: ${({ width }) => (width ? `${width}px` : '0px')};
  height: ${({ height }) => (height ? `${height}px` : '0px')};

  @media ${({ theme }: any) => theme.mobileL} {
    width: calc(${({ width }) => (width ? `${width}px` : '0px')} / 2);
    height: calc(${({ height }) => (height ? `${height}px` : '0px')} / 2);
  }
`;

export default Spacer;
