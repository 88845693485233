import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MoonLoader from 'react-spinners/MoonLoader';

import { Button, ButtonOutline } from '../../../../components/Button/Button';
import {
  Content,
  TextField,
  InputsWrapper,
  Header,
  Form,
  LoaderWrapper,
} from '../Create/Create.styles';
import UserClient from '../../../../clients/user.client';
import { useApp } from '../../../../hooks/useMain';
import { Row } from '../../../../components/Row/Row';
import useLogin from '../../../Signin/hooks/useController/useController';
import JuneService from '../../../../services/june.service';

const validation = Yup.object({
  code: Yup.string()
    .max(10, 'Must be 10 characters or less')
    .required('Required'),
});

interface CodeVerificationFormProps {
  onSuccess: () => void;
  data: {
    email: string;
    password: string;
  };
}

const CodeVerificationForm = ({
  onSuccess,
  data,
}: CodeVerificationFormProps) => {
  const { setSuccessMessage } = useApp();
  const { performSignin } = useLogin();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validation,
    onSubmit: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      performSubmit(values.code);
    },
  });

  async function performSubmit(code: string) {
    try {
      setIsLoading(true);

      await new UserClient().signupConfirmation(data.email, code);

      await performSignin(
        data.email,
        data.password,
        formik.setFieldError,
        setIsLoading
      );

      new JuneService().init().track('Signed Up');

      onSuccess();
    } catch (error: any) {
      formik.setFieldError(
        'code',
        error?.data?.message || 'Something went wrong while sending the code'
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function performResendVerificationCode() {
    try {
      setIsLoading(true);

      await new UserClient().resendConfirmationCode(data.email);

      setSuccessMessage('Verification code sent');
    } catch (error: any) {
      formik.setFieldError(
        'code',
        error?.data?.message || 'Something went wrong while sending the code'
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Content>
      {isLoading ? (
        <LoaderWrapper>
          <MoonLoader color="#7F8087" size={30} />
        </LoaderWrapper>
      ) : (
        <>
          <Header>
            <>
              <p style={{ textAlign: 'left', fontSize: '20px' }}>
                Enter verification code we sent you
              </p>
              <small style={{ textAlign: 'center' }}>
                We sent you a verification link to your email: {data.email}
              </small>
            </>
          </Header>

          <Form noValidate onSubmit={formik.handleSubmit}>
            <InputsWrapper>
              <TextField
                type="text"
                id="code"
                name="code"
                placeholder="Verification code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.code}
                error={formik.touched.code ? formik.errors.code : ''}
              />
            </InputsWrapper>

            <Row justify="space-between">
              <ButtonOutline
                type="button"
                width="155px"
                height="48px"
                fontSize="16"
                borderRadius="12px"
                borderSize="1px"
                color="#7F8087"
                onClick={performResendVerificationCode}
              >
                Resend email
              </ButtonOutline>

              <Button
                type="submit"
                width="121px"
                height="48px"
                fontSize="16"
                borderRadius="12px"
                disabled={!formik.isValid}
              >
                Continue
              </Button>
            </Row>
          </Form>
        </>
      )}
    </Content>
  );
};

export default CodeVerificationForm;
