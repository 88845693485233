import styled, { css, createGlobalStyle } from 'styled-components/macro';
import TextField from '@material-ui/core/TextField';

export const Container = styled.div`
  .MuiCircularProgress-root {
    margin-right: 12px;
  }
`;

export const User = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
`;

export const Username = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.gray3};
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  object-fit: cover;
`;

export const Input = styled(TextField)`
  height: 48px;
  width: 100%;
  padding: 11px 20px;
  background: #f1f3f5;
  border: 0;
  line-height: 25px;
  border: 3px solid transparent;
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;

  &:focus {
    border-color: ${({ theme }: any) => theme.blue};
  }

  &:active {
    border-color: ${({ theme }: any) => theme.blue};
  }

  .MuiInputBase-root {
    height: 48px;
    padding: 0 16px !important;
    padding-right: 0px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  input {
    font-weight: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.black};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputAction = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
`;

export const NotFound = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  svg {
    width: 32px;
    height: 32px;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    color: ${({ theme }) => theme.gray3};
  }
`;

interface GlobalStyleProps {
  id: string;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${({ id }) => `#${id}-popup`} {
    padding: 0 !important;

    li {
      padding: 8px 13px;

      &:not(:last-child) {
        border-bottom: 1px solid #E3E6EC;
      }
    }
  }

  .MuiAutocomplete-option {
    &[data-focus="true"] {
      background-color: #fff !important;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
`;
