import React from 'react';

import { Container, Item } from './Tabs.styles';
import Grid from '../../../../components/Icon/icons/Grid';
import Stacks from '../../../../components/Icon/icons/Stacks';
import Timeline from '../../../../components/Icon/icons/Timeline';

export enum ProjectTabs {
  STACKS = 'FEED',
  GRID = 'GRID',
  TIMELINE = 'TIMELINE',
}

const TABS = [
  {
    id: ProjectTabs.STACKS,
    name: 'Stacks',
    icon: <Stacks />,
  },
  {
    id: ProjectTabs.GRID,
    name: 'Grid',
    icon: <Grid />,
  },
  {
    id: ProjectTabs.TIMELINE,
    name: 'Timeline',
    icon: <Timeline />,
  },
];

interface TabsProps {
  current: ProjectTabs;
  onChange: (id: ProjectTabs) => void;
  showingSideBar: boolean;
}

const Tabs = ({ current, showingSideBar, onChange }: TabsProps) => (
  <Container showingSideBar={showingSideBar}>
    {TABS.map((tab) => (
      <Item
        active={Boolean(tab.id === current)}
        onClick={() => onChange(tab.id)}
        key={tab.id}
      >
        {tab.icon} <p>{tab.name}</p>
      </Item>
    ))}
  </Container>
);

export default Tabs;
