import styled from 'styled-components/macro';
import { CaretDown } from '@phosphor-icons/react';
import { Accordion, AccordionProps } from 'react-bootstrap';

interface IconProps {
  open: boolean;
}

export const Icon = styled(CaretDown)<IconProps>`
  background: ${({ theme, open }) => (open ? 'transparent' : theme.white)};
  border-radius: 12px;
  color: ${({ theme }) => theme.gray1};
  width: 48px !important;
  height: 48px !important;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.gray};

  transform: rotateX(0deg);
  transition: transform 0.2s ease-in-out !important;
  overflow: hidden;
  transform: ${({ open: isOpen }) => (isOpen ? `rotateX(-180deg)` : '')};

  &:hover {
    background: ${({ theme }) => theme.white};
  }
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    gap: 2px;
  }
`;

export const Image = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.gray};

  &:hover {
    box-shadow: 0px 0px 0px 2px #00caf2;
  }
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.14px;
  text-align: left;

  width: 186px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Creator = styled.p`
  color: ${({ theme }) => theme.gray3};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
  text-align: left;

  width: 186px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 12px;
`;

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.white};
`;

export const LinksSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.white};
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const ItemInfo = styled.div`
  margin-bottom: 12px;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.14px;
  }

  p {
    color: ${({ theme }) => theme.gray3};
    font-family: Silka;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.14px;
  }
`;

export const Section = styled.section``;

export const Placeholder = styled.div`
  width: 306px;
  height: 48px;
`;

interface AccordionWrapperProps extends AccordionProps {
  isOpen: boolean;
  showingDetails: boolean;
}

export const AccordionWrapper = styled(Accordion)<AccordionWrapperProps>`
  position: absolute;
  top: 12px;
  width: 322px;
  background: ${({ theme, isOpen }) => (isOpen ? theme.gray2 : theme.white)};
  box-shadow: ${({ isOpen }) =>
    isOpen ? '0px 8px 8px -8px rgba(127, 128, 135, 0.2)' : 'none'};
  border-radius: 16px;
  border: 1px solid
    ${({ isOpen, theme }) =>
      isOpen ? `${theme.gray as string}` : 'transparent'};
  z-index: 2;

  #accordion-toggle {
    width: 100%;
    height: 100%;
    border: 0;
    padding: 8px;
    background: transparent;
  }

  @media (max-width: 1500px) {
    width: ${({ isOpen, showingDetails }) =>
      isOpen || !showingDetails ? '322px' : '198px'};

    ${Icon} {
      display: ${({ isOpen, showingDetails }) =>
        isOpen || !showingDetails ? 'flex' : 'none'};
    }

    ${Content} {
      opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    }

    ${Header} {
      justify-content: ${({ isOpen, showingDetails }) =>
        isOpen || !showingDetails ? 'space-between' : 'center'};
    }

    ${Name} {
      width: ${({ showingDetails }) => (showingDetails ? '118px' : '186px')};
    }

    ${Creator} {
      width: ${({ showingDetails }) => (showingDetails ? '118px' : '186px')};
    }
  }

  @media (max-width: 1250px) {
    width: ${({ isOpen, showingDetails }) =>
      isOpen ? '322px' : `${showingDetails ? 64 : 222}px`};

    ${Icon} {
      display: ${({ isOpen, showingDetails }) =>
        isOpen && showingDetails ? 'flex' : 'none'};
    }

    ${Section} {
      display: ${({ isOpen, showingDetails }) =>
        isOpen || !showingDetails ? 'flex' : 'none'};
    }

    ${Name} {
      width: ${({ showingDetails }) => (showingDetails ? '118px' : '186px')};
    }

    ${Creator} {
      width: ${({ showingDetails }) => (showingDetails ? '118px' : '186px')};
    }
  }
`;
