/* eslint-disable class-methods-use-this */
import BaseClient from './base.client';
import AuthService from '../services/auth.service';
import {
  TeamSimplifiedViewModel,
  TeamViewModel,
  UserViewModel,
} from '../typings';

class TeamClient extends BaseClient {
  token: string;

  constructor() {
    super();
    this.token = new AuthService().getToken();
  }

  public async create(payload: TeamViewModel): Promise<TeamViewModel> {
    return this.client
      .post('/teams', payload)
      .then<TeamViewModel>(({ data }) => data);
  }

  public async update(
    id: number,
    payload: TeamViewModel
  ): Promise<TeamViewModel> {
    return this.client
      .put(`/teams/${id}`, payload)
      .then<TeamViewModel>(({ data }) => data);
  }

  public async delete(id: number): Promise<void> {
    return this.client.delete(`/teams/${id}`).then<void>(({ data }) => data);
  }

  public async getAll(): Promise<TeamViewModel[]> {
    return this.client.get(`/teams`).then<TeamViewModel[]>(({ data }) => data);
  }

  public async getAllSimplified(): Promise<TeamSimplifiedViewModel[]> {
    return this.client
      .get(`/teams/simplified`)
      .then<TeamSimplifiedViewModel[]>(({ data }) => data);
  }

  public async getById(
    id: number,
    invitedUserEmail?: string
  ): Promise<TeamViewModel> {
    return this.client
      .get(`/teams/${id}`, { params: { invitedUserEmail } })
      .then<TeamViewModel>(({ data }) => data);
  }

  public async addBoards(id: number, boards: number[]): Promise<void> {
    return this.client
      .put(`/teams/${id}/boards`, { boards })
      .then<void>(({ data }) => data);
  }

  public async removeBoard(id: number, project: number): Promise<void> {
    return this.client
      .delete(`/teams/${id}/boards/${project}`)
      .then<void>(({ data }) => data);
  }

  public async addMember(id: number, member: string): Promise<void> {
    return this.client
      .put(`/teams/${id}/members/${member}`)
      .then<void>(({ data }) => data);
  }

  public async removeMemberInvite(id: number, member: string): Promise<void> {
    return this.client
      .delete(`/teams/${id}/members/${member}/invite`)
      .then<void>(({ data }) => data);
  }

  public async removeMember(id: number, memberEmail: string): Promise<void> {
    return this.client
      .delete(`/teams/${id}/members/${memberEmail}`)
      .then<void>(({ data }) => data);
  }

  public async join(id: number): Promise<void> {
    return this.client.post(`/teams/${id}/join`).then<void>(({ data }) => data);
  }

  public async unjoin(id: number): Promise<void> {
    return this.client
      .post(`/teams/${id}/unjoin`)
      .then<void>(({ data }) => data);
  }

  public async acceptInvitation(id: number): Promise<void> {
    return this.client
      .post(`/teams/${id}/accept-invitation`)
      .then<void>(({ data }) => data);
  }

  public async declineInvitation(
    id: number,
    invitedUserEmail?: string
  ): Promise<void> {
    return this.client
      .delete(`/teams/${id}/decline-invitation`, {
        params: { invitedUserEmail },
      })
      .then<void>(({ data }) => data);
  }
}

export default TeamClient;
