import React from 'react';
import { HiLockClosed as PrivateIcon } from 'react-icons/hi';
import {
  Container,
  Title,
  Subtitle,
  CloseButton,
  GlobalStyle,
} from './ProjectCreatedToast.styles';
import { ButtonOutline } from '../../../../components/Button/Button';

interface ProjectCreatedToastProps {
  onShare?: () => void;
  closeToast: () => void;
}

const ProjectCreatedToast = ({
  closeToast,
  onShare,
}: ProjectCreatedToastProps) => (
  <Container>
    <GlobalStyle />

    <CloseButton onClick={closeToast} />

    <Title>Project created!</Title>
    <Subtitle>
      <PrivateIcon /> Only visible to you
    </Subtitle>

    <ButtonOutline
      type="button"
      width="193px"
      height="46px"
      marginTop="12px"
      borderSize="1px"
      color="#FFFFFF"
      borderRadius="8px"
      onClick={onShare}
    >
      Share project
    </ButtonOutline>
  </Container>
);

export default ProjectCreatedToast;
