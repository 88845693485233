import React, { useState } from 'react';
import { Masonry } from 'masonic';

import { Container, Header, Image } from './GridView.styles';
import ImageUtils from '../../../../utils/image.utils';

export interface GridViewItem {
  id: number;
  columnId: number;
  url: string;
}

interface GridViewProps {
  images: Array<GridViewItem>;
  onClick: (columnId: number, itemId: number) => void;
}

const GridView: React.FC<GridViewProps> = ({
  images,
  onClick,
}: GridViewProps) => {
  const [itemToZoom, setItemToZoom] = useState<GridViewItem | null>(null);

  return (
    <Container>
      <Header>
        <p>All assets ({images.length})</p>
        <p>Organized chronologically</p>
      </Header>

      <Masonry
        items={images}
        columnGutter={12}
        rowGutter={12}
        columnWidth={155}
        maxColumnCount={10}
        render={({ data: item, width }) => (
          <Image
            src={new ImageUtils(item.url).getSmall()}
            width={width}
            onClick={() => onClick(item.columnId, item.id)}
          />
        )}
      />

      {/* <CarouselModal
        show={Boolean(itemToZoom)}
        images={imagesOrderedByCreatedAt as unknown as ProjectAsset[]}
        selectedItem={imagesOrderedByCreatedAt.findIndex(
          (image) => image.url === itemToZoom?.url
        )}
        onClose={() => setItemToZoom(null)}
      /> */}
    </Container>
  );
};

export default GridView;
