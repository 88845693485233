import { useState } from 'react';
import LocalStorageService from '../services/localStorage.service';

const WELCOME_CARD_KEY = 'viewport_dashboard_status';

interface UseDashboardSettingsProps {
  data: any;
  onClose: (name: string) => void;
  onToggle: (name: string, value: boolean) => void;
}

export function useDashboardSettings<T extends string>(
  names: T[],
  defaultValue = true
): UseDashboardSettingsProps {
  const [data, setData] = useState<Record<T, boolean>>(() => {
    const result = LocalStorageService.get(WELCOME_CARD_KEY)?.data;

    const items = names.reduce((acc, name) => {
      acc[name] = result?.[name] ?? defaultValue;

      return acc;
    }, {} as Record<T, boolean>);

    return { ...items, ...result };
  });

  function handleClose(name: string) {
    const result = { ...data, [name]: false };
    setData(result);

    LocalStorageService.set(WELCOME_CARD_KEY, result);
  }

  function handleToggle(name: string, value: boolean) {
    const result = { ...data, [name]: value };
    setData(result);

    LocalStorageService.set(WELCOME_CARD_KEY, result);
  }

  return {
    data,
    onClose: handleClose,
    onToggle: handleToggle,
  };
}
