import React from 'react';

const RatioLandscape = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1475_1566)">
      <rect
        x="31.5"
        y="8"
        width="16"
        height="30"
        rx="3"
        transform="rotate(90 31.5 8)"
        fill="white"
        fillOpacity="0.15"
      />
      <rect
        x="31.5"
        y="8"
        width="16"
        height="30"
        rx="3"
        transform="rotate(90 31.5 8)"
        stroke="black"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1475_1566">
        <rect width="32" height="32" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RatioLandscape;
