import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ButtonLink } from '../Button/Button';

import { useApp } from '../../hooks/useMain';

import {
  Container,
  Uploader,
  AddIcon,
  Picture,
  PictureWrapper,
  Actions,
  Description,
} from './ProfilePhotoUploader.styles';

interface ProfilePhotoUploaderProps {
  title?: string;
  data?: string;
  onChange: (item: File | null) => void;
  size?: string;
  backgroundFancy?: boolean;
  icon?: React.ReactNode;
  fontSize?: string;
  borderRadius?: string;
  hoverEffect?: boolean;
}

const MAX_FILES = 1;

const ProfilePhotoUploader: React.FC<ProfilePhotoUploaderProps> = ({
  title,
  data,
  onChange,
  size,
  backgroundFancy = false,
  icon = <AddIcon fontSize="large" />,
  fontSize = '14px',
  borderRadius = '15px',
  hoverEffect = false,
}: ProfilePhotoUploaderProps) => {
  const { setErrorMessage } = useApp();

  const onDrop = function (newFiles: any[]) {
    if (newFiles.length > MAX_FILES) {
      setErrorMessage(`Maximun files allowed are ${MAX_FILES}`);
      return;
    }

    if (onChange) {
      onChange(newFiles[0]);
    }
  };

  const {
    getRootProps,
    getInputProps,
    open: openUploader,
  } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES,
    accept: 'image/jpg, image/jpeg, image/png, image/gif, image/tiff',
    maxSize: 20971520, // 20 MB's
    noClick: Boolean(data),
  });

  return (
    <Container {...getRootProps()} size={size}>
      {!data && (
        <Uploader
          backgroundFancy={backgroundFancy}
          borderRadius={borderRadius}
          hoverEffect={hoverEffect}
        >
          <input {...getInputProps()} />
          {icon}
          {Boolean(title) && (
            <Description dangerouslySetInnerHTML={{ __html: String(title) }} />
          )}
        </Uploader>
      )}

      {Boolean(data) && (
        <PictureWrapper>
          <input {...getInputProps()} />

          <Picture src={data} />

          <Actions fontSize={fontSize}>
            <ButtonLink type="button" onClick={() => openUploader()}>
              Replace
            </ButtonLink>
            <ButtonLink type="button" onClick={() => onChange(null)}>
              Remove
            </ButtonLink>
          </Actions>
        </PictureWrapper>
      )}
    </Container>
  );
};

export default ProfilePhotoUploader;
