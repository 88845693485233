const ROUTES = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  GOOGLE_CALLBACK: '/auth/google-callback',
  SIGNIN: '/signin',
  SIGN_UP: '/sign-up',
  SIGN_UP_PRO: '/prosignup',
  SIGN_UP_GROUP: '/sign-up/external/:groupId',
  PASSWORD_RECOVER: '/password-recover',
  PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  COMMUNITY: '/community',
  PROFILE_NOT_FOUND: '/profile/not-found',
  GIF_CREATOR: '/gif-creator',

  GROUP_NEW: '/group/new',
  GROUP_EDIT: (id?: string): string => `/group/edit/${id || ':id'}`,
  GROUP_VIEW: (id?: string): string => `/group/${id || ':id'}`,
  GROUP_VIEW_NOT_FOUND: (id?: string): string =>
    `/group/${id || ':id'}/not-found`,

  PROJECT: '/project',
  PROJECT_NEW: '/project/new',
  PROJECT_EDIT: (id?: string): string => `/project/edit/${id || ':id'}`,
  PROJECT_VIEW: (id?: string): string => `/project/${id || ':id'}`,
  NEW_PROJECT_NOT_FOUND: (id?: string): string =>
    `/project/${id || ':id'}/not-found`,
  PROJECT_EDIT_CONTENT: (id?: string): string =>
    `/project/edit/${id || ':id'}/content`,
  PROJECT_EDIT_SHARING: (id?: string): string =>
    `/project/edit/${id || ':id'}/sharing`,
  PROJECT_EDIT_FEEDBACK: (id?: string): string =>
    `/project/edit/${id || ':id'}/feedback`,
  PROJECT_VIEW_FEEDBACK: (id?: string): string =>
    `/project/view/${id || ':id'}/feedback`,
  PROJECT_NOT_FOUND: (id: string): string => `/project/view/${id}/not-found`,
  PROJECT_VIEW_PUBLIC: (id?: string): string => `/project/${id || ':id'}`,

  POST_NEW: '/post/new',
  POST_TYPE: '/post/type',
  POST_EDIT: (id?: string): string => `/post/edit/${id || ':id'}`,
  POST_VIEW: (id?: string): string => `/post/${id || ':id'}`,
  POST_VIEW_NOT_FOUND: (id?: string): string =>
    `/post/${id || ':id'}/not-found`,

  SETTINGS: '/settings',
  SETTINGS_INVITES: '/settings/invites',
};

export default ROUTES;
