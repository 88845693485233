import React from 'react';

const PencilDrawing = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M7.57731 17.0528H3.78783C3.62033 17.0528 3.45968 16.9863 3.34124 16.8678C3.2228 16.7494 3.15625 16.5887 3.15625 16.4212V12.8923C3.15597 12.8103 3.17186 12.729 3.20301 12.6532C3.23417 12.5773 3.27999 12.5083 3.33783 12.4502L12.8115 2.9765C12.8703 2.91683 12.9403 2.86944 13.0176 2.83709C13.0948 2.80474 13.1778 2.78809 13.2615 2.78809C13.3453 2.78809 13.4282 2.80474 13.5054 2.83709C13.5827 2.86944 13.6527 2.91683 13.7115 2.9765L17.2326 6.49756C17.2922 6.55632 17.3396 6.62637 17.372 6.70363C17.4043 6.78088 17.421 6.8638 17.421 6.94756C17.421 7.03131 17.4043 7.11423 17.372 7.19148C17.3396 7.26874 17.2922 7.33879 17.2326 7.39756L7.57731 17.0528Z"
      stroke="#C8C9CF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7383 5.05273L15.1593 9.47379"
      stroke="#C8C9CF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0545 17.0525H7.5808L3.19922 12.6709"
      stroke="#C8C9CF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9461 7.26367L5.36719 14.8426"
      stroke="#C8C9CF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PencilDrawing;
