import styled from 'styled-components/macro';

export const Content = styled.div`
  width: 560px;
  min-height: 256px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;

  p {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  }

  small {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: ${({ theme }) => theme.gray3};
  }
`;

interface ItemProps {
  active: boolean;
}

export const Item = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 24px;
  border-radius: 16px;
  cursor: pointer;
  border: ${({ active }) =>
    active ? '2px solid #00CAF2' : '1px solid #E3E6EC'};
  box-shadow: ${({ active }) => (active ? '0px 2px 0px #00CAF2' : 'none')};

  .MuiCheckbox-root {
    padding: 0 !important;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: #000000;
  }

  small {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: ${({ theme }) => theme.gray3};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
