import styled, { createGlobalStyle } from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IframeMockup = styled.div`
  width: 600px;
  height: 494px;
  border-radius: 24px;
  border: 1px solid #e0e0e0;
`;

export const HideMobileProtection = createGlobalStyle`
  #small-screen-not-supported {
    display: none !important;
  }
`;
