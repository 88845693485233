import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Close';
import { PencilLine } from '@phosphor-icons/react';

import {
  Container,
  Actions,
  Textarea,
  Drawing,
  Line,
} from './AddComment.styles';
import {
  BoardComment,
  BoardCommentMetadataType,
} from '../../../../../../../../typings';
import { Marker } from '../../../../../../../../components/ImageMarker/ImageMarker';
import {
  Button,
  ButtonLink,
  ButtonOutline,
} from '../../../../../../../../components/Button/Button';
import Icon, { IconType } from '../../../../../../../../components/Icon/Icon';
import UserService from '../../../../../../../../services/user.service';
import Spacer from '../../../../../../../../components/Spacer/Spacer';

interface AddCommentProps {
  onCancel: () => void;
  onSave: (data: BoardComment) => void;
  onDrawingSave: () => void;
  onDrawingDelete: () => void;
  drawValue?: string;
  show?: boolean;
  marker?: Marker | null;
}

const AddComment: React.FC<AddCommentProps> = ({
  onCancel,
  onSave,
  onDrawingSave,
  onDrawingDelete,
  show = true,
  drawValue,
  marker,
}: AddCommentProps) => {
  const [data, setData] = useState('');

  function handleSave() {
    const userInfo = new UserService().get();

    const comment: BoardComment = {
      value: data,
      user: userInfo,
      createdAt: new Date(),
      updatedAt: new Date(),
      metadatas: [
        {
          type: BoardCommentMetadataType.MARKER,
          value: marker,
        },
      ],
    };

    if (drawValue) {
      comment.metadatas?.push({
        type: BoardCommentMetadataType.DRAWING,
        value: drawValue,
      });
    }

    onSave(comment);
    setData('');
  }

  function handleCancel() {
    onCancel();
    setData('');
  }

  return (
    <Container show={show}>
      <Textarea
        autoFocus
        placeholder="Type your comment here"
        value={data}
        maxLength={245}
        onChange={({ target }: any) => setData(target.value)}
      />

      <Line />

      {Boolean(drawValue) && (
        <Drawing>
          Drawing attached <DeleteIcon onClick={onDrawingDelete} />
        </Drawing>
      )}

      <Actions>
        <ButtonOutline
          type="button"
          height="28px"
          width="68px"
          marginTop="0"
          borderSize="1px"
          borderRadius="8px"
          alignSelf="auto"
          fontWeight="400"
          fontSize="12"
          color="#7F8087"
          borderColor="#C8C9CF"
          onClick={onDrawingSave}
        >
          <PencilLine size={14} color="#7F8087" />
          <Spacer width={4} />
          Draw
        </ButtonOutline>

        <span>
          <ButtonLink
            type="button"
            height="28px"
            width="66px"
            marginTop="0"
            fontSize="12"
            color="#C8C9CF"
            withUnderline={false}
            onClick={handleCancel}
          >
            Cancel
          </ButtonLink>
          <Button
            type="button"
            height="28px"
            width="68px"
            marginTop="0"
            borderRadius="8px"
            fontSize="12"
            disabled={!data}
            onClick={handleSave}
          >
            Submit
          </Button>
        </span>
      </Actions>
    </Container>
  );
};

export default AddComment;
