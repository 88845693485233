/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-param-reassign */
// @ts-nocheck

import * as Sentry from '@sentry/react';
import UserService from '../services/user.service';

export const MicrosoftClarity = () =>
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = `https://www.clarity.ms/tag/${i}`;
    // eslint-disable-next-line prefer-destructuring
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', 'c64rms57zp');

export const SentryIO = () => {
  Sentry.init({
    dsn: 'https://8fd55111ce36bd87bc7255afefd7e8bb@o4505844905934848.ingest.sentry.io/4505844909604864',
    integrations: [
      Sentry.browserTracingIntegration({
        // This is the default value, but it's important to set it to true for performance monitoring
        tracingOrigins: [window.location.origin],
        // This is the default value, but it's important to set it to true for session replay
        tracingReferrerPatterns: [window.location.origin],
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin],
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracePropagationTargets: [window.location.origin],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const userInfo = new UserService().get();

  if (userInfo) {
    Sentry.setUser({
      email: userInfo.email,
    });
  }
};
