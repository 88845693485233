import React, { ReactElement, cloneElement, useState } from 'react';
import { Modal } from 'react-bootstrap';

import {
  ModalContainer,
  Title,
  ConfirmButton,
} from './CancelConfirmationModal.styles';
import { ButtonOutline } from '../../../../components/Button/Button';

interface CancelConfirmationModalProps {
  onExit: () => void;
  onSave: () => void;
  validate: boolean;
  children: React.ReactNode;
}

const CancelConfirmationModal: React.FC<CancelConfirmationModalProps> = ({
  onExit,
  onSave,
  validate,
  children,
}: CancelConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const childrenWithProps = React.Children.map(children, (child) =>
    cloneElement(child as ReactElement, {
      onClick: () => {
        if (!validate) {
          onExit();
        } else {
          setIsOpen(true);
        }
      },
    })
  );

  return (
    <>
      {childrenWithProps}

      <ModalContainer
        show={isOpen}
        size="md"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Title>
            You have unsaved changes.
            <br /> Are you sure you want to cancel these updates?
          </Title>
        </Modal.Header>

        <Modal.Footer>
          <ConfirmButton
            type="button"
            width="200px"
            height="48px"
            marginTop="0"
            borderRadius="12px"
            onClick={() => {
              setIsOpen(false);
              onExit();
            }}
          >
            Exit without saving
          </ConfirmButton>

          <ButtonOutline
            type="button"
            width="157px"
            height="48px"
            marginTop="0"
            borderSize="1px"
            borderRadius="12px"
            color="#7F8087"
            onClick={() => {
              setIsOpen(false);
              onSave();
            }}
          >
            Save updates
          </ButtonOutline>
        </Modal.Footer>
      </ModalContainer>
    </>
  );
};

export default CancelConfirmationModal;
