const LocalStorageService = {
  set: (name: string, data: any) => {
    try {
      localStorage.setItem(name, JSON.stringify(data));
      return { data: true };
    } catch (error) {
      return { data: false, error };
    }
  },
  get: (name: string, defaultValue?: any) => {
    try {
      const value = localStorage.getItem(name);
      if (value !== null) {
        return { data: JSON.parse(value) };
      }
      return { data: defaultValue };
    } catch (error) {
      return { error };
    }
  },
  remove: (name: string) => {
    try {
      const data = localStorage.removeItem(name);
      return { data };
    } catch (error) {
      return { error };
    }
  },
};

export default LocalStorageService;
