/* eslint-disable class-methods-use-this */
import { UserViewModel } from '../typings';
import LocalStorageService from './localStorage.service';

const USER_STORAGE_KEY = 'viewport-user';

class UserService {
  login(userData: any): void {
    LocalStorageService.set(USER_STORAGE_KEY, userData);
  }

  logout(): void {
    LocalStorageService.remove(USER_STORAGE_KEY);
  }

  loginSimple(userData: { fullName: string; email: string }): void {
    LocalStorageService.set(USER_STORAGE_KEY, userData);
  }

  get(): UserViewModel {
    const { data } = LocalStorageService.get(USER_STORAGE_KEY);
    return data;
  }

  getToken(): string {
    const { data } = LocalStorageService.get(USER_STORAGE_KEY);
    return data && data.token;
  }
}

export default UserService;
