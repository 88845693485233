import React, { useEffect, useMemo, useState } from 'react';
import { HiArrowUp as ArrowUpIcon } from 'react-icons/hi';
import { X } from '@phosphor-icons/react';

import {
  BottomInfo,
  Container,
  InputWrapper,
  Message,
  SubmitButton,
} from './Reply.styles';
import AuthService from '../../../../../../../../services/auth.service';
import { ButtonLink } from '../../../../../../../../components/Button/Button';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';
import { UserViewModel } from '../../../../../../../../typings';

export const MAX_REPLIES_TO_SHOW_COUNTER = 2;
export const MAX_REPLIES_TO_SHOW_COLLAPSE = 3;

interface ReplyProps {
  data: {
    commentId: number;
    totalReplies?: number;
    user?: UserViewModel;
  };
  onSubmit: (commentId: number, message: string) => Promise<void>;
  onToggle: (open: boolean) => void;
}

const Reply: React.FC<ReplyProps> = ({
  data,
  onSubmit,
  onToggle,
}: ReplyProps) => {
  const [isReplying, setIsReplying] = useState(false);
  const [message, setMessage] = useState('');

  async function handleSubmit() {
    await onSubmit(data.commentId, message);
    setMessage('');
  }

  function handleCancel() {
    setIsReplying(false);
    setMessage('');
  }

  const isAuthenticated = useMemo(
    () => new AuthService().isAuthenticated(),
    []
  );

  useEffect(() => onToggle(isReplying), [isReplying]);

  return (
    <Container>
      {!isReplying && isAuthenticated && (
        <ButtonLink
          withUnderline={false}
          type="button"
          fontSize="14"
          fontWeight="600"
          color="#4D4DFF"
          marginLeft="32px"
          onClick={() => setIsReplying(true)}
        >
          <u>Reply</u>{' '}
          {(data.totalReplies || 0) >= MAX_REPLIES_TO_SHOW_COUNTER
            ? data.totalReplies && `(${data.totalReplies})`
            : ''}
        </ButtonLink>
      )}

      {isReplying && (
        <>
          {(data.totalReplies || 0) >= MAX_REPLIES_TO_SHOW_COLLAPSE && (
            <ButtonLink
              type="button"
              fontSize="12"
              color="#C8C9CF"
              onClick={() => setIsReplying(false)}
            >
              Collapse thread
            </ButtonLink>
          )}

          <InputWrapper>
            <Message
              placeholder="Type a reply here..."
              autoFocus
              value={message}
              // rows={3}
              maxLength={227}
              length={message.length}
              onChange={({ target }: any) => setMessage(target.value)}
            />

            <BottomInfo>
              <p>Replying to {data.user?.firstName}</p>

              <span>
                <Tooltip title="Cancel" placement="bottom" id="cancel-reply">
                  <X
                    color="#C8C9CF"
                    size={18}
                    cursor="pointer"
                    onClick={handleCancel}
                  />
                </Tooltip>

                <SubmitButton
                  type="button"
                  disabled={!message}
                  onClick={handleSubmit}
                >
                  <ArrowUpIcon size="14px" />
                </SubmitButton>
              </span>
            </BottomInfo>
          </InputWrapper>
        </>
      )}
    </Container>
  );
};

export default Reply;
