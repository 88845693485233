import styled from 'styled-components/macro';
import CloseIcon from '@material-ui/icons/Close';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: calc(100% - 87px);
  height: calc(100vh - 88px);
  position: absolute;
  left: 0;
  top: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  background: ${({ theme }) => theme.white};
  margin-left: 87px;
`;

export const Header = styled.header`
  position: absolute;
  top: -88px;
  width: 600px;
  height: 88px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -14px;

  @media (min-width: 1700px) {
    width: 800px;
  }
`;

export const CloseButton = styled(CloseIcon)`
  width: 32px !important;
  height: 32px !important;
  padding: 6px;
  background: ${({ theme }) => theme.gray2};
  color: ${({ theme }) => theme.gray3};
  border-radius: 8px;
  cursor: pointer;
`;

export const HeaderContent = styled.div`
  width: 600px;
  height: 64px;
  background: ${({ theme }) => theme.white};
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  @media (min-width: 1700px) {
    width: 800px;
  }
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;
  overflow: overlay;
  background: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-right: 14px;

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 5px;
  }
`;

export const Items = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1700px) {
    width: 800px;
  }
`;

export const Name = styled.p`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.gray3};
`;

export const Note = styled.p`
  display: flex;
  width: 556px;
  padding: 13px 14px 13px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.gray2};
  background: ${({ theme }) => theme.gray2};
  scroll-margin: 24px;

  @media (min-width: 1700px) {
    width: 756px;
  }
`;

export const Link = styled.a`
  width: 556px;
  /* min-height: 45px; */
  padding: 13px 14px 13px 14px;
  border: 1px solid ${({ theme }) => theme.gray2};
  background: ${({ theme }) => theme.gray2};
  scroll-margin: 24px;
  border-radius: 8px;
  word-wrap: break-word;

  /* text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */

  font-size: 14px;
  color: ${({ theme }) => theme.gray3} !important;

  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-decoration-line: underline !important;

  @media (min-width: 1700px) {
    width: 756px;
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  padding: 0px 26px;

  path {
    stroke-width: 1;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 140px;
    max-width: 200px;
    width: auto;
    color: ${({ theme }) => theme.gray3};
    padding: 16px;

    p {
      margin: 0;
      line-height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      max-width: 130px;
    }

    &:hover {
      background: ${({ theme }) => theme.gray2};

      svg {
        color: ${({ theme }) => theme.gray3} !important;
      }
    }
  }

  @media (max-width: 1250px) {
    button {
      min-width: 60px;

      p {
        display: none;
      }
    }
  }
`;
