import React from 'react';
import Loading from 'react-loading-overlay';

import { useApp } from '../../hooks/useMain';

function LoadingOverlay() {
  const {
    state: { loading, background },
  } = useApp();

  return <Loading active={background || loading} spinner />;
}

export default LoadingOverlay;
