import styled, { createGlobalStyle } from 'styled-components/macro';
import { FormControl as MaterialFormControl } from '@material-ui/core';

export const Container = styled(MaterialFormControl)`
  position: relative;
  min-width: 32px !important;
  height: 32px;
  text-align: left;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.white};

    .MuiSelect-icon {
      opacity: 1;
    }
  }

  .MuiInput-root {
    width: 32px !important;
    height: 32px !important;
    border-bottom: 0 !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
  }

  .MuiInput-underline:before {
    border-bottom: 0 !important;
  }

  .MuiInput-underline:after {
    border-bottom: 0 !important;
  }

  .MuiSelect-select:focus {
    background-color: transparent !important;
    border-radius: 8px;
  }

  .MuiSelect-selectMenu {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: ${({ theme }: any) => theme.black} !important;

    .help-text {
      display: none;
    }
  }

  .MuiInput-input {
    height: 32px;
    width: 32px;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    background: transparent;
    border-radius: 8px;
    padding-right: 0px !important;

    svg {
      display: none;
    }
  }

  .MuiSelect-icon {
    margin-right: 0;
    margin-top: -4px;
    width: 32px;
    height: 32px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

export const MenuItemWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: ${({ color }: any) => color} !important;
`;

export const GlobalStyle = createGlobalStyle`
  .column-options {
    .MuiListItem-root {
      width: 210px !important;
      column-gap: 12px !important;
      border-radius: 4px !important;
    }

    .MuiList-padding {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .MuiListItem-placeholder {
      display: none !important;
    }

    .MuiPaper-rounded {
      border-radius: 12px !important;
      box-shadow: 0px 8px 8px -8px rgba(127, 128, 135, 0.20) !important;
      border: 1px solid ${({ theme }: any) => theme.gray} !important;
      margin-top: 15px !important;
      margin-left: -170px !important;
    }

    .MuiListItem-button {
      color: ${({ theme }: any) => theme.gray3} !important;
      border-bottom: 1px solid ${({ theme }: any) => theme.gray} !important;
      padding: 12px 20px 12px 16px !important;
      justify-content: space-between !important;
    }
  }
`;
