import styled from 'styled-components/macro';
import {
  Popover as BootstrapPopover,
  PopoverTitle as BootstrapPopoverTitle,
} from 'react-bootstrap';

export const Popover = styled(BootstrapPopover)`
  border-radius: 12px;

  .popover-header {
    font-weight: 400;
    font-size: 14px;
    border-radius: 12px;
    padding: 16px;
  }

  .popover-body {
    padding: 16px;
  }

  .arrow {
    &:after {
      border-bottom-color: #f7f7f7 !important;
    }
  }
`;

export const PopoverTitle = styled(BootstrapPopoverTitle)`
  text-align: center;
`;

export const ActionButtons = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.gray3};
  margin: 0;
`;
