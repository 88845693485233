import React from 'react';

const Pencil = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d="M5.00249 13.9142H1.57178C1.42014 13.9142 1.2747 13.854 1.16747 13.7467C1.06024 13.6395 1 13.4941 1 13.3424V10.1486C1 10.0735 1.01479 9.99912 1.04352 9.92975C1.07226 9.86038 1.11438 9.79734 1.16747 9.74425L9.74425 1.16747C9.85148 1.06024 9.99691 1 10.1486 1C10.3002 1 10.4456 1.06024 10.5529 1.16747L13.7467 4.36134C13.854 4.46857 13.9142 4.61401 13.9142 4.76565C13.9142 4.9173 13.854 5.06274 13.7467 5.16997L5.00249 13.9142Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 2L13 8"
      stroke="#7F8087"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5793 13.9142H5.00255L1.03643 9.94812"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pencil;
