import React from 'react';

import { Button, ButtonLink } from '../../../../components/Button/Button';
import { Content, Header, Form } from '../Create/Create.styles';
import Spacer from '../../../../components/Spacer/Spacer';
import Google from '../../../../components/Icon/icons/Google';
import { GoogleSignInState } from '../../../../typings';

interface InitialProps {
  onSuccess: () => void;
  welcomePro: boolean;
}

const InitialForm = ({ onSuccess, welcomePro }: InitialProps) => (
  <Content height="165px">
    <Header>
      {welcomePro ? (
        <p style={{ textAlign: 'center' }}>
          Welcome to Viewport Pro!
          <br />
          Create your account below to continue
        </p>
      ) : (
        <p style={{ textAlign: 'center' }}>Create your free Viewport account</p>
      )}

      {welcomePro && (
        <small
          style={{ textAlign: 'center', fontSize: '14px', marginTop: '8px' }}
        >
          Please use the same email you used for checkout
        </small>
      )}
    </Header>

    <Form noValidate gap="20px">
      <a
        href={`/api/users/sign-in/google?state=${GoogleSignInState.SIGN_UP_NEW_USER}`}
      >
        <Button
          type="button"
          width="360px"
          height="54px"
          fontSize="16"
          fontWeight="500"
          alignSelf="center"
          background="#F1F3F5"
          backgroundOverlay="#E3E6EC"
          borderColor="#E3E6EC"
          borderSize="1px"
          color="#000"
          marginTop="0"
          borderRadius="12px"
        >
          <Google /> <Spacer width={15} /> Continue with Google
        </Button>
      </a>

      <ButtonLink
        type="button"
        withUnderline
        color="#7F8087"
        fontSize="16"
        onClick={onSuccess}
      >
        Continue with email
      </ButtonLink>
    </Form>
  </Content>
);

export default InitialForm;
