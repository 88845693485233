/* eslint-disable jsx-a11y/label-has-associated-control */
import { Accordion } from 'react-bootstrap';
import { useMemo, useState } from 'react';
import { Board } from '../../../../typings';

import {
  AccordionWrapper,
  Image,
  Header,
  Name,
  Creator,
  Content,
  MainSection,
  LinksSection,
  Footer,
  ItemInfo,
  Icon,
  HeaderContent,
  Section,
  Placeholder,
} from './InfosCollapse.styles';
import DateUtils from '../../../../utils/date.utils';
import { Button, ButtonOutline } from '../../../../components/Button/Button';
import StringUtils from '../../../../utils/string.utils';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import UserService from '../../../../services/user.service';

interface InfoCollapseProps {
  data: Board;
  disabled?: boolean;
  show: boolean;
  viewOnly: boolean;
  showingDetails: boolean;
  onEdit: () => void;
  onShare: () => void;
}

const InfosCollapse: React.FC<InfoCollapseProps> = ({
  data,
  disabled = false,
  show = true,
  viewOnly,
  showingDetails,
  onEdit,
  onShare,
}: InfoCollapseProps) => {
  const [isOpen, toggleOpen] = useState(false);

  const isOwner = useMemo((): boolean => {
    const userInfo = new UserService().get();
    return data.user?.email === userInfo?.email;
  }, [data.user]);

  return (
    <>
      <Placeholder />

      {show && (
        <AccordionWrapper isOpen={isOpen} showingDetails={showingDetails}>
          <Tooltip
            title="Project info"
            id="project-details"
            placement="bottom"
            show={isOpen ? false : undefined}
          >
            <Accordion.Toggle
              onClick={() => !disabled && toggleOpen((current) => !current)}
              eventKey="0"
              id="accordion-toggle"
            >
              <Header>
                <HeaderContent>
                  <Image src={data.image} />
                  <Section>
                    <Name>{data.name}</Name>
                    <Creator>{`${String(data.user?.firstName)} ${String(
                      data.user?.lastName
                    )}`}</Creator>
                  </Section>
                </HeaderContent>

                <Icon open={isOpen} />
              </Header>
            </Accordion.Toggle>
          </Tooltip>

          {!disabled && (
            <Accordion.Collapse eventKey="0">
              <Content>
                <MainSection>
                  {Boolean(data.description) && (
                    <ItemInfo>
                      <label>Project Info</label>
                      <p>{data.description}</p>
                    </ItemInfo>
                  )}

                  <ItemInfo>
                    <label>Project Status</label>
                    <p>{new StringUtils(data.status).capitalize()}</p>
                  </ItemInfo>

                  <ItemInfo>
                    <label>Project created</label>
                    <p>{new DateUtils(data.createdAt).fromNowDay()}</p>
                  </ItemInfo>

                  <ItemInfo>
                    <label>Project updated</label>
                    <p>{new DateUtils(data.updatedAt).fromNowDay()}</p>
                  </ItemInfo>
                </MainSection>

                {/* <LinksSection></LinksSection> */}
                {isOwner && (
                  <Footer>
                    <ButtonOutline
                      type="button"
                      marginTop="0"
                      height="46px"
                      width="100%"
                      color="#7F8087"
                      borderSize="1px"
                      onClick={onEdit}
                    >
                      {viewOnly ? 'Edit details' : 'Edit project details'}
                    </ButtonOutline>
                  </Footer>
                )}
              </Content>
            </Accordion.Collapse>
          )}
        </AccordionWrapper>
      )}
    </>
  );
};

export default InfosCollapse;
