import React from 'react';

const Grid = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="12.75" width="8.25" height="8.25" rx="2" fill="#7F8087" />
    <rect x="3" y="3" width="8.25" height="8.25" rx="2" fill="#7F8087" />
    <rect x="12.75" y="3" width="8.25" height="8.25" rx="2" fill="#7F8087" />
    <rect
      x="12.75"
      y="12.75"
      width="8.25"
      height="8.25"
      rx="2"
      fill="#7F8087"
    />
  </svg>
);

export default Grid;
