import styled from 'styled-components/macro';
import { Popover as BootstrapPopover } from 'react-bootstrap';

export const AvatarWrapper = styled.div`
  position: relative;
`;

interface AvatarProps {
  active: boolean;
  isProUser: boolean;
}

export const Avatar = styled.img<AvatarProps>`
  max-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;

  border: ${({ isProUser, theme }: any) =>
    isProUser
      ? `2px solid ${theme.blue as string}`
      : `1px solid ${theme.white as string}`};
  box-shadow: ${({ active, isProUser, theme }) =>
    active && !isProUser
      ? `0px 0px 0px 2px ${theme.blue as string}`
      : 'transparent'};
`;

export const Popover = styled(BootstrapPopover)`
  margin-top: 4px !important;
  border-color: ${({ theme }: any) => theme.gray1};
  border-radius: 12px;

  .arrow {
    display: none;
  }
`;

export const NavigationsItems = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;

  hr {
    margin-bottom: 10px;
  }
`;

interface NavigatonItemProps {
  secondary?: boolean;
}

export const NavigatonItem = styled.li<NavigatonItemProps>`
  height: 40px;
  width: 199px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 12px 4px 12px 12px;
  margin: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme }: any) => theme.black};

  display: flex;
  align-items: center;
  gap: 14px;

  a {
    width: 100%;
    color: ${({ theme }: any) => theme.black} !important;
  }

  &:hover {
    background: ${({ theme, secondary }: any) =>
      secondary ? 'rgba(241, 243, 245, 0.5)' : theme.gray2};
  }
`;

export const ComminSoon = styled.div`
  width: 90px;
  height: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #d9f7fd;
  color: #00caf2;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const Badge = styled.div`
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  width: 30px;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 10px;
  background: ${({ theme }) => theme.blue};
  border-radius: 5px;

  font-weight: 600;
  font-size: 8px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.white};
`;
