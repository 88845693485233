import { useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { OverlayTrigger } from 'react-bootstrap';
import {
  House,
  Gear,
  SealCheck,
  UserCirclePlus,
  SignOut,
  Kanban,
  DownloadSimple,
  ListChecks,
  Chats,
} from '@phosphor-icons/react';

import ROUTES from '../../../../routes';
import {
  Avatar,
  Popover,
  NavigationsItems,
  NavigatonItem,
  AvatarWrapper,
  Badge,
} from './Navigation.styles';
import AuthService from '../../../../services/auth.service';
import UserService from '../../../../services/user.service';
import { useAvatarImage } from '../../../../hooks/useAvatarImage';
import { UserType } from '../../../../typings';
import { useApp } from '../../../../hooks/useMain';
import DownloadAppModal from './components/DownloadAppModal/DownloadAppModal';

const NavigationOptions = () => {
  const { setUpgradeToProModal, isProUser } = useApp();
  const navigate = useNavigate();
  const [isMenuOepn, setIsMenuOpen] = useState(false);
  const [isDownloadAppModalOpen, setIsDownloadAppModalOpen] = useState(false);
  const { getAvatarMemo } = useAvatarImage();
  const userInfo = new UserService().get();

  function logout(): void {
    new AuthService().logout();

    navigate(ROUTES.SIGN_IN);
  }

  function handleUpgradeToProModal() {
    setUpgradeToProModal({
      title: 'Upgrade to Pro membership',
      subtitle: 'Unlock unlimited uploads and early access to new features',
    });
    setIsMenuOpen(false);
  }

  function handleDownloadAppModal() {
    setIsDownloadAppModalOpen(true);
    setIsMenuOpen(false);
  }

  return (
    <>
      <OverlayTrigger
        rootClose
        placement="bottom-end"
        trigger={['click']}
        show={isMenuOepn}
        onToggle={(val) => {
          setIsMenuOpen(val);
        }}
        overlay={
          <Popover id="menu">
            <NavigationsItems>
              <NavLink
                className={({ isActive }) =>
                  `${isActive ? 'navbar-selected' : ''}`
                }
                to={ROUTES.DASHBOARD}
              >
                <NavigatonItem>
                  <House color="#7F8087" weight="fill" size={18} />
                  Dashboard
                </NavigatonItem>
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  `${isActive ? 'navbar-selected' : ''}`
                }
                to={ROUTES.SETTINGS}
              >
                <NavigatonItem>
                  <Gear color="#7F8087" weight="fill" size={18} />
                  Settings
                </NavigatonItem>
              </NavLink>

              {!isProUser && (
                <NavigatonItem onClick={handleUpgradeToProModal}>
                  <SealCheck color="#7F8087" weight="fill" size={18} />
                  Go Pro
                </NavigatonItem>
              )}

              {/* <NavigatonItem>
              <a href="mailto:hello@viewport.co">Contact us</a>
            </NavigatonItem> */}

              <NavLink
                className={({ isActive }) =>
                  `${isActive ? 'navbar-selected' : ''}`
                }
                to={ROUTES.SETTINGS_INVITES}
              >
                <NavigatonItem>
                  <UserCirclePlus color="#7F8087" weight="fill" size={18} />
                  Invite someone
                </NavigatonItem>
              </NavLink>

              <a href="https://viewport.co/start">
                <NavigatonItem>
                  <ListChecks color="#7F8087" weight="fill" size={18} />
                  Getting started
                </NavigatonItem>
              </a>

              <NavigatonItem onClick={handleDownloadAppModal}>
                <DownloadSimple color="#7F8087" weight="fill" size={18} />
                Screenshot App
              </NavigatonItem>

              <a href="mailto:hello@viewport.co">
                <NavigatonItem>
                  <Chats color="#7F8087" weight="fill" size={18} />
                  Contact
                </NavigatonItem>
              </a>

              {/* <NavigatonItem>
                <NavLink
                  activeClassName="navbar-selected"
                  to={ROUTES.PROJECT_VIEW('1')}
                >
                  <Kanban color="#7F8087" weight="fill" />
                  Demo project
                </NavLink>
              </NavigatonItem> */}

              <hr />

              <NavigatonItem onClick={logout} secondary>
                <SignOut color="#7F8087" weight="fill" />
                Log out
              </NavigatonItem>
            </NavigationsItems>
          </Popover>
        }
      >
        <AvatarWrapper>
          <Avatar
            src={getAvatarMemo(userInfo?.picture, userInfo?.email)}
            active={isMenuOepn}
            isProUser={isProUser}
          />

          {isProUser && <Badge>PRO</Badge>}
        </AvatarWrapper>
      </OverlayTrigger>

      <DownloadAppModal
        open={isDownloadAppModalOpen}
        onClose={() => setIsDownloadAppModalOpen(false)}
      />
    </>
  );
};

export default NavigationOptions;
