import { useState } from 'react';
import { OverlayTrigger, PopoverContent } from 'react-bootstrap';
import { Button, ButtonOutline } from '../Button/Button';
import {
  Popover,
  PopoverTitle,
  ActionButtons,
  Description,
} from './DeletePopover.styles';

interface DeletePopoverProps {
  title: string;
  subtitle?: string;
  confirmButtonText?: string;
  children: React.ReactElement;
  id?: string;
  onConfirm: () => void;
}

const DeletePopover = ({
  title,
  subtitle,
  confirmButtonText,
  id,
  children,
  onConfirm,
}: DeletePopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <OverlayTrigger
      placement="bottom"
      trigger="click"
      show={isOpen}
      onToggle={(val: boolean) => setIsOpen(val)}
      rootClose
      overlay={
        <Popover id={id}>
          <PopoverTitle dangerouslySetInnerHTML={{ __html: title }} />
          <PopoverContent>
            {Boolean(subtitle) && <Description>{subtitle}</Description>}

            <ActionButtons>
              <ButtonOutline
                type="button"
                width="126px"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </ButtonOutline>

              <Button
                type="button"
                width="126px"
                background="#FF5656"
                onClick={() => {
                  onConfirm();
                  setIsOpen(false);
                }}
              >
                {confirmButtonText || 'Remove'}
              </Button>
            </ActionButtons>
          </PopoverContent>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default DeletePopover;
