import React from 'react';

interface ProjectStatusActiveProps {
  className?: string;
}

const ProjectStatusActive = ({ className }: ProjectStatusActiveProps) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1L7 6L11 7.5L4 15L5 10L1 8.5L8 1Z"
      stroke="#7F8087"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProjectStatusActive;
