import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import MoonLoader from 'react-spinners/MoonLoader';
import orderBy from 'lodash.orderby';

import { Link } from 'react-router-dom';
import {
  ModalContainer,
  Title,
  Content,
  EmptyState,
  List,
  Item,
  Image,
  Details,
  Name,
  LoaderWrapper,
  CreateButton,
  Checkbox,
} from './SetFavoriteModal.styles';
import { BoardSimplified, TeamSimplifiedViewModel } from '../../../../typings';
import BoardClient from '../../../../clients/board.client';
import { useApp } from '../../../../hooks/useMain';
import { Button, ButtonOutline } from '../../../Button/Button';
import { Column } from '../../../Column/Column';
import DateUtils from '../../../../utils/date.utils';
import ROUTES from '../../../../routes';
import TeamClient from '../../../../clients/team.client';
import FavoriteClient from '../../../../clients/favorite.client';

type Favorite = TeamSimplifiedViewModel | BoardSimplified;

interface SetFavoriteModalProps {
  open: boolean;
  favorites: Array<Favorite>;
  onClose: () => void;
}

const SetFavoriteModal: React.FC<SetFavoriteModalProps> = ({
  open,
  favorites,
  onClose,
}: SetFavoriteModalProps) => {
  const { setErrorMessage } = useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patching, setPatching] = useState<string>();
  const [boards, setBoards] = useState<BoardSimplified[]>([]);
  const [teams, setTeams] = useState<TeamSimplifiedViewModel[]>([]);
  const [selecteds, setSelecteds] = useState<string[]>([]);
  const [updated, setUpdated] = useState(false);

  function handleClose() {
    onClose();
    setBoards([]);
    setTeams([]);
    setSelecteds([]);
    setUpdated(false);
  }

  function isTeam(item: Favorite): boolean {
    return 'members' in item && 'boards' in item;
  }

  function getKey(item: Favorite): string {
    const isTeamSelected = isTeam(item);
    const result = `${isTeamSelected ? 'team' : 'board'}-${item.id}`;

    return result;
  }

  function handleSelection(item: Favorite) {
    // const isAlreadyFavorited = favorites.some((item) => item.id === id);
    // if (isAlreadyFavorited) return;

    const key = getKey(item);
    const isAlreadySelected = selecteds.some((selected) => selected === key);

    if (isAlreadySelected) {
      const withoutSelected = selecteds.filter((selected) => selected !== key);
      setSelecteds(withoutSelected);
    } else {
      setSelecteds([...selecteds, key]);
    }
  }

  function isIncluded(item: Favorite): boolean {
    const key = getKey(item);
    return selecteds.some((selected) => selected === key);
  }

  async function fetchData() {
    try {
      setIsLoading(true);

      const [boardsSimplified, teamsSimplified] = await Promise.all([
        new BoardClient().getAllSimplified(),
        new TeamClient().getAllSimplified(),
      ]);

      setBoards(boardsSimplified);
      setTeams(teamsSimplified);
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while fetching the projects'
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function performFavorite(item: Favorite) {
    try {
      const key = getKey(item);
      setPatching(key);

      const isAlreadyFavorited = isIncluded(item);
      const isTeamSelected = isTeam(item);

      if (isAlreadyFavorited) {
        if (isTeamSelected) {
          await new FavoriteClient().removeTeam(item.id);
        } else {
          await new FavoriteClient().removeBoard(item.id);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isTeamSelected) {
          await new FavoriteClient().addTeam(item.id);
        } else {
          await new FavoriteClient().addBoard(item.id);
        }
      }

      handleSelection(item);
      setUpdated(true);
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while pinning to sidebar'
      );
    } finally {
      setPatching(undefined);
    }
  }

  const dataOrderedByDate = useMemo(() => {
    const result = orderBy([...boards, ...teams], ['updatedAt'], ['desc']);

    return result;
  }, [boards, teams]);

  useEffect(() => {
    if (open === true) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (favorites.length) {
      setSelecteds(favorites?.map((item) => getKey(item)));
    }
  }, [favorites]);

  return (
    <ModalContainer
      show={open}
      size="md"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Title>
          Add or remove projects and
          <br />
          groups from your sidebar
        </Title>
      </Modal.Header>

      <Modal.Body>
        <Content>
          {isLoading && (
            <LoaderWrapper>
              <MoonLoader color="#7F8087" loading />
            </LoaderWrapper>
          )}

          {!isLoading && (
            <List>
              {dataOrderedByDate?.map((item) => (
                <Item
                  key={getKey(item)}
                  checked={isIncluded(item)}
                  onClick={() => performFavorite(item)}
                  disabled={Boolean(patching)}
                >
                  <Image src={item.image} />

                  <Column gap="4px" width="290px">
                    <Name>{item.name}</Name>

                    <Details>
                      {isTeam(item) ? 'Group' : 'Project'} • Edited{' '}
                      {new DateUtils(item.updatedAt)
                        .fromNowDay()
                        ?.toLowerCase()}
                    </Details>
                  </Column>

                  <Checkbox
                    loading={patching === getKey(item)}
                    checked={isIncluded(item)}
                    onChange={() => {}}
                    sizing="20px"
                    name={String(item.id)}
                  />
                </Item>
              ))}

              {!dataOrderedByDate.length && (
                <EmptyState>
                  <p>
                    No projects <br />
                    or collections yet
                  </p>
                  <Link to={ROUTES.PROJECT_NEW}>
                    <CreateButton>Create a project</CreateButton>
                  </Link>
                </EmptyState>
              )}
            </List>
          )}
        </Content>
      </Modal.Body>

      <Modal.Footer>
        <ButtonOutline
          type="button"
          width="106px"
          height="48px"
          fontSize="16"
          borderRadius="12px"
          borderSize="1px"
          marginTop="0"
          color="#7F8087"
          onClick={handleClose}
        >
          Cancel
        </ButtonOutline>

        <Button
          type="button"
          width="165px"
          height="48px"
          marginTop="0"
          fontSize="16"
          disabled={!updated}
          onClick={handleClose}
        >
          Save selection
        </Button>
      </Modal.Footer>
    </ModalContainer>
  );
};

export default SetFavoriteModal;
