import React from 'react';
import { Plus } from '@phosphor-icons/react';

import { Container, Header, Content } from './AddColumn.styles';
import Spacer from '../../../../components/Spacer/Spacer';

interface AddColumnProps {
  onClick: () => void;
}

const AddColumn: React.FC<AddColumnProps> = ({ onClick }: AddColumnProps) => (
  <Container>
    <Header onClick={onClick}>
      <Plus size="24px" color="#C8C9CF" weight="bold" />
    </Header>

    <Content onClick={onClick}>
      <Plus size="24px" color="#C8C9CF" weight="bold" />
      <Spacer height={42 + 20} />
    </Content>
  </Container>
);

export default AddColumn;
