import React from 'react';

const LeftArrow = () => (
  <svg
    width="718"
    height="30"
    viewBox="0 0 718 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 15C3 21.6274 8.37258 27 15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15ZM17.394 8.73304C17.9798 9.31882 17.9798 10.2686 17.394 10.8544L13.2484 15L17.394 19.1456C17.9798 19.7314 17.9798 20.6811 17.394 21.2669C16.8082 21.8527 15.8585 21.8527 15.2727 21.2669L10.1819 16.1762C9.85961 15.8539 9.71463 15.4214 9.74699 15C9.71463 14.5786 9.85961 14.1461 10.1819 13.8238L15.2727 8.73304C15.8585 8.14725 16.8082 8.14725 17.394 8.73304Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default LeftArrow;
