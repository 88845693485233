import { Navigate, useParams } from 'react-router-dom';
import ROUTES from '../../routes';
import AuthService from '../../services/auth.service';

interface RouteProps {
  children: React.ReactElement;
}

export const PrivateRoute = ({ children }: RouteProps) =>
  new AuthService().isAuthenticated() ? (
    children
  ) : (
    <Navigate to={ROUTES.SIGN_IN} />
  );

export const PublicRoute = ({ children }: RouteProps) =>
  new AuthService().isAuthenticated() ? (
    <Navigate to={ROUTES.DASHBOARD} />
  ) : (
    children
  );

interface RouteValidationProps extends RouteProps {
  validation: (params: any) => boolean;
}

export const ValidateRoute = ({
  children,
  validation,
}: RouteValidationProps) => {
  const params = useParams();
  return validation(params) ? children : <Navigate to={ROUTES.SIGN_IN} />;
};
