import {
  Container,
  HideMobileProtection,
  IframeMockup,
} from './EmbededGifCreator.styles';
import GifCreator from './GifCreator/GifCreator';

const EmbededGifCreator = () => (
  <Container>
    <HideMobileProtection />

    <IframeMockup>
      <GifCreator />
    </IframeMockup>
  </Container>
);

export default EmbededGifCreator;
