import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import JuneService from '../../services/june.service';

interface RouteChangeHandlerProps {
  children: React.ReactElement;
}

export const RouteChangeHandler = ({ children }: RouteChangeHandlerProps) => {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      new JuneService().init().page();
    }
  }, [location]);

  return children;
};
