import moment from 'moment';

const DATE = 'MM/DD/YYYY';
const DATE_TIME = 'MM/DD/YYYY HH:mm';

class DateUtils {
  date: any;

  constructor(date: any) {
    this.date = date;
  }

  toView(isDateSimple = false): string {
    const setFormat = isDateSimple ? DATE : DATE_TIME;

    const formatedDate = moment(this.date).format(setFormat);

    return formatedDate.replace('.', '').toLowerCase();
  }

  fromNow(): string {
    return moment(this.date).fromNow();
  }

  fromNowDay(descritive = false): string {
    const now = moment().startOf('day');
    const target = moment(this.date).startOf('day');
    const diff = now.diff(target, 'days');

    if (diff === 0) {
      return 'Today';
    }

    if (diff <= 7) {
      return `${diff}d ago`;
    }

    if (descritive) {
      return moment(this.date).format('MMM D, YYYY');
    }

    return moment(this.date).format('MM/DD/YY');
  }

  diffInDays(date: Date): number {
    return moment(date).diff(moment(this.date), 'days');
  }

  addDays(days: number): Date {
    return moment(this.date).add(days, 'days').toDate();
  }
}

export default DateUtils;
