import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { MdOutlineHelp as HelpIcon } from 'react-icons/md';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Container, GlobalStyle } from './DropdownSelect.styles';
import Tooltip from '../Tooltip/Tooltip';

interface DropdownSelectProps {
  label?: string;
  value: string;
  items: {
    name: string;
    help?: string;
    icon?: React.ReactElement;
  }[];
  width?: number;
  height?: number;
  className?: string;
  hideChevronAfterSelect?: boolean;
  optionsAtBottom?: boolean;
  onChange: (value: string, e?: any) => void;
}

const DropdownSelect = ({
  label,
  value,
  items,
  width,
  height,
  className,
  optionsAtBottom = false,
  hideChevronAfterSelect = true,
  onChange,
}: DropdownSelectProps) => (
  <Container
    width={width}
    height={height}
    className={className}
    hideChevron={hideChevronAfterSelect && Boolean(value)}
  >
    <GlobalStyle optionsAtBottom={optionsAtBottom} />
    <Select
      displayEmpty
      value={value}
      onChange={(e) => onChange(String(e.target.value), e)}
      IconComponent={ExpandMoreIcon}
      MenuProps={
        optionsAtBottom
          ? {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              marginThreshold: 50,
            }
          : {}
      }
    >
      {Boolean(label) && (
        <MenuItem value="" className="MuiListItem-placeholder" disabled>
          {label}
        </MenuItem>
      )}

      {items.map((item: any) => (
        <MenuItem key={item.name} value={item.name}>
          {Boolean(item.icon) && item.icon} {item.name}{' '}
          {Boolean(item.help) && (
            <Tooltip title={item.help} id={item.name}>
              <HelpIcon color="#C8C9CF" className="help-text" />
            </Tooltip>
          )}
        </MenuItem>
      ))}
    </Select>
  </Container>
);

export default DropdownSelect;
