import React, { useLayoutEffect, useMemo, useRef } from 'react';

import Comment from '../Comment/Comment';

import {
  Close,
  Container,
  Content,
  Header,
  LockMainContent,
  Title,
} from './CommentsPanel.styles';
import { BoardComment } from '../../../../../../../../typings';

interface CommentsProps {
  data: {
    containerId: string;
    comments: BoardComment[];
  };
  open: boolean;
  currentDrawing?: string;
  onDelete: (comment: BoardComment, reply?: BoardComment) => void;
  onSetDrawing: (value: string) => void;
  onRemoveDrawing: (value: string) => void;
  onClose: () => void;
  onReply: (commentId: number, message: string) => Promise<void>;
}

const CommentsPanel: React.FC<CommentsProps> = ({
  data,
  open,
  currentDrawing,
  onDelete,
  onRemoveDrawing,
  onSetDrawing,
  onClose,
  onReply,
}: CommentsProps) => {
  const closeButtonRef = useRef(null);

  function highlightMarker(position: number, enable: boolean) {
    const $container = document.getElementById(data.containerId);
    const $markers = $container?.querySelectorAll('.image-marker__marker');

    $markers?.forEach((item, index) => {
      if (position === index) {
        if (enable) {
          item.classList.add('hightlight');
        } else {
          item.classList.remove('hightlight');
        }
      }

      if (position !== index) {
        if (enable) {
          item.classList.add('disabled');
        } else {
          item.classList.remove('disabled');
        }
      }
    });
  }

  function closeOtherPanelsOpened() {
    const commentPanels = document?.querySelectorAll('#close-comments-panel');

    if (commentPanels.length > 1) {
      commentPanels.forEach((item) => {
        if (item !== closeButtonRef.current) {
          item.dispatchEvent(new MouseEvent('click', { bubbles: true }));
        }
      });
    }
  }

  useLayoutEffect(() => {
    if (open) {
      closeOtherPanelsOpened();
    }
  }, [open, closeButtonRef]);

  if (open && !data.comments.length) return null;

  return (
    <Container open={open}>
      <Header>
        <Title>Comments</Title>

        {Boolean(data.comments.length) && (
          <>
            <LockMainContent />
            <Close
              onClick={onClose}
              ref={closeButtonRef}
              id="close-comments-panel"
            />
          </>
        )}
      </Header>

      <Content>
        {data.comments?.map((item, index) => (
          <span
            key={index}
            onMouseEnter={() => highlightMarker(index, true)}
            onMouseLeave={() => highlightMarker(index, false)}
          >
            <Comment
              index={index + 1}
              data={item}
              currentDrawing={currentDrawing}
              onDelete={onDelete}
              onRemoveDrawing={onRemoveDrawing}
              onSetDrawing={onSetDrawing}
              onReply={onReply}
            />
          </span>
        ))}
      </Content>
    </Container>
  );
};

export default CommentsPanel;
