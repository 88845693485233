/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { forwardRef } from 'react';
import FileUtils from '../../utils/file.utils';
import {
  Container,
  LazyImageLoader,
  Marker,
  Video,
} from './ImageMarker.styles';
import { calculateMarkerPosition } from './ImageMarker.utils';
import ImageUtils from '../../utils/image.utils';

const DEFAULT_BUFFER = 12;

export interface Marker {
  top: number;
  left: number;
}

export interface MarkerComponentProps {
  top: number;
  left: number;
  itemNumber: number;
}

type Props = {
  src: string;
  markers: Array<Marker>;
  onAddMarker?: (marker: Marker) => void;
  markerComponent?: React.FC<MarkerComponentProps>;
  bufferLeft?: number;
  bufferTop?: number;
  alt?: string;
  extraClass?: string;
  onLoad?: () => void;
};
const ImageMarker = forwardRef<HTMLImageElement, Props>(
  (
    {
      src,
      markers,
      onAddMarker,
      onLoad,
      markerComponent: MarkerComponent,
      bufferLeft = DEFAULT_BUFFER,
      bufferTop = DEFAULT_BUFFER,
      alt = 'Markers',
      extraClass = '',
    }: Props,
    ref
  ) => {
    const localRef = React.useRef<HTMLImageElement | null>(null);
    const imageRef = ref || localRef;

    const handleImageClick = (event: React.MouseEvent) => {
      if (!(imageRef as any).current || !onAddMarker) return;

      const imageDimentions = (
        imageRef as any
      ).current!.getBoundingClientRect();

      const [top, left] = calculateMarkerPosition(
        event,
        imageDimentions,
        window.scrollY,
        bufferLeft,
        bufferTop
      );

      onAddMarker({
        top,
        left,
      });
    };

    const getItemPosition = (marker: Marker) => ({
      top: `${marker.top}%`,
      left: `${marker.left}%`,
    });

    if (!src) return null;

    return (
      <Container className="image-marker" ref={imageRef}>
        {new FileUtils(src).isVideo() ? (
          <Video
            autoPlay={false}
            controls
            loop
            muted
            controlsList="play nodownload nofullscreen noplaybackrate"
            disablePictureInPicture
            src={src}
            onClick={handleImageClick}
            className={`image-marker__image ${extraClass}`}
          />
        ) : (
          <LazyImageLoader
            src={new ImageUtils(src).getMedium()}
            placeholderSrc={new ImageUtils(src).getSmall()}
            alt={alt}
            effect="blur"
            onClick={handleImageClick}
            afterLoad={onLoad}
            height="100%"
            className={`image-marker__image ${extraClass}`}
          />
        )}

        {markers.map((marker, itemNumber) => (
          <Marker
            className={`image-marker__marker ${
              !MarkerComponent && 'image-marker__marker--default'
            }`}
            style={getItemPosition(marker)}
            key={itemNumber}
            data-testid="marker"
          >
            {MarkerComponent ? (
              <MarkerComponent {...marker} itemNumber={itemNumber} />
            ) : (
              itemNumber + 1
            )}
          </Marker>
        ))}
      </Container>
    );
  }
);

export default ImageMarker;
