import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MoonLoader from 'react-spinners/MoonLoader';

import {
  ModalContainer,
  Title,
  SubTitle,
  CloseButton,
  Content,
  Logo,
  TextField,
  Row,
  InputGroup,
  SpinnerWrapper,
} from './RequestAccessModal.styles';
import { Button, ButtonLink } from '../Button/Button';
import { useApp } from '../../hooks/useMain';
import UserClient from '../../clients/user.client';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
});

interface RequestAccessModalProps {
  open: boolean;
  onClose: () => void;
}

const RequestAccessModal: React.FC<RequestAccessModalProps> = ({
  open,
  onClose,
}: RequestAccessModalProps) => {
  const { setErrorMessage } = useApp();
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function performSubmit(
    firstName: string,
    lastName: string,
    email: string
  ) {
    try {
      setIsLoading(true);

      await new UserClient().saveContact(firstName, lastName, email);

      setSubmitted(true);
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while saving the data'
      );
    } finally {
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      performSubmit(values.firstName, values.lastName, values.email);
    },
  });

  function handleClose() {
    formik.resetForm();
    setSubmitted(false);
    onClose();
  }

  return (
    <ModalContainer
      show={open}
      size="md"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <CloseButton onClick={handleClose} />
        <Logo src="/images/logo.png" alt="Viewport logo" />

        <Title>
          {submitted
            ? 'Success! Thank you for signing up. We’ll be reaching out soon!'
            : 'Request early access to Viewport'}
        </Title>

        {submitted ? (
          <ButtonLink
            type="button"
            fontSize="14"
            alignSelf="center"
            marginTop="32px"
          >
            <a
              href="https://twitter.com/ViewportHQ"
              target="_blank"
              rel="noreferrer"
            >
              Stay up to date on Twitter
            </a>
          </ButtonLink>
        ) : (
          <SubTitle>
            We’re building the best space for creatives
            <br /> to share in-progress projects
          </SubTitle>
        )}
      </Modal.Header>

      <SpinnerWrapper>
        <MoonLoader color="#7F8087" loading={isLoading} />
      </SpinnerWrapper>

      {!submitted && !isLoading && (
        <>
          <Modal.Body>
            <Content noValidate onSubmit={formik.handleSubmit}>
              <Row>
                <span>
                  <TextField
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    error={
                      formik.touched.firstName ? formik.errors.firstName : ''
                    }
                  />
                </span>

                <span>
                  <TextField
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    error={
                      formik.touched.lastName ? formik.errors.lastName : ''
                    }
                  />
                </span>
              </Row>

              <InputGroup>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email ? formik.errors.email : ''}
                />
              </InputGroup>
            </Content>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="button"
              width="130px"
              height="50px"
              borderRadius="12px"
              fontSize="18"
              marginTop="0"
              disabled={!formik.isValid}
              onClick={formik.submitForm}
            >
              Submit
            </Button>
          </Modal.Footer>
        </>
      )}
    </ModalContainer>
  );
};

export default RequestAccessModal;
