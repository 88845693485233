import { useEffect, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

import {
  BoardComment,
  BoardItem,
  PostMetadataType,
  ProjectPostCommentMetadata,
  ReducerAction,
} from '../../../../../../../../typings';
import UserService from '../../../../../../../../services/user.service';
import AuthService from '../../../../../../../../services/auth.service';
import { useApp } from '../../../../../../../../hooks/useMain';
import BoardItemClient from '../../../../../../../../clients/boardItem.client';

const ACTION_TYPES = {
  SET_RESULT: 'SET_RESULT',
  SET_IS_COMMENTING: 'SET_IS_COMMENTING',
  SET_DRAWING: 'SET_DRAWING',
  SET_COMMENTS_PANEL_USEREMAIL: 'SET_COMMENTS_PANEL_USEREMAIL',
  SET_COMMENT_REPLY: 'SET_COMMENT_REPLY',
  SET_COMMENT: 'SET_COMMENT',
  REMOVE_COMMENT: 'REMOVE_COMMENT',
  REMOVE_COMMENT_REPLY: 'REMOVE_COMMENT_REPLY',
  REMOVE_DRAWING: 'REMOVE_DRAWING',
};

interface State extends BoardItem {
  comments: BoardComment[];
  isCommenting: boolean;
  currentDrawing?: string;
  commentsPanelUseremail: string | null;
}

const initialState: State = {
  id: undefined,
  value: '',
  isCommenting: false,
  currentDrawing: '',
  commentsPanelUseremail: null,
  comments: [],
  createdAt: new Date(),
};

function reducer(state: State, action: ReducerAction): State {
  switch (action.type) {
    case ACTION_TYPES.SET_RESULT: {
      return {
        ...state,
        ...action.value,
        isCommenting: false,
      };
    }
    case ACTION_TYPES.SET_IS_COMMENTING: {
      return {
        ...state,
        isCommenting: action.value,
      };
    }
    case ACTION_TYPES.SET_DRAWING: {
      return {
        ...state,
        currentDrawing: action.value,
      };
    }
    case ACTION_TYPES.SET_COMMENT: {
      return {
        ...state,
        comments: action.value,
      };
    }
    case ACTION_TYPES.REMOVE_COMMENT: {
      const comments = state.comments.filter(
        (item) =>
          item.value !== action.value.value &&
          item.createdAt !== action.value.createdAt
      );

      const drawingMetadata = action.value.metadata?.find(
        (metadata: ProjectPostCommentMetadata) =>
          metadata.type === PostMetadataType.DRAWING
      );

      const currentDrawing =
        state.currentDrawing === drawingMetadata?.value
          ? ''
          : state.currentDrawing;

      return {
        ...state,
        comments,
        currentDrawing,
      };
    }
    case ACTION_TYPES.REMOVE_COMMENT_REPLY: {
      const comments = state.comments.map((item) => {
        if (item.id === action.value.commentId) {
          item.replies = item.replies?.filter(
            (reply) => reply.id !== action.value.id
          );
        }

        return item;
      });

      return {
        ...state,
        comments,
      };
    }
    case ACTION_TYPES.REMOVE_DRAWING: {
      const comments = state.comments.map((comment) => {
        const metadata = comment.metadatas?.filter(
          (item) => item.value !== action.value
        );

        comment.metadatas = metadata;

        return comment;
      });

      const currentDrawing =
        state.currentDrawing === action.value ? '' : state.currentDrawing;

      return {
        ...state,
        comments,
        currentDrawing,
      };
    }
    case ACTION_TYPES.SET_COMMENTS_PANEL_USEREMAIL: {
      return {
        ...state,
        commentsPanelUseremail: action.value ?? !state.commentsPanelUseremail,
        currentDrawing: '',
      };
    }
    case ACTION_TYPES.SET_COMMENT_REPLY: {
      const comments = state.comments.map((item) => {
        if (item.id === action.value.commentId) {
          item.replies = [...(item.replies || []), action.value.commentReply];
        }

        return item;
      });

      return {
        ...state,
        comments,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

interface UsePostControllerProps {
  data: BoardItem;
  onCommentsPanelToggle?: (open: boolean) => void;
}

function useImageItemController({
  data,
  onCommentsPanelToggle,
}: UsePostControllerProps) {
  const { setSuccessMessage, setErrorMessage, setLoading, toggleSignUpModal } =
    useApp();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search } = useLocation();

  function scrollToFocus(disabled: boolean) {
    if (!disabled) return;

    document
      .getElementById(`post-container-${String(state.id)}`)
      ?.scrollIntoView({ behavior: 'smooth' });
  }

  function toggleVisibility(disabled: boolean) {
    document.querySelectorAll(`[id*='post-container-']`)?.forEach((item) => {
      const isCurrentPost = item.id === `post-container-${String(state.id)}`;

      if (isCurrentPost) {
        item.classList.remove('disabled');
        return;
      }

      if (disabled) {
        item.classList.add('disabled');
      } else {
        item.classList.remove('disabled');
      }
    });
  }

  function closeFeedbackInOtherPosts(disabled: boolean) {
    if (!disabled) return;

    const closeFeedbackModeButtons = document?.querySelectorAll(
      '#close-feedback-mode'
    );

    closeFeedbackModeButtons[0]?.dispatchEvent(
      new MouseEvent('click', { bubbles: true })
    );
  }

  function setIsCommenting(value: boolean): void {
    // scrollToFocus(value);
    closeFeedbackInOtherPosts(value);
    toggleVisibility(value);

    dispatch({ type: ACTION_TYPES.SET_IS_COMMENTING, value });
  }

  function setDrawing(value: string): void {
    dispatch({ type: ACTION_TYPES.SET_DRAWING, value });
  }

  function setComment(value: BoardComment): void {
    dispatch({ type: ACTION_TYPES.SET_COMMENT, value });
  }

  function removeDrawingFromComment(value: string): void {
    dispatch({ type: ACTION_TYPES.REMOVE_DRAWING, value });
  }

  function setCommentsPanelUseremail(value: any) {
    const isAuthenticated = new AuthService().isAuthenticated();

    if (!isAuthenticated) {
      toggleSignUpModal(true);
      return;
    }

    onCommentsPanelToggle?.(Boolean(value));
    dispatch({
      type: ACTION_TYPES.SET_COMMENTS_PANEL_USEREMAIL,
      value,
    });
  }

  function handleAvatarSelection(userEmail: string) {
    // if is same user was selected, the avatar panel should be closed
    const isSameUser = userEmail === state.commentsPanelUseremail;
    setCommentsPanelUseremail(isSameUser ? null : userEmail);
  }

  function handleQueryParams() {
    const columnId = new URLSearchParams(search).get('column') ?? '';
    const itemId = new URLSearchParams(search).get('item') ?? '';
    const userEmail = new URLSearchParams(search).get('user') ?? '';
    const shouldFocus = data.id === Number(itemId);

    if (columnId && itemId && userEmail && shouldFocus) {
      setCommentsPanelUseremail(userEmail);
    }
  }

  /// ////////////////////////////
  //  REQUESTS
  /// ////////////////////////////
  async function performCommentAdd(value: BoardComment) {
    try {
      const user = new UserService().get();

      setLoading(false, true);

      const comment = {
        id: value.id,
        value: value.value,
        metadatas: value.metadatas,
      };

      const commentsAdded = await new BoardItemClient().addComments(state.id!, [
        comment,
      ]);
      setComment(commentsAdded);
      setCommentsPanelUseremail(user.email);
      setIsCommenting(false);

      setSuccessMessage(
        'Thanks! Your feedback was saved!',
        undefined,
        'viewport-success'
      );
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while fetching the data'
      );
    } finally {
      setLoading(false, false);
    }
  }

  async function performCommentDeletion(
    comment: BoardComment,
    reply?: BoardComment
  ) {
    try {
      setLoading(false, true);

      if (reply) {
        await new BoardItemClient().deleteCommentReply(
          state.id!,
          comment.id!,
          reply.id!
        );
        dispatch({
          type: ACTION_TYPES.REMOVE_COMMENT_REPLY,
          value: { ...reply, commentId: comment.id },
        });
      } else {
        await new BoardItemClient().deleteComment(state.id!, comment.id!);
        setSuccessMessage('Your feedback was deleted!');
        dispatch({ type: ACTION_TYPES.REMOVE_COMMENT, value: comment });
      }
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while deleting the comment'
      );
    } finally {
      setLoading(false, false);
    }
  }

  async function performCommentReply(commentId: number, message: string) {
    try {
      setLoading(false, true);

      const commentsAdded = await new BoardItemClient().addCommentReply(
        state.id!,
        commentId,
        message
      );

      setComment(commentsAdded);
      setSuccessMessage('Reply submitted!', undefined, 'viewport-success');
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while replying'
      );
    } finally {
      setLoading(false, false);
    }
  }

  const isAuthenticated = useMemo(
    (): boolean => new AuthService().isAuthenticated(),
    []
  );

  const commentsByUser = useMemo(
    () =>
      state.comments?.filter(
        (item) => item.user?.email === state.commentsPanelUseremail
      ),
    [state.comments, state.commentsPanelUseremail]
  );

  useEffect(() => {
    if (data) {
      dispatch({ type: ACTION_TYPES.SET_RESULT, value: data });
    }
  }, [data]);

  useEffect(() => {
    if (state.comments.length) {
      handleQueryParams();
    }
  }, [search, state.comments]);

  return {
    state,
    setIsCommenting,
    setDrawing,
    removeDrawingFromComment,
    setCommentsPanelUseremail,
    performCommentAdd,
    performCommentDeletion,
    performCommentReply,
    handleAvatarSelection,
    isAuthenticated,
    commentsByUser,
  };
}

export default useImageItemController;
