import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { DownloadSimple } from '@phosphor-icons/react';

import {
  ModalContainer,
  Container,
  Title,
  Text,
  Header,
  Card,
} from './DownloadAppModal.styles';
import { ButtonOutline } from '../../../../../Button/Button';
import MacAppIcon from '../../../../../Icon/icons/MacAppIcon';
import { Column } from '../../../../../Column/Column';
import JuneService from '../../../../../../services/june.service';
import UserService from '../../../../../../services/user.service';

interface DownloadAppModalProps {
  open: boolean;
  onClose: () => void;
}

const DownloadAppModal: React.FC<DownloadAppModalProps> = ({
  open,
  onClose,
}: DownloadAppModalProps) => {
  const user = useMemo(() => new UserService().get(), []);

  return (
    <ModalContainer
      show={open}
      size="md"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <Container>
          <Header>
            <Title>
              Download our screenshot app to
              <br /> speed up your workflow
            </Title>

            <Text>Available on Mac. PC coming soon.</Text>
          </Header>

          <a
            href="https://viewport-user-assets-production.s3.us-west-2.amazonaws.com/mac-os-app/Viewport+Screenshot+Installer.dmg"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              new JuneService()
                .init()
                .track('Mac app downloaded', { user: user?.email });
            }}
          >
            <Card>
              <MacAppIcon />

              <Column>
                <p>Viewport Screenshot</p>
                <small>Mac OS latest release</small>
              </Column>

              <DownloadSimple color="#C8C9CF" size={32} weight="fill" />
            </Card>
          </a>

          <ButtonOutline
            type="button"
            width="90px"
            height="48px"
            fontWeight="600"
            color="#7F8087"
            borderSize="1px"
            marginTop="0"
            onClick={onClose}
            alignSelf="center"
          >
            Done
          </ButtonOutline>
        </Container>
      </Modal.Body>
    </ModalContainer>
  );
};

export default DownloadAppModal;
