import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BAD_REQUEST, FORBIDDEN, GONE, NOT_FOUND } from 'http-status';
import orderBy from 'lodash.orderby';

import {
  BoardColumn,
  BoardStatus,
  Board,
  ReducerAction,
  BoardState,
  UploadFilesJson,
  BoardShare,
} from '../../../../typings';
import { useApp } from '../../../../hooks/useMain';
import UploadClient from '../../../../clients/upload.client';
import BoardClient from '../../../../clients/board.client';
import ROUTES from '../../../../routes';
import JuneService from '../../../../services/june.service';
import UserService from '../../../../services/user.service';
import { ProjectTabs } from '../../components/Tabs/Tabs';
import FileUtils from '../../../../utils/file.utils';
import { GridViewItem } from '../../components/GridView/GridView';
import ProjectCreatedToast from '../../components/ProjectCreatedToast/ProjectCreatedToast';
import AuthService from '../../../../services/auth.service';

const ACTION_TYPES = {
  SET_COLUMNS: 'SET_COLUMNS',
  MOVE_COLUMN: 'MOVE_COLUMN',
  REMOVE_COLUMN: 'REMOVE_COLUMN',
  ADD_COLUMN: 'ADD_COLUMN',
  SET_RESULT: 'SET_RESULT',
  SET_COLUMN_TO_FOCUS: 'SET_COLUMN_TO_FOCUS',
  SET_TAB: 'SET_TAB',
  SET_FOLLOWING: 'SET_FOLLOWING',
  TOGGLE_CREATE_MODAL: 'TOGGLE_CREATE_MODAL',
  TOGGLE_SHARE_MODAL: 'TOGGLE_SHARE_MODAL',
  SET_LOADING: 'SET_LOADING',
  SET_SHARE_LINK: 'SET_SHARE_LINK',
  REMOVE_SHARE_LINK: 'REMOVE_SHARE_LINK',
};

interface State extends Board {
  tab: ProjectTabs;
  showCreateModal: boolean;
  showShareModal: boolean;
  columnInFocus: { columnId?: number; itemId?: number };
  changed: boolean;
  loading: boolean;
}

const initialState: State = {
  tab: ProjectTabs.STACKS,
  loading: true,
  id: undefined,
  name: '',
  description: '',
  image: '',
  private: false,
  status: '' as BoardStatus,
  state: BoardState.DRAFT,
  columnInFocus: { columnId: undefined, itemId: undefined },
  audits: [],
  shares: [],
  following: false,
  columns: [
    {
      order: 0,
      name: '',
      placeholder: 'Add stack name',
      items: [],
    },
    {
      order: 1,
      name: '',
      placeholder: '“Moodboard”',
      items: [],
    },
    {
      order: 2,
      name: '',
      placeholder: '“Visual design”',
      items: [],
    },
    {
      order: 3,
      name: '',
      placeholder: '“Inspiration”',
      items: [],
    },
  ],
  showCreateModal: false,
  showShareModal: false,
  changed: false,
};

function reducer(state: State, action: ReducerAction): State {
  switch (action.type) {
    case ACTION_TYPES.SET_RESULT: {
      if (action?.value?.columns?.length === 0) {
        action.value.columns = initialState.columns;
      }

      return {
        ...state,
        ...action.value,
      };
    }
    case ACTION_TYPES.SET_COLUMNS: {
      const columns = [...state.columns];
      columns[action.value.column.order] = action.value.column;

      return {
        ...state,
        changed: true,
        columns,
      };
    }
    case ACTION_TYPES.SET_COLUMN_TO_FOCUS: {
      const columnInFocus = {
        columnId: action.value.columnId,
        itemId: action.value.itemId,
      };

      return {
        ...state,
        columnInFocus,
      };
    }
    case ACTION_TYPES.SET_TAB: {
      return {
        ...state,
        tab: action.value,
      };
    }
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }
    case ACTION_TYPES.SET_FOLLOWING: {
      return {
        ...state,
        following: action.value,
      };
    }
    case ACTION_TYPES.MOVE_COLUMN: {
      const columns = [...state.columns];
      columns.splice(
        action.value.to,
        0,
        columns.splice(action.value.from, 1)[0]
      );

      const columnsWithOrder = columns.map((column, index) => ({
        ...column,
        order: index,
      }));

      return {
        ...state,
        changed: true,
        columns: columnsWithOrder,
      };
    }
    case ACTION_TYPES.REMOVE_COLUMN: {
      const columns = [...state.columns];
      columns.splice(action.value.order, 1);

      const columnsWithOrder = columns.map((column, index) => ({
        ...column,
        order: index,
      }));

      return {
        ...state,
        changed: true,
        columns: columnsWithOrder,
      };
    }
    case ACTION_TYPES.ADD_COLUMN: {
      const columns = [...state.columns];
      const lastColumn = columns[columns.length - 1];

      columns.push({
        order: lastColumn.order + 1,
        name: '',
        placeholder: 'Add stack name',
        items: [],
      });

      return {
        ...state,
        changed: true,
        columns,
      };
    }
    case ACTION_TYPES.TOGGLE_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.value ?? !state.showCreateModal,
      };
    }
    case ACTION_TYPES.TOGGLE_SHARE_MODAL: {
      return {
        ...state,
        showShareModal: action.value ?? !state.showShareModal,
      };
    }
    case ACTION_TYPES.SET_SHARE_LINK: {
      const shares = [...state.shares, action.value];

      return {
        ...state,
        shares,
      };
    }
    case ACTION_TYPES.REMOVE_SHARE_LINK: {
      const hashs = action.value;
      const shares = state.shares.filter(
        (share) => !hashs.includes(share.hash)
      );

      return {
        ...state,
        shares,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function useSettingsController(viewOnly: boolean) {
  const {
    setSuccessMessage,
    setErrorMessage,
    setCustomToast,
    setUpgradeToProModal,
    toggleSignUpModal,
    setLoading,
  } = useApp();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const { search } = useLocation();

  function toggleCreateModal(value?: boolean) {
    dispatch({
      type: ACTION_TYPES.TOGGLE_CREATE_MODAL,
      value,
    });
  }

  function toggleShareModal(value?: boolean) {
    dispatch({
      type: ACTION_TYPES.TOGGLE_SHARE_MODAL,
      value: Boolean(value),
    });
  }

  function setColumnToFocus(columnId?: number, itemId?: number) {
    dispatch({
      type: ACTION_TYPES.SET_COLUMN_TO_FOCUS,
      value: { columnId, itemId },
    });
  }

  function onChangeColumn(column: BoardColumn) {
    dispatch({
      type: ACTION_TYPES.SET_COLUMNS,
      value: { column },
    });
  }

  // function setLoading(value: boolean) {
  //   dispatch({
  //     type: ACTION_TYPES.SET_LOADING,
  //     value,
  //   });
  // }

  function onColumnAction(from: number, to: number): void {
    const shouldRemove = from === to;

    if (shouldRemove) {
      dispatch({
        type: ACTION_TYPES.REMOVE_COLUMN,
        value: { order: from },
      });
    } else {
      dispatch({
        type: ACTION_TYPES.MOVE_COLUMN,
        value: { from, to },
      });
    }
  }

  function onAddColumn() {
    dispatch({
      type: ACTION_TYPES.ADD_COLUMN,
    });
  }

  function setTab(tabId: ProjectTabs) {
    dispatch({ type: ACTION_TYPES.SET_TAB, value: tabId });
  }

  function setFollowing(value: boolean) {
    dispatch({ type: ACTION_TYPES.SET_FOLLOWING, value });
  }

  function handleEdit() {
    if (viewOnly) {
      navigate(ROUTES.PROJECT_EDIT(projectId));
    } else {
      toggleCreateModal(true);
    }
  }

  function addShareLink(datas: BoardShare) {
    dispatch({
      type: ACTION_TYPES.SET_SHARE_LINK,
      value: datas,
    });
  }

  function removeShareLink(hashs: string[]) {
    dispatch({
      type: ACTION_TYPES.REMOVE_SHARE_LINK,
      value: hashs,
    });
  }

  function handleQueryParams() {
    const columnId = new URLSearchParams(search).get('column') ?? '';
    const itemId = new URLSearchParams(search).get('item') ?? '';
    const showToastForPublished =
      new URLSearchParams(search).get('published') ?? false;

    if (columnId && itemId) {
      setColumnToFocus(Number(columnId), Number(itemId));
    }

    if (showToastForPublished) {
      const searchParams = new URLSearchParams(search);
      searchParams.delete('published');
      navigate({ search: searchParams.toString() });

      setCustomToast(
        ({ closeToast }: any) => (
          <ProjectCreatedToast
            onShare={() => toggleShareModal(true)}
            closeToast={closeToast}
          />
        ),
        {
          autoClose: 5000,
        }
      );
    }
  }

  /// ////////////////////////////
  //  REQUESTS
  /// ////////////////////////////
  async function fetchData() {
    try {
      setLoading(true);

      const data = await new BoardClient().getById(projectId as string);
      const isEmpty = data.columns?.length === 0;

      dispatch({ type: ACTION_TYPES.SET_RESULT, value: data });
      handleQueryParams();

      if (isEmpty && viewOnly) {
        navigate(ROUTES.PROJECT_EDIT(projectId));
      }
    } catch (error: any) {
      if ([FORBIDDEN, NOT_FOUND, GONE].includes(error?.data?.statusCode)) {
        navigate(`${ROUTES.NEW_PROJECT_NOT_FOUND(projectId)}`);
      } else {
        setErrorMessage(
          error?.data?.message || 'Something went wrong while fetching the data'
        );
      }
    } finally {
      setLoading(false);
    }
  }

  async function uploadPendingImages(
    columns: BoardColumn[]
  ): Promise<UploadFilesJson[]> {
    try {
      const imagesFiles = columns.reduce((acc: any[], column: BoardColumn) => {
        const images = column.items
          .filter((item) => item.value instanceof File)
          .map((item) => item.value);

        return [...acc, ...images];
      }, []);

      if (imagesFiles.length === 0) return [];

      const result = await new UploadClient().uploadTransformFiles(imagesFiles);

      return result;
    } catch (error: any) {
      const isBadRequest = error?.data?.statusCode === BAD_REQUEST;

      if (isBadRequest) {
        setUpgradeToProModal({
          title: 'You’ve reached your upload limit!',
          subtitle:
            'Upgrade to Pro to unlock unlimited uploads and early access to new features.',
        });

        throw error;
      }

      throw error;
    }
  }

  function getColumnsWithImagesReplaced(
    columns: BoardColumn[],
    images: UploadFilesJson[]
  ): BoardColumn[] {
    const result = columns.map((column) => {
      const items = column.items.map((item) => {
        if (item.value instanceof File) {
          const image = images.find(
            (imageUploaded) =>
              imageUploaded.originalname === (item.value as File).name
          );

          // if a image was matched, remove it from the images array to avoid use it again
          if (image) {
            // eslint-disable-next-line no-param-reassign
            images = images.filter(
              (imageUploaded) => imageUploaded.key !== image.key
            );
          }

          return {
            ...item,
            value: image?.location || item.value,
          };
        }

        return item;
      });

      return {
        ...column,
        items,
      };
    });

    return result;
  }

  function removeEmptyColumns(columns: BoardColumn[]): BoardColumn[] {
    const result = columns.filter((column) => column.name?.length > 0);

    const resultOrdered = result.map((column, index) => ({
      ...column,
      order: index,
    }));

    return resultOrdered;
  }

  async function performFollow() {
    try {
      setFollowing(true);
      await new BoardClient().follow(Number(state.id));
    } catch (error: any) {
      setFollowing(false);
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while following this project'
      );
    } finally {
      setLoading(false);
    }
  }

  async function performUnfollow() {
    try {
      setFollowing(false);
      await new BoardClient().unfollow(Number(state.id));
    } catch (error: any) {
      setFollowing(true);
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while unfollowing this project'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleFollow() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (!isAuthenticated) {
      toggleSignUpModal(true);
      return;
    }

    if (state.following) {
      performUnfollow();
    } else {
      performFollow();
    }
  }

  async function performInfoSubmit(formData: any) {
    try {
      setLoading(false, true);
      const isEditing = Boolean(projectId);

      const uploads =
        formData.picture instanceof File
          ? await new UploadClient().uploadFiles([formData.picture])
          : [];

      const payload = {
        name: formData.name,
        description: formData.description,
        status: formData.status,
        private: formData.private,
        image: formData.picture,
      } as Board;

      if (uploads?.length) {
        payload.image = uploads[0].location;
      }

      if (isEditing) {
        await new BoardClient().update(Number(projectId), payload);

        setLoading(false, false);

        new JuneService()
          .init()
          .track('Project updated', { name: formData.name });

        dispatch({ type: ACTION_TYPES.SET_RESULT, value: payload });
      } else {
        const { id } = await new BoardClient().create(payload);

        setLoading(false, false);

        new JuneService()
          .init()
          .track('Project created', { name: formData.name });

        navigate(ROUTES.PROJECT_EDIT(String(id)));
      }
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while creating the project'
      );
    } finally {
      setLoading(false, false);
    }
  }

  async function performColumnsSubmit(isPublishing = false) {
    try {
      setLoading(true);

      if (!state.changed) {
        return navigate(
          isPublishing ? ROUTES.PROJECT_VIEW(projectId) : ROUTES.DASHBOARD
        );
      }

      const columns = removeEmptyColumns(state.columns);

      const payload = {
        name: state.name,
        description: state.description,
        status: state.status,
        state: state.state,
        image: state.image,
        columns,
      } as Board;

      if (isPublishing) {
        payload.state = BoardState.PUBLISHED;
      }

      const imagesUpload = await uploadPendingImages(columns);

      if (imagesUpload.length > 0) {
        payload.columns = getColumnsWithImagesReplaced(
          payload.columns,
          imagesUpload
        );
      }

      await new BoardClient().update(Number(projectId), payload);
      setLoading(false);

      new JuneService().init().track('Project Updated', { name: state.name });
      const shouldShowMessage =
        isPublishing &&
        payload.state === BoardState.PUBLISHED &&
        state.state === BoardState.DRAFT;

      if (!shouldShowMessage) {
        setSuccessMessage('Project updated');
      }

      navigate({
        pathname: isPublishing
          ? ROUTES.PROJECT_VIEW(projectId)
          : ROUTES.DASHBOARD,
        search: shouldShowMessage ? '?published=true' : '',
      });
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while updating the project'
      );
    }
  }

  async function performDeletion() {
    try {
      setLoading(true);

      await new BoardClient().delete(Number(projectId));

      navigate(ROUTES.DASHBOARD);
      setSuccessMessage('Project deleted!');
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message ||
          'Something went wrong while deleting the comment'
      );
    } finally {
      setLoading(false);
    }
  }

  const isAbleToPublish = useMemo((): boolean => {
    const result = state.columns?.some(
      (column) => Boolean(column?.name) && column?.name?.length !== 0
    );

    return result;
  }, [state.columns]);

  const isOwner = useMemo((): boolean => {
    const userInfo = new UserService().get();
    return state.user?.email === userInfo?.email;
  }, [state.user]);

  const isDraft = useMemo(
    () => state.state === BoardState.DRAFT,
    [state.state]
  );

  const isAuthenticated = useMemo((): boolean => {
    const result = new AuthService().isAuthenticated();

    return result;
  }, [state.user]);

  const allImagesFromColumns = useMemo((): GridViewItem[] => {
    const result = state.columns?.reduce((acc: any[], column) => {
      const images = column?.items
        ?.filter(
          (item) =>
            item.value instanceof File || new FileUtils(item.value).isImage()
        )
        ?.map((item) => ({
          id: item.id,
          columnId: column.id,
          createdAt: item.createdAt,
          url:
            item.value instanceof File
              ? URL.createObjectURL(item.value)
              : item.value,
        }));

      return [...acc, ...images];
    }, []);

    const resultOrdered = orderBy<GridViewItem>(
      result,
      ['createdAt'],
      ['desc']
    );

    return resultOrdered;
  }, [state.columns]);

  useEffect(() => {
    if (!projectId) {
      toggleCreateModal(true);
    } else {
      fetchData();
    }
  }, [projectId]);

  return {
    performColumnsSubmit,
    performInfoSubmit,
    performDeletion,
    toggleCreateModal,
    toggleShareModal,
    onChangeColumn,
    onColumnAction,
    onAddColumn,
    setColumnToFocus,
    setTab,
    handleEdit,
    handleFollow,
    addShareLink,
    removeShareLink,
    isAbleToPublish,
    isOwner,
    isDraft,
    isAuthenticated,
    allImagesFromColumns,
    state,
  };
}

export default useSettingsController;
