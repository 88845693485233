import styled, { createGlobalStyle } from 'styled-components/macro';
import { Modal } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    padding: 32px;
    border-radius: 20px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 24px;
    text-align: center;
  }

  .modal-header,
  .modal-footer {
    border: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-footer {
    padding: 24px 0 0 0;
    display: flex;
    justify-content: space-between;

    button {
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 8px;
    }

    .MuiFormControl-root {
      border-radius: 12px;
    }
  }
`;

export const Title = styled(Modal.Title)`
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
`;

export const Subtitle = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.gray3};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 12px;
`;

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  font-size: 24px !important;
  color: #c8c9cf;
`;

interface ContentProps {
  width?: string;
  height?: string;
}

export const Content = styled.div<ContentProps>`
  width: ${({ width }) => width || '536px'};
  height: ${({ height }) => height || '338px'};
  background: ${({ theme }) => theme.gray2};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid ${({ theme }) => theme.gray};

  span:first-child {
    margin: auto auto;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }
`;

export const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  p {
    color: ${({ theme }) => theme.gray3};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageGalleryPreview = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  align-content: flex-start;
  gap: 8px;
  padding: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface ImageProps {
  selected: boolean;
  selectable: boolean;
}

export const Image = styled.img<ImageProps>`
  width: 96px;
  height: 96px;
  border-radius: 8px;
  object-fit: cover;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'grab')};
  border: 2px solid
    ${({ theme, selected }) => (selected ? theme.blue : 'transparent')};
`;

export const Action = styled.div`
  position: absolute;
  right: 3px;
  top: 4px;

  button {
    padding: 0;
    opacity: 0;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 96px;
  height: 96px;

  &:hover ${Action} button {
    opacity: 1;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .sortable-helper {
    opacity: 0.8;
  }
`;

export const SupportText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.gray3};
  margin-bottom: 24px;
`;

export const UpgradeText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-decoration-line: underline !important;
  color: ${({ theme }) => theme.blue};
  display: inline-block;
  cursor: pointer;
`;

export const Ratios = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 20px;
`;

interface RadioCardProps {
  selected: boolean;
}

export const RatioCard = styled.div<RadioCardProps>`
  width: 124px;
  height: 124px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;

  background: ${({ selected, theme }) =>
    selected ? 'rgba(77, 77, 255, 0.1)' : theme.gray2};

  border-radius: 12px;
  border: 3px solid
    ${({ theme, selected }) => (selected ? theme.blue : 'transparent')};

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
  }
`;
