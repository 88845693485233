import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MoonLoader from 'react-spinners/MoonLoader';
import { BAD_REQUEST } from 'http-status';

import { Button } from '../../../../components/Button/Button';
import {
  Content,
  TextField,
  Actions,
  InputsWrapper,
  Header,
  Form,
  LoaderWrapper,
} from './Create.styles';
import UserClient from '../../../../clients/user.client';
import { useApp } from '../../../../hooks/useMain';
import { DashboardSettings, User } from '../../../../typings';
import StringUtils from '../../../../utils/string.utils';
import { useDashboardSettings } from '../../../../hooks/useDashboardSettings';

const validation = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string().required('Required').min(7),
});

interface AccountFormProps {
  onSuccess: (email: string, password: string) => void;
}

const AccountForm = ({ onSuccess }: AccountFormProps) => {
  const { setErrorMessage } = useApp();
  const [isLoading, setIsLoading] = useState(false);
  const { onToggle } = useDashboardSettings(
    [DashboardSettings.EXPLORE_SAMPLE_PROJECTS],
    false
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validation,
    onSubmit: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      performSubmit(values);
    },
  });

  async function performSubmit(data: any) {
    try {
      setIsLoading(true);

      await new UserClient().signUp(data as User);

      onSuccess(data.email, data.password);
      onToggle(DashboardSettings.EXPLORE_SAMPLE_PROJECTS, true);
    } catch (error: any) {
      const isInvalidGroup = error?.data?.statusCode === BAD_REQUEST;
      if (isInvalidGroup) {
        setErrorMessage('The invite URL is invalid');
      } else {
        setErrorMessage(error?.data?.message || 'Something went wrong');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Content>
      {isLoading ? (
        <LoaderWrapper>
          <MoonLoader color="#7F8087" size={30} />
        </LoaderWrapper>
      ) : (
        <>
          <Header>
            <p style={{ textAlign: 'center' }}>
              Create your free Viewport account
            </p>
          </Header>

          <Form noValidate onSubmit={formik.handleSubmit}>
            <InputsWrapper>
              <TextField
                type="email"
                id="email"
                name="email"
                placeholder="Your email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email ? formik.errors.email : ''}
              />

              <TextField
                type="password"
                id="password"
                name="password"
                placeholder="Your password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={
                  formik.touched.password && formik.errors.password
                    ? new StringUtils(formik.errors.password).capitalize()
                    : ''
                }
              />
            </InputsWrapper>

            <Actions>
              <Button
                type="submit"
                width="107px"
                height="48px"
                fontSize="16"
                marginTop="0"
                borderRadius="12px"
                disabled={!formik.isValid}
              >
                Sign up
              </Button>
            </Actions>
          </Form>
        </>
      )}
    </Content>
  );
};

export default AccountForm;
