import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Container, Content, Text } from './NotFound.styles';
import ROUTES from '../../routes';
import { Button } from '../../components/Button/Button';

import AuthService from '../../services/auth.service';
import Header from '../../components/Header/Header';
import NotFoundIcon from './components/NotFoundIcon/NotFoundIcon';

interface ErrorPageProps {
  title: string;
}

const NotFoundPage: React.FC<ErrorPageProps> = ({ title }: ErrorPageProps) => {
  const isAuthenticated = useCallback(
    () => new AuthService().isAuthenticated(),
    []
  );

  return (
    <Container>
      <Header />

      <Content>
        <NotFoundIcon />

        <Text>{title}</Text>

        {isAuthenticated() ? (
          <Link to={ROUTES.DASHBOARD}>
            <Button
              type="button"
              marginTop="0"
              width="120px"
              height="48px"
              borderRadius="12px"
              fontWeight="bold"
            >
              Go home
            </Button>
          </Link>
        ) : (
          <Link to={ROUTES.SIGN_IN}>
            <Button
              type="button"
              marginTop="0"
              width="190px"
              height="48px"
              borderRadius="12px"
              fontWeight="bold"
            >
              Create free account
            </Button>
          </Link>
        )}
      </Content>
    </Container>
  );
};

export default NotFoundPage;
