import React from 'react';

const Stacks = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="9.75" y="3" width="4.5" height="18" rx="1" fill="#7F8087" />
    <rect x="3" y="3" width="4.5" height="10.5" rx="1" fill="#7F8087" />
    <rect x="16.5" y="3" width="4.5" height="15" rx="1" fill="#7F8087" />
  </svg>
);

export default Stacks;
