class ImageUtils {
  value: string;

  constructor(value: string) {
    this.value = value;
  }

  getOriginal(): string {
    return this.value;
  }

  getMedium(): string {
    return this.getValueReplaced('M');
  }

  getSmall(): string {
    return this.getValueReplaced('S');
  }

  private getValueReplaced(size: string): string {
    const canBeReplaced = this.value.includes('-O.');

    if (!canBeReplaced) {
      return this.value;
    }

    const valueReplaced = this.value.replace('-O.', `-${size}.`);
    return valueReplaced;
  }
}

export default ImageUtils;
