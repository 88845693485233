import styled, { createGlobalStyle } from 'styled-components/macro';
import { FormControl as MaterialFormControl } from '@material-ui/core';

interface ContainerProps {
  width?: number;
  empty: boolean;
}

export const Container = styled(MaterialFormControl)<ContainerProps>`
  min-width: ${({ width }) => (width ? `${width}px` : '100%')} !important;
  text-align: left;
  display: flex;
  justify-content: center;
  padding: 10px 10px;

  .MuiInput-root {
    border-bottom: 0 !important;
    width: ${({ width }) => (width ? `${width}px` : '100%')} !important;
    margin-top: 8px !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
  }

  .MuiInput-underline:before {
    border-bottom: 0 !important;
  }

  .MuiInput-underline:after {
    border-bottom: 0 !important;
  }

  .MuiSelect-select:focus {
    background-color: transparent !important;
    border-radius: 8px;
  }

  .MuiSelect-selectMenu {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: ${({ empty, theme }: any) =>
      empty ? theme.gray1 : theme.black} !important;

    .help-text {
      display: none;
    }
  }

  .MuiInput-input {
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px;
    border: 1px solid ${({ theme }) => theme.gray};
    border-radius: 8px;

    svg {
      display: none;
    }
  }

  svg {
    font-size: 30px;
    margin-top: -3px;
    margin-right: 10px;
    color: ${({ empty, theme }: any) =>
      empty ? theme.gray1 : theme.black} !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .MuiListItem-root {
    column-gap: 12px !important;
    border-radius: 4px !important;
  }

  .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiListItem-placeholder {
    display: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 8px !important;
    border: 1px solid ${({ theme }: any) => theme.gray} !important;
  }

  .MuiListItem-button:hover {
    background-color: #F1F3F5 !important;
  }

  .MuiListItem-button {
    color: ${({ theme }: any) => theme.gray3} !important;
    border-bottom: 1px solid ${({ theme }: any) => theme.gray} !important;
    padding: 12px 20px 12px 16px !important;
    justify-content: space-between !important;
  }
`;

export const Label = styled.label`
  color: ${({ theme }: any) => theme.gray3};
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  margin: 0;
`;
