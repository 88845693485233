export function isProdEnv(): boolean {
  const prodHost = 'viewport.co';

  return window.location.hostname.includes(prodHost);
}

export function isStagEnv(): boolean {
  const stagHost = 'fly.dev';

  return window.location.hostname.includes(stagHost);
}

export function isDevEnv(): boolean {
  const stagHost = 'localhost';

  return window.location.hostname.includes(stagHost);
}
