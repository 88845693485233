import { useCallback, useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { IoMdNotifications } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import BarLoader from 'react-spinners/BarLoader';

import { Link } from 'react-router-dom';
import {
  Popover,
  PopoverTitle,
  Items,
  Item,
  Button,
  Dot,
  Counter,
  Avatar,
  Details,
  Title,
  Subtitle,
  Date,
  Empty,
  LoadingWrapper,
} from './Notification.styles';
import NotificationClient from '../../../../clients/notification.client';
import {
  NotificationMessageViewModel,
  NotificationPaging,
} from '../../../../typings';
import DateUtils from '../../../../utils/date.utils';
import Icon, { IconType } from '../../../Icon/Icon';
import UrlUtils from '../../../../utils/url.utils';
import { useAvatarImage } from '../../../../hooks/useAvatarImage';
import ROUTES from '../../../../routes';

const MAX_ITENS_TO_SCROLL = 5;

const Notification = () => {
  const [results, setResults] = useState<NotificationMessageViewModel[]>([]);
  const [paging, setPaging] = useState<NotificationPaging>({
    total: 0,
    unread: 0,
    page: 0,
  });
  const { getAvatarMemo } = useAvatarImage();

  async function fetchData(page: number) {
    try {
      // setLoading(true);

      const data = await new NotificationClient().getAll(page);

      setResults([...results, ...data.results]);
      setPaging(data.paging);
    } catch (error: any) {
      // setErrorMessage(
      //   error?.data?.message ||
      //     'Something went wrong while fetching the group data'
      // );
    } finally {
      // setLoading(false);
    }
  }

  async function performRead(id: string) {
    try {
      // setLoading(true);

      await new NotificationClient().setAsRead(id);
    } catch (error: any) {
      // setErrorMessage(
      //   error?.data?.message ||
      //     'Something went wrong while fetching the group data'
      // );
    } finally {
      // setLoading(false);
    }
  }

  const hasNewMessage = useCallback(
    (): boolean => results.some((item) => !item.readAt),
    [results]
  );

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <OverlayTrigger
      rootClose
      placement="bottom"
      trigger="click"
      overlay={
        <Popover id="navigation">
          <PopoverTitle>
            Notifications
            <Counter>{paging?.unread}</Counter>
          </PopoverTitle>

          <Items
            id="scrollableDiv"
            scroll={results?.length >= MAX_ITENS_TO_SCROLL}
          >
            <InfiniteScroll
              // pullDownToRefresh
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>
              //     &#8595; Pull down to refresh
              //   </h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>
              //     &#8593; Release to refresh
              //   </h3>
              // }
              dataLength={results.length}
              next={() => fetchData(paging.page + 1)}
              // refreshFunction={() => fetchData(paging.page)}
              hasMore={results.length < paging.total}
              loader={
                <LoadingWrapper>
                  <BarLoader color="#4D4DFF" loading width={100} height={4} />
                </LoadingWrapper>
              }
              scrollableTarget="scrollableDiv"
            >
              {results?.map((item) => (
                <Item
                  key={item.id}
                  unread={!item.readAt}
                  onClick={() => !item.readAt && performRead(item.id)}
                >
                  {/* need to be an archor here to "force" go to page */}
                  <a href={item.url && new UrlUtils(item.url).getPathname()}>
                    {item.username ? (
                      <Link to={`${ROUTES.PROFILE}/${String(item.username)}`}>
                        <Avatar src={getAvatarMemo(item.picture, item.url)} />
                      </Link>
                    ) : (
                      <Avatar src={getAvatarMemo(item.picture, item.url)} />
                    )}

                    <Details>
                      <Title>{item.title}</Title>

                      <Subtitle
                        dangerouslySetInnerHTML={{ __html: item.body }}
                      />

                      <Date>{new DateUtils(item.createdAt).fromNow()}</Date>
                    </Details>

                    {!item.readAt ? <Dot /> : <span />}
                  </a>
                </Item>
              ))}
            </InfiniteScroll>

            {!results?.length && (
              <Empty>
                <Icon name={IconType.sadFace} bottom={14} />

                <p>Nothing here!</p>
              </Empty>
            )}
          </Items>
        </Popover>
      }
    >
      <Button type="button">
        <IoMdNotifications />
        {hasNewMessage() && <Dot fixed color="#18ECBC" />}
      </Button>
    </OverlayTrigger>
  );
};

export default Notification;
