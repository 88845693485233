import mixpanel from 'mixpanel-browser';

const IS_DEV = process.env.NODE_ENV === 'development';

class MixPanelService {
  static init(): void {
    if (!IS_DEV) mixpanel.init('846bddfadb3a57f227ecba59dc54433e');
  }

  static identify(email: string): void {
    if (!IS_DEV) mixpanel.identify(email);
  }

  static track(name: string, props: any): void {
    if (!IS_DEV) mixpanel.track(name, props);
  }
}

export default MixPanelService;
