import styled, { css } from 'styled-components/macro';
import {
  Popover as BootstrapPopover,
  PopoverTitle as BootstrapPopoverTitle,
} from 'react-bootstrap';

export const Button = styled.button`
  position: relative;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 0;
  padding: 0;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, color }: any) => color || theme.white};
  background: transparent;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.2);
  }
`;

interface DotsProps {
  fixed?: boolean;
  color?: string;
}

export const Dot = styled.div<DotsProps>`
  width: 10px;
  height: 10px;
  background: ${({ color, theme }: any) => color || theme.blue};
  border-radius: 8px;
  align-self: center;

  ${({ fixed }) =>
    fixed &&
    css`
      position: absolute;
      top: 12px;
      right: 8px;
      border: 1px solid #5d5e61;
    `}
`;

export const Counter = styled.div`
  width: 32px;
  height: 32px;
  background: ${({ theme }: any) => theme.blue};
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }: any) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Popover = styled(BootstrapPopover)`
  max-width: 256px;
  margin-top: 12px !important;
  border-color: ${({ theme }: any) => theme.gray1};
  border-radius: 8px;

  .arrow:after {
    border-bottom-color: ${({ theme }: any) => theme.gray2};
  }
`;

export const PopoverTitle = styled(BootstrapPopoverTitle)`
  padding: 15px;
  width: 100%;
  max-height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  background: ${({ theme }: any) => theme.gray2};
`;

interface ItemsProps {
  scroll: boolean;
}

export const Items = styled.ul<ItemsProps>`
  max-height: 427px;
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  /* padding: 0px 0px 12px 0px; */
  /* margin: 0 ${({ scroll }) => (scroll ? '2px' : '12px')} 0 12px; */
  padding: 12px 8px 12px 12px;
  margin: 0;

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
`;

interface ItemProps {
  unread: boolean;
}

export const Item = styled.li<ItemProps>`
  padding: 12px;
  background: ${({ theme, unread }: any) =>
    unread ? theme.gray2 : 'transparent'};
  border-radius: 12px;
  /* margin-top: 12px; */
  border: 1px solid ${({ theme }: any) => theme.gray2};

  &:hover {
    background: ${({ theme }: any) => theme.gray};
  }

  a {
    display: flex;
    justify-content: space-between;
    color: inherit;
  }
`;

export const Details = styled.div`
  min-width: 145px;
  max-width: 163px;
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: cover;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 10px;
  margin: 0;
`;

export const Subtitle = styled.p`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 4px 0px;
`;

export const Date = styled.p`
  font-weight: 400;
  font-size: 10px;
  margin: 0;
  color: ${({ theme }: any) => theme.gray3};
`;

export const Empty = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;

  p {
    font-weight: 600;
    font-size: 16px;
    color: ${({ theme }) => theme.gray1};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;
