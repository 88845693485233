import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 24px 32px;
  height: calc(100% - 88px); // 100% - header;
  overflow-x: auto;

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;

  p:nth-child(1) {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  p:nth-child(2) {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    opacity: 0.2;
  }
`;

export const Image = styled.img`
  min-height: 100px;
  object-fit: cover;
  width: ${({ width }) => (width ? `${width}px` : '152px')};
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.gray};
  cursor: pointer;
`;
