import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';

export const ModalContainer = styled(Modal)`
  z-index: 1054;

  .modal-dialog {
    max-width: 801px;
  }

  .modal-content {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 32px;
    padding: 20px 20px 20px 40px;
    background-image: url('/images/pro-modal-background.png');
    background-position: center;
    background-size: cover;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }

  .modal-header,
  .modal-footer {
    border: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-footer {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 40px;
`;

export const LeftSide = styled.div`
  width: 280px;
  margin-top: 20px;

  h1 {
    color: ${({ theme }) => theme.white};
    letter-spacing: 0%;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: left;
  }

  small {
    color: ${({ theme }) => theme.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0%;
    opacity: 0.5;
  }
`;

export const RightSide = styled.div`
  width: 430px;
  height: 530px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: ${({ theme }) => theme.white};
  padding: 24px;
`;

export const Button = styled.button`
  width: 382px;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 86px 20px 86px;
  color: ${({ theme }) => theme.white};
  border: 0;

  font-weight: 600;
  font-size: 18px;
  line-height: 100%;

  border-radius: 12px;
  box-shadow: 0px 6px 9px -2px rgba(0, 202, 242, 0.3),
    inset 0px 0px 0px 2px rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgb(77, 77, 255) -20.37%,
    rgb(0, 202, 242) 118.519%
  );
`;

export const Badge = styled.div`
  width: 56px;
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 16px;
  border-radius: 12px;
  color: ${({ theme }) => theme.white};
  box-shadow: 0px 3px 14px -4px rgb(0, 202, 242);
  background: linear-gradient(
    175.09deg,
    rgb(77, 77, 255) -6.866%,
    rgb(0, 202, 242) 105.451%
  );
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    align-self: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.gray};
    margin: 0;
  }
`;

export const Pricing = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -4%;
    display: inline;
  }

  small {
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0%;
    color: ${({ theme }) => theme.gray3};
    margin-left: 8px;
  }
`;

export const Items = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Item = styled.li`
  height: 22px;
  display: flex;
  align-items: center;
  gap: 23px;

  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
`;

export const ComingSoon = styled.div`
  width: 47px;
  height: 23px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: ${({ theme }) => theme.gray2};

  font-size: 10px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 1%;
  color: ${({ theme }) => theme.gray1};

  margin-left: -16px;
`;

interface PaymentTypeProps {
  active: boolean;
}

export const PaymentType = styled.li<PaymentTypeProps>`
  padding: 8px 12px 8px 12px;
  background: ${({ theme, active }) => (active ? theme.white : 'transparent')};
  opacity: ${({ active }) => (active ? '1' : '0.3')};
  border: 1px solid
    ${({ theme, active }) => (active ? theme.gray : 'transparent')};
  color: ${({ theme }) => theme.gray3};
  line-height: 100%;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    opacity: 50%;
  }
`;

export const PaymentTypeWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  background: ${({ theme }) => theme.gray2};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.gray};
  padding: 4px;
  margin: 0;
  height: 44px;
`;

export const Save = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #00caf2;
`;

export const TotalPrice = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.gray1};
`;
