import { useState } from 'react';

import { OverlayTrigger } from 'react-bootstrap';
import { Button, Popover, Info, Placeholder } from './AddCommentInfo.styles';
import { Button as ViewportButton } from '../../../../components/Button/Button';

interface AddCommentInfoProps {
  show: boolean;
}

const AddCommentInfo = ({ show }: AddCommentInfoProps) => {
  const [isMenuOepn, setIsMenuOpen] = useState(false);

  if (!show) {
    return <Placeholder />;
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom-end"
        trigger={['click']}
        show={isMenuOepn}
        onToggle={(val) => {
          setIsMenuOpen(val);
        }}
        overlay={
          <Popover id="add-comment-info">
            <Info>
              <p>
                To add a comment, open a <br /> stack and click on an asset to
                start a comment—that’s it!
              </p>

              <ViewportButton
                type="button"
                width="211px"
                height="48px"
                marginTop="0"
                onClick={() => setIsMenuOpen(false)}
              >
                Got it!
              </ViewportButton>
            </Info>
          </Popover>
        }
      >
        <Button type="button">Add a comment</Button>
      </OverlayTrigger>
    </>
  );
};

export default AddCommentInfo;
