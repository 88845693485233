import React, { useMemo, useState } from 'react';
import RemoveIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { Eye, Trash } from '@phosphor-icons/react';

import {
  Content,
  Avatar,
  Container,
  Details,
  Header,
  Username,
  Text,
  Date,
  ShowDrawingButton,
  Counter,
  GlobalStyle,
} from './Comment.styles';
import {
  BoardComment,
  BoardCommentMetadataType,
  UserViewModel,
} from '../../../../../../../../typings';
import { useAvatarImage } from '../../../../../../../../hooks/useAvatarImage';
import UserService from '../../../../../../../../services/user.service';
import UserProfileLink from '../../../../../../../../components/UserProfileLink/UserProfileLink';
import DeletePopover from '../../../../../../../../components/DeletePopover/DeletePopover';
import Reply, { MAX_REPLIES_TO_SHOW_COUNTER } from '../Reply/Reply';
import Spacer from '../../../../../../../../components/Spacer/Spacer';

const MAX_REPLIES_TO_SHOW = 2;
interface CommentProps {
  data: BoardComment;
  index: number;
  currentDrawing?: string;
  onDelete: (comment: BoardComment, reply?: BoardComment) => void;
  onSetDrawing: (value: string) => void;
  onRemoveDrawing: (value: string) => void;
  onReply: (commentId: number, message: string) => Promise<void>;
}

const Comment: React.FC<CommentProps> = ({
  data,
  index,
  currentDrawing,
  onDelete,
  onSetDrawing,
  onRemoveDrawing,
  onReply,
}: CommentProps) => {
  const { getAvatarMemo } = useAvatarImage();
  const [expandCollapsedReplies, setExpandCollapsedReplies] = useState(false);

  const showAllReplies = useMemo(
    (): boolean =>
      data.replies ? data.replies?.length >= MAX_REPLIES_TO_SHOW : false,
    [data.replies]
  );

  const drawingMetadata = useMemo(
    () =>
      data.metadatas?.find(
        (item) => item.type === BoardCommentMetadataType.DRAWING
      ),
    [data.metadatas]
  );

  const isShowingDrawing = useMemo(
    (): boolean => drawingMetadata?.value === currentDrawing,
    [currentDrawing]
  );

  const commmentWithReplies = useMemo(
    (): BoardComment[] =>
      showAllReplies && !expandCollapsedReplies
        ? [data]
        : [data, ...(data.replies || [])],
    [data.replies, expandCollapsedReplies]
  );

  function getUpdatedAt(date?: Date) {
    const now = moment().startOf('day');
    const target = moment(date).startOf('day');
    const diffInDays = now.diff(target, 'days');
    const diffInMinutes = moment().diff(moment(date), 'minutes');

    if (diffInMinutes < 2) {
      return 'Just now';
    }

    if (diffInDays === 0) {
      return moment(date).format('hh:mm A');
    }

    if (diffInDays <= 7) {
      return `${diffInDays}d ago`;
    }

    return moment(date).format('MM/DD/YY');
  }

  function handleShowDrawing() {
    onSetDrawing(!isShowingDrawing ? drawingMetadata?.value : '');
  }

  function isReply(value: BoardComment): boolean {
    return !value.metadatas;
  }

  function showConnectLine(itemIndex: number): boolean {
    const { replies = [] } = data;

    if (
      !expandCollapsedReplies &&
      replies.length >= MAX_REPLIES_TO_SHOW_COUNTER
    ) {
      return false;
    }

    if (!replies.length) return false;

    return itemIndex < replies.length;
  }

  function handleRemoveDrawing(e: React.MouseEvent<SVGSVGElement>) {
    e.stopPropagation();
    onRemoveDrawing(drawingMetadata?.value);
  }

  function handleDelete(comment: BoardComment) {
    if (isReply(comment)) {
      onDelete(data, comment);
    } else {
      onDelete(comment);
    }
  }

  function isAbleToDelete(currentUser?: UserViewModel) {
    const userInfo = new UserService().get();
    const isOwner = userInfo?.email === currentUser?.email;
    const isMainCommentOwner = userInfo?.email === data?.user?.email;

    return isOwner || isMainCommentOwner;
  }

  return (
    <Container>
      {commmentWithReplies.map((commentOrReply, commentIndex) => (
        <Content key={String(commentOrReply.createdAt)}>
          <Header connect={showConnectLine(commentIndex)}>
            <Avatar
              src={getAvatarMemo(
                commentOrReply.user?.picture,
                commentOrReply.id
              )}
            />

            <Username>
              {`
            ${String(commentOrReply.user?.firstName)}
            ${String(commentOrReply.user?.lastName)}
          `}

              {!isReply(commentOrReply) && <Counter>#{index}</Counter>}
            </Username>

            <Date>{getUpdatedAt(commentOrReply.updatedAt)}</Date>

            {isAbleToDelete(commentOrReply.user) && (
              <>
                <GlobalStyle />

                <Spacer width={8} />

                <DeletePopover
                  title="Are you sure you want to delete this comment?"
                  confirmButtonText="Delete"
                  id="remove-comment"
                  onConfirm={() => handleDelete(commentOrReply)}
                >
                  <Trash cursor="pointer" size={16} height={14} />
                </DeletePopover>
              </>
            )}
          </Header>

          <Details>
            <Text>{commentOrReply.value}</Text>

            {drawingMetadata && !isReply(commentOrReply) && (
              <ShowDrawingButton
                type="button"
                active={isShowingDrawing}
                onClick={handleShowDrawing}
              >
                <Eye color="#7F8087" size={14} />

                <p>{isShowingDrawing ? 'Hide' : 'Show'} drawing</p>

                {!commentOrReply.id && (
                  <RemoveIcon onClick={handleRemoveDrawing} />
                )}
              </ShowDrawingButton>
            )}
          </Details>
        </Content>
      ))}

      <Reply
        data={{
          commentId: data.id!,
          totalReplies: data.replies?.length,
          user: data.user,
        }}
        onSubmit={onReply}
        onToggle={setExpandCollapsedReplies}
      />
    </Container>
  );
};

export default Comment;
