import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSearch as SearchIcon } from 'react-icons/fi';

import ROUTES from '../../../../routes';
import { Container, Button, GlobalStyle } from './Search.styles';
import UserAutocomplete from '../../../UserAutocomplete/UserAutocomplete';
import { UserViewModel } from '../../../../typings';

const Search = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function handleSelection(user: UserViewModel) {
    if (user?.username) {
      navigate(`${ROUTES.PROFILE}/${String(user.username)}`);
    }
  }

  return (
    <Container open={open}>
      <GlobalStyle />

      <Button type="button" onClick={() => setOpen(!open)}>
        <SearchIcon color="white" size="20px" />
      </Button>

      {open && (
        <UserAutocomplete
          autoFocus
          showNoOptions
          id="header-user-search"
          placeholder="Search users"
          onSelect={handleSelection}
          onBlur={() => setOpen(false)}
        />
      )}
    </Container>
  );
};

export default Search;
