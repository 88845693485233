import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';

interface TooltipProps {
  title: string;
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  id: string;
  show?: boolean;
  children: React.ReactElement;
  spacing?: number;
  borderRadius?: string;
  height?: string;
}

const Tooltip = ({
  id,
  title,
  show,
  children,
  height,
  placement = 'bottom',
  spacing = 4,
  borderRadius = '6px',
}: TooltipProps): React.ReactElement => (
  <OverlayTrigger
    show={show}
    // @ts-ignore
    placement={placement}
    popperConfig={{
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, spacing],
          },
        },
      ],
    }}
    overlay={
      // @ts-ignore
      <BootstrapTooltip id={id} style={{ borderRadius, height }}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </BootstrapTooltip>
    }
  >
    {children}
  </OverlayTrigger>
);

export default Tooltip;
