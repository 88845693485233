import styled from 'styled-components/macro';

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  background: ${({ theme }) => theme.gray2};
  width: 272px;
  height: 48px;
  border-radius: ${({ disabled }) => (disabled ? '8px' : '12px')};
  padding: 8px 8px 8px 16px;
  border: 1px solid transparent;
  cursor: ${({ disabled }) => (disabled ? 'pointer' : 'initial')};

  &:focus-within {
    border-color: ${({ theme }) => theme.blue};
    box-shadow: 0px 0px 0px 2px rgba(77, 77, 255, 0.15);

    .MuiSelect-icon {
      opacity: 1;
    }
  }

  &:hover {
    background: ${({ disabled, theme }) =>
      disabled ? theme.gray : theme.gray2};

    input {
      cursor: ${({ disabled }) => (disabled ? 'pointer' : 'text')};
      background: ${({ disabled, theme }) =>
        disabled ? theme.gray : theme.gray2};
    }
  }
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  height: 32px;
  border-radius: 8px;
  border: 0;
  background: ${({ theme }) => theme.gray2};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:disabled {
    pointer-events: none;
  }
`;

export const AssetsCount = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  align-self: center;
  letter-spacing: 0.16px;
  opacity: 0.3;
  width: 32px;
  text-align: center;
`;
